import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index"
import Icon from "react-icons-kit";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import { notepad_ok } from 'react-icons-kit/ikons/notepad_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'
import { notepad_remove } from 'react-icons-kit/ikons/notepad_remove'
class DfCarsdocumentation extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Datas: [],
      dashboard: [{ "fill": 0, "view": 0, "trade": 0 }],
      data1: [],
      data2: [],
      phyval: [],
      handle_CurrentStatus_data: [],
      data3: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      modelopt: [],
      customerId: localStorage.getItem("userId"),
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      stage: "",
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          minWidth: 140,
          Cell: (d) => this.Document(d),
        },
        {
          Header: "Register No",
          accessor: "regno",
          width:150
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        // {
        //   Header: "Evaluator Name ",
        //   accessor: "jobroleName",
        // },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "modelName",
          minWidth: 140,
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },

        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Source",
          accessor: "sources",
          width: 150

        },
        {
          Header: "Approval Status",
          accessor: "docs_status",
          minWidth: 140,
          Cell: (d) => this.Document_status_VIew(d),
        },

        {
          Header: "Data Type",
          accessor: "Auction",
          minWidth: 140,
          Cell: (d) => this.Auction(d.original),
        },
        {
          Header: "Print PDF",
          accessor: "pdf",
          minWidth: 140,
          Cell: d => this.viewpdf(d),
        },
        {
          Header: "Approval",
          accessor: "mobile",
          minWidth: 140,
          Cell: (d) => this.Approval(d),
        },
        {
          Header: "Status",
          accessor: "Auction_Status",
          minWidth: 140,
          Cell: (d) => this.CurrentStatus(d.original),
        },

      ],
      excelColumn: [
        {
          label: "Register No",
          key: "regno",
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },

        {
          label: "Color",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          label: "Source",
          key: "sources"
        },
        {
          label: "Data Type",
          key: "Auction_Status"
        }

      ],
      PhyColoumn: [
        {
          Header: "Form",
          accessor: "mobile",
          Cell: (d) => this.physicaldash(d),
        },
        {
          Header: "Name",
          accessor: "name",
        },

        {
          Header: "Register No",
          accessor: "reg_no",
        },
        {
          Header: "Evaluator Name ",
          accessor: "jobroleName",
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Source",
          accessor: "sources"
        },
        {
          Header: "Approval Status",
          accessor: "docs_status"
        },


      ],
      excelPhyColoumn: [
        {
          label: "Name",
          key: "name",
        },

        {
          label: "Register No",
          key: "reg_no",
        },
        {
          label: "Evaluator Name ",
          key: "jobroleName",
        },
        {
          label: "Make",
          key: "makeName",

        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          label: "Color",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          label: "Source",
          key: "sources"
        },
        {
          label: "Approval Status",
          key: "docs_status"
        }

      ],
    };
    this.vals()
  }
  vals = async () => {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt
      })
    }
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<center><button type="button" className="btn btn-warning btn-sm text-center"
        style={{ minWidth: "100px" }}
        onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
    }
    else {
      return (
        <div className="text-center">-</div>)
    }
  }
  physicaldash = (d) => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<center>
        <button
          type="button"
          className="btn btn-success btn-sm text-center"
          style={{ minWidth: "100px" }}
          onClick={() => this.physicalform(d)}
        >
          Edit Form
        </button></center>
      );
    } else {
      return (<center>
        <button
          type="button"
          className="btn btn-warning btn-sm text-center"
          style={{ minWidth: "100px" }}
          onClick={() => this.physicalform(d)}
        >
          Fill Form
        </button></center>
      );
    }
  };
  physicalform = async (d) => {
    window.open(
      `/DFCars/Delivery_Note_Seller_Proof/${d.original.id}`,
      "_self"
    );
  };
  generatepdf = async (d) => {
    const document = await cmsContent.getFreedom("*", "tbl_AT_Booklet_Purchase_Contract_Proof", `customerId=${d.original.id}`, 1, 1);
    console.log(document.data)
    if (document) {
      let documentpdf = await cmsContent.document("tbl_AT_Booklet_Purchase_Contract_Proof", document.data[0].customerid);
      console.log(documentpdf)
      if (documentpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/documentpdf/${document.data[0].name}`, '_self')
      }
    }
  }

  UpdateSTatus = (d) => {
    return (<div>
      <button
        type="button"
        className="btn btn-warning btn-sm"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button></div>
    );
  };

  Editdata = async (d) => {
    this.setState({ stage: "documentation", CustomerId: d.original.id });
  };

  Approval = (d) => {

    if (d.original.docs_status) {
      return (<div><span className="btn btn-success btn-sm text-center" style={{ minWidth: "130px" }}>{d.original.docs_status}</span></div>)
    }
    else if (d && d.original && d.original.docs_status == 'Form Not Filled') {
      return (<div><span className="btn btn-secondary btn-sm text-center" style={{ minWidth: "130px" }}>Form Not Filled</span></div>)

    }
    else {
      return (<div><span className="btn btn-secondary btn-sm text-center" style={{ minWidth: "130px" }}>Form Not Filled</span></div>)
    }
  };
  handle_CurrentStatus = async (d) => {
    await this.setState({
      handle_CurrentStatus_data: d
    })
  }
  Auction = (d) => {
    if (d && d.Auction_Status) {
      return (
        <div>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "130px" }}>
            {d.Auction_Status}
          </span>
        </div>
      )
    } else {
      return (
        <div>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "130px" }}>
            {d.Auction_Status}
          </span>
        </div>
      )
    }
  };
  CurrentStatus = (d) => {
    let { handle_CurrentStatus_data } = this.state
    if (d) {
      return (
        <LoginModal
          buttonTitle="View Status"
          title="Status"
          id={`Status${d.id}`}
          extraClass="btn btn-sm btn-primary "
          buttonStyle={{ minWidth: "130px" }}
          extraStyle={{ width: '100%' }}
          onClick={() => this.handle_CurrentStatus(d)
          }
          bodyText={
            < div className="container" >
              <div className="row form-group">
                <div className="col-sm-1" />
                <div className="col-sm-3 text-right">
                  <label htmlFor="exampleInputEmail1">
                    Form Status
                  </label>
                </div>
                <div className="col-sm-5">
                  <h3 className="" style={{ color: handle_CurrentStatus_data.Auction == 0 ? "#07278f" : handle_CurrentStatus_data.Auction == 1 ? "orange" : handle_CurrentStatus_data.Auction == 2 ? "green" : "red" }}>{handle_CurrentStatus_data.Auction_Status}</h3>
                </div>
                <div className="col-sm-3" />
              </div>
            </ div >
          }
        />)
    }
  };

  Document_status_VIew = (d) => {

    return (
      <div>
        <button
          type="button"
          className={`btn btn-${d.original.FormFilled == "no" ? "secondary" : d.original.FormFilled == "yes" ? "primary" : d.original.FormFilled == "approved" ? "success" : d.original.Rejected ? "danger" : "info"} btn-sm text-center`}
          style={{ minWidth: "130px" }}

        >
          {d.original.docs_status}
        </button></div>
    );

  };
  Document = (d) => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <center>  <button
          type="button"
          className="btn btn-primary btn-sm text-center"
          style={{ minWidth: "100px" }}
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button></center>
      );
    } else {
      return (<center>
        <button
          type="button"
          className="btn btn-warning btn-sm text-center"
          style={{ minWidth: "100px" }}
          onClick={() => this.F_proofForm(d)}
        >
          Fill Form
        </button>
      </center>

      );
    }
  };

  F_proofForm = async (d) => {
    window.open(
      `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d.original.id}`,
      "_self"
    );
  };
  MainFunction = async () => {
    try {
      let results = await cmsContent.BUYDFCARZ(this.state.userid.id, 'Documents')
      let data_for_f = results.vals
      console.log(results, "API");
      let fulldata = []
      if (results && results.Documentation_Prospective) {
        fulldata = await results.Documentation_Prospective;
      }

      await this.setState({
        Data: results.Documentation_Prospective,
        data_for_f,
        tradedata: fulldata.length ? fulldata.filter(e => e.filterdealok == true) : [],
        dashboard: data_for_f,
        data2: fulldata.length ? fulldata.filter(e => e.filterpending == true) : [],
        phyval: fulldata.length ? results.phyval : [],
        // phyval: results.phyval ? results.phyval : [],
        loading: false
      })
    } catch (error) {
      console.log(error);
      await this.setState({ loading: false })
    }
  }
  async componentDidMount() {
    // try {
    this.setState({ loading: true })
    this.MainFunction()
  }
  edash = () => {
    return (<div className="">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className=''>
            <div className="row col-12 mt-4 text-center">
              <div className="col ">
                <div className="dashboardCard1" onClick={() => this.onClk(1)}>
                  <Icon icon={calendar} style={{ color: '#2A9D8F' }} size={45} />
                  <h2 className="mt-2 black" >Deal OK</h2>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].trade ? this.state.dashboard[0].trade : 0}</b></h1>
                  </div>
                </div>
              </div>

              <div className="col " onClick={() => this.onClk(3)}>
                <div className="dashboardCard1">
                  <Icon icon={circle_ok} size={45} style={{ color: 'green' }} />
                  <h2 className="mt-2 black" >Docs pending</h2>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].fill ? this.state.dashboard[0].fill : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col " onClick={() => this.onClk(4)}>
                <div className="dashboardCard1">
                  <Icon icon={circle_ok} size={45} style={{ color: 'green' }} />
                  <h2 className="mt-2 black" >Approved</h2>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].phyval ? this.state.dashboard[0].phyval : 0}</b></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.tradedata, phy: false }) }

    if (val === 3) { this.setState({ Datas: this.state.data2, phy: false }) }
    if (val === 4) { this.setState({ Datas: this.state.phyval, phy: true }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  render() {
    let { loading } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Documentation</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          id="status"
                          extraClass="btn btn-primary btn-sm"
                          onClick={this.reset}
                          extraStyle={{ width: "1000px" }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white",
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div>
                    {loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.Data.length ? (
                      this.state.Datas.length ? <Datatable
                        data={this.state.Datas}
                        columnHeading={this.state.phy ? this.state.PhyColoumn : this.state.column}
                        excelColumn={this.state.phy ? this.state.excelPhyColoumn : this.state.excelColumn}
                      /> : null
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default DfCarsdocumentation;
