import React, { Component } from "react";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { x } from 'react-icons-kit/iconic/x'
import FormModal from "../../../../components/Modal/FormModal";
import LoginModal from "../../../../components/Modal/Modal";
import PaymentModal from '../SalesMaster/ContractPaymentProcess'
import { ACCESS_POINT } from "../../../../config";
import { time } from "react-icons-kit/ikons/time";
import { calendar } from "react-icons-kit/ikons/calendar";
import { user_delete } from "react-icons-kit/ikons/user_delete";
import { cog } from "react-icons-kit/ikons/cog";
import { shuffle } from "react-icons-kit/ikons/shuffle";
import { shopping_cart_delete } from "react-icons-kit/ikons/shopping_cart_delete";

class TLContracted extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      loading: false,
      User: JSON.parse(localStorage.getItem("userlog")),
      // userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      isEdit: false,
      Datas: [],
      Edit_Data: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      contractFTD: [],
      contractMTD: [],
      delivery_pending: [],
      contractcommit: [],
      allcontract: [],
      modelopt: [],
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          Cell: d => this.Document(d),
          className: "text-center",
          width: 100
        },
        {
          Header: "Payment Status",
          minWidth: 140,
          Cell: d => this.Status(d),
          className: "text-center"

        },
        {
          Header: "View",
          accessor: "approval",
          width: 120,
          Cell: d => this.view_Approval(d),
          className: "text-center"
        },
        // {
        //   Header: "Payment Approval",
        //   accessor: "paymentApproval",
        //   width: 180,
        //   Cell: d => this.payment_Approval(d),
        //   className: "text-center"
        // },
        {
          Header: "Approval",
          accessor: "mobile",
          width: 170,
          Cell: d => this.Approval(d),
          className: "text-center"
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "reg_no"
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },

        {
          Header: "Print PDF",
          accessor: "print",
          minWidth: 120,
          Cell: d => this.viewpdf(d),
          className: "text-center"

        },
        {
          Header: "type",
          accessor: "data_type",
          Cell: d => this.data_type(d),
          minWidth: 140

        },

      ],
      excelColumn: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "SalesConsultantName",
          key: "Sales_employee_name"
        },
        {
          label: "REG NO",
          key: "reg_no"
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Color",
          key: "colourName",
        },
        {
          label: "type",
          key: "data_type",

        }


      ],
      data_for_f: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0, "ftd": 0 }],
      stage: "",
      CustomerId: "",
      filteredLoc: "",
      filteredMake: "",
      LocationOptions: [],
      FilterData: [],
    };
    this.vals()
  }
  async vals() {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      await this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt,
        LocationOptions: allOpt.locopt

      })
    }
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<button type="button" className="btn btn-sm btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const fproofid = await cmsContent.getFreedom("*", "tbl_Booking_Confirmation_Reproof", `customerId=${d.original.id}`, 1, 1);
    console.log(fproofid)
    if (fproofid) {
      let fproofpdf = await cmsContent.Bookingconfirm("tbl_Booking_Confirmation_Reproof", fproofid.data[0].customerid);
      console.log(fproofpdf)
      if (fproofpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadbookconfirm/${fproofid.data[0].name}`, '_self')
        // window.open(`${ACCESS_POINT}/cmsContent/downloadbookconfirm/${fproofid.data[0].name}`, '_self')
      }
    }
  }
  Status = (d) => {
    if (d.original.FormFilled == "no") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ minWidth: "100px" }}
        >
          Not Filled
        </button>
      )
    } else {
      return (<button className="btn btn-sm btn-primary" style={{ cursor: 'pointer', minWidth: "100px" }}
        onClick={() => this.editdatareset(d)}
      >Payment Details</button>)
    }
  }
  editdatareset = async (d) => {
    await this.setState({ EditData: null, formid: null, Index: null, isEdit: false })
    await this.Editstatus(d)
  }
  Editstatus = async (d) => {
    if (d.original.paymentStatus == 0) {
      await this.setState({ isEdit: true })
    } else {
      await this.setState({ isEdit: false })
    }
    await this.setState({ EditData: d.original, formid: d.original.formid, Index: d.index })
    window.$('#Payment').modal("show");
  }
  view_Approval = d => {

    return (
      <button
        type="button"
        className="btn btn-sm btn-primary"
        style={{ minWidth: "100px" }}
        onClick={() => this.opentable(d.original)}
      >
        View Details
      </button>
    )
  };
  payment_Approval = d => {
    if (d.original.paymentApproval == 0 && d.original.FormFilled == "yes") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          style={{ minWidth: "100px" }}
          onClick={() => this.alert_validation(this.PaymentApprovalContract, d)}
        >
          Approve
        </button>
      )
    }
    else if (d.original.paymentApproval == 1 && d.original.FormFilled == "yes") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          style={{ minWidth: "100px" }}
        // onClick={() => this.PaymentApprovalContract(d.original)}
        >
          Approved
        </button>
      )
    }
    else if (d.original.FormFilled == "no") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ minWidth: "100px" }}
        >
          form not filled
        </button>
      )
    }
    else {
      return (
        <>
          -
        </>

      )
    }

  }
  PaymentApprovalContract = async (d, text) => {
    let data = d.original;
    let approvaldatas = {};

    if (text == "yes") {
      approvaldatas.paymentApproval = 1;
    } else {
      approvaldatas.paymentApproval = 0;
    }
    try {
      let results = await cmsContent.updateMaster(
        'tbl_dfcarz_prosperities',
        data.id,
        approvaldatas)
      if (results) {
        this.MainFunction();
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  opentable = async (d) => {
    if (d) {
      let Edit_Data = d
      console.log(Edit_Data, "Djdgjdgdjgddd");
      await this.setState({ Edit_Data })
      window.$("#formmodel").modal("show")
    }
  }
  Approval = d => {
    // console.log(d, "dataa");
    let { usertypelog } = this.state;

    if (d.original.FormFilled === "no") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ minWidth: "150px" }}
        >Pending</button>

      );
    }
    if (d.original.FormFilled === "yes" && d.original.paymentStatus == 0) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ minWidth: "150px" }}
        >Payment Pending</button>

      );
    }
    if (d.original.FormFilled === "yes" && d.original.filter_filled_only == true && d.original.paymentStatus == 0) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ minWidth: "150px" }}
        >Pending</button>

      );
    }
    else if (d.original.FormFilled === "yes" && d.original.approval == 0 && d.original.paymentStatus == 1 && usertypelog.orderBy != 3) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          style={{ minWidth: "150px" }}
          onClick={() => this.alert_validation(this.Approval_in_tl, d)}
        >Approve</button>
      );
    }
    else if (d.original.approval == 1 && d.original.commit == 1 && d.original.accounts_approval == 0 && d.original.Rfstatus == null && d.original.paymentStatus == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-warning"
          style={{ minWidth: "150px" }}
        >Waiting for RF Work</button>
      )
    }
    else if (d.original.approval == 1 && d.original.accounts_approval == 0 && d.original.paymentStatus == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-warning"
          style={{ minWidth: "150px" }}
        >Waiting for Accounts</button>
      )
    }
    else if (d.original.approval == 1 && d.original.accounts_approval == 1 && d.original.paymentStatus == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          style={{ minWidth: "150px" }}
        >Approved</button>
      )
    }
    else if (d.original.approval == 1 && d.original.accounts_approval == 2 && d.original.paymentStatus == 1) {
      return (
        <button
          type="button"
          style={{ minWidth: "150px" }}
          className="btn btn-sm btn-danger"

        >Accounts Rejected</button>
      )
    }
    else if (d.original.approval == 2) {
      return (<button
        type="button"
        className="btn btn-sm btn-danger"
        style={{ minWidth: "150px" }}
        onClick={() => this.alert_validation(this.Approval_in_tl, d)}
      >Rejected</button>)
    }
    else {
      if (usertypelog.orderBy != 3) {
        return (
          <button
            type="button"
            className="btn btn-sm btn-warning"
            style={{ minWidth: "150px" }}
            onClick={() => this.alert_validation(this.Approval_in_tl, d)}
          >Approve</button>
        );
      }
      else {
        return (
          <>
            -
          </>
        )
      }

    }
  };
  data_type = d => {
    if (d.original.data_type == "Commit Work") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-danger"
          style={{ minWidth: "120px" }}
        >Commit Work</button>

      );
    }
    else {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          style={{ minWidth: "120px" }}

        >Normal</button>
      );
    }

  };
  Document = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-warning"
        onClick={() => this.F_proofForm1(d)}
      >
        View Form
      </button>
    );
  };

  F_proofForm1 = async d1 => {
    window.open(
      `/DFCars/AT_Booking_Confirmation_Reproof/${d1.original.id}`,
      "_self"
    );
  };

  Approval_in_tl = async (d, text) => {
    let data = d.original;
    let body = {};
    body.customerid = data.id;
    if (text == "yes") {
      body.approval = 1;
    } else {
      body.approval = 2;
    }
    body.Approved_date = moment().format("YYYY-MM-DDThh:mm")
    body.Approved_by = this.state.User.id
    console.log(data, "data");
    try {
      let results = await cmsContent.updateMaster(
        'tbl_Booking_Confirmation_Reproof',
        data.formid,
        body)
      if (results && text == "yes" && data.data_type == "Commit Work") {
        let sub = {}
        sub.enquiryid = data.enquiry_id
        sub.prosid = data.id
        sub.process = "1"
        sub.from = "contract"
        sub.userid = data.userid
        sub.contractid = data.formid
        sub.approved_user_id = this.state.User.id
        let results = null

        results = await cmsContent.addMaster('tbl_dfcars_RF', sub)

        if (results) {
          await this.MainFunction()
        }
      } else {
        await this.MainFunction()
      }
    }
    catch (error) {
      console.log(error);
    }

  };
  MainFunction = async () => {
    try {
      await this.setState({ loading: true, data2: [], data3: [], data4: [], data5: [], Datas: [] })
      let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'contract')
      console.log(results, "API");
      if (results.fulldata) {
        let fulldata = []
        if (results && results.fulldata) {
          fulldata = results.fulldata;
        }
        let logos = results;
        let Data = logos.fulldata;
        await this.setState({
          Data,
          contractFTD: fulldata.length ? fulldata.filter(e => e.contractFTD == true) : [],
          contractMTD: fulldata.length ? fulldata.filter(e => e.contractMTD == true) : [],
          delivery_pending: fulldata.length ? fulldata.filter(e => e.delivery_pending == true) : [],
          allcontract: fulldata,
          contractcommit: fulldata.length ? fulldata.filter(e => e.contractcommit == true) : [],

          data2: fulldata.length ? fulldata.filter(e => e.filter_approved == true) : [],
          data3: fulldata.length ? fulldata.filter(e => e.filter_filled == true) : [],
          data4: fulldata.length ? fulldata.filter(e => e.filternon_filled == true) : [],
          data5: fulldata.length ? fulldata.filter(e => e.filter_reject == true) : [],
          data6: fulldata.length ? fulldata.filter(e => e.filter_filled_only == true) : [],
          userId: results.userid,
          data_for_f: results.data_for_f,
          loading: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    await this.MainFunction()

  }
  edash = () => {
    return (<div className="">
      <div className="card">

        <div className="card-label">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(6)}>
                <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                <h4 className="mt-2 black" >FTD Contract</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].FTD ? this.state.data_for_f[0].FTD : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(7)}>
                <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                <h4 className="mt-2 black" >MTD Contract</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].MTD ? this.state.data_for_f[0].MTD : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(10)}>
                <Icon icon={cog} style={{ color: 'purple' }} size={35} /><br />
                <h4 className="mt-2 black" >Under RF Comitement</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].Comitement ? this.state.data_for_f[0].Comitement : 0}</b></h1>
                </div>
              </div>
            </div>
            {/* <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Approved</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].approved ? this.state.data_for_f[0].approved : 0}</b></h1>
                </div>
              </div>
            </div> */}
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                <Icon icon={x} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Rejected</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].reject ? this.state.data_for_f[0].reject : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon icon={user_delete} style={{ color: '#4285F4' }} size={35} />
                <h4 className="mt-2 black" >Approval Pending</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].approval ? this.state.data_for_f[0].approval : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(8)}>
                <Icon icon={shopping_cart_delete} style={{ color: 'red' }} size={35} /><br />
                <h4 className="mt-2 black" >Delivery Pending</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].delivery_pending ? this.state.data_for_f[0].delivery_pending : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(9)}>
                <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                <h4 className="mt-2 black" >All Contract</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.allcontract ? this.state.allcontract.length : 0}</b></h1>
                </div>
              </div>
            </div>
            {/* <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(5)} >
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Filled</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].filled ? this.state.data_for_f[0].filled : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Non-Filled</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].nonfilled ? this.state.data_for_f[0].nonfilled : 0}</b></h1>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data2, FilterData: this.state.data2, filteredLoc: "", filteredMake: "" }) }
    if (val === 2) { this.setState({ Datas: this.state.data3, FilterData: this.state.data3, filteredLoc: "", filteredMake: "" }) }
    if (val === 3) { this.setState({ Datas: this.state.data4, FilterData: this.state.data4, filteredLoc: "", filteredMake: "" }) }
    if (val === 4) { this.setState({ Datas: this.state.data5, FilterData: this.state.data5, filteredLoc: "", filteredMake: "" }) }
    if (val === 5) { this.setState({ Datas: this.state.data6, FilterData: this.state.data6, filteredLoc: "", filteredMake: "" }) }


    if (val === 6) { this.setState({ Datas: this.state.contractFTD, FilterData: this.state.contractFTD, filteredLoc: "", filteredMake: "" }) }
    if (val === 7) { this.setState({ Datas: this.state.contractMTD, FilterData: this.state.contractMTD, filteredLoc: "", filteredMake: "" }) }


    if (val === 8) { this.setState({ Datas: this.state.delivery_pending, FilterData: this.state.delivery_pending, filteredLoc: "", filteredMake: "" }) }
    if (val === 9) { this.setState({ Datas: this.state.allcontract, FilterData: this.state.allcontract, filteredLoc: "", filteredMake: "" }) }

    if (val === 10) { this.setState({ Datas: this.state.contractcommit, FilterData: this.state.contractcommit, filteredLoc: "", filteredMake: "" }) }

    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  resetModal = (Modalid) => {
    window.$(`#${Modalid}`).modal("hide");
    this.setState({
      EditData: [],
      isEdit: false
    })
  }
  makeFilter = async (d) => {
    let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

    //   console.log(d, "d")

    await this.setState({ filteredMake: d })

    if (d && d.length == 0 && filteredLoc.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredLoc.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
      var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
      var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
      var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }
  }
  locationFilter = async (d) => {
    let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
    await this.setState({ filteredLoc: d })

    if (d && d.length == 0 && filteredMake.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredMake.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length != 0) {
      var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
      var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
      var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }

  }
  clearFilter = async () => {
    await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
  }
  render() {
    let { Edit_Data, LocationOptions, MakeOptions } = this.state
    console.log(this.state.Datas, "Edit_this.state.DatasData");
    return (
      <React.Fragment>
        <FormModal
          id={"formmodel"}
          extraStyle={{ width: 'auto' }}
          bodyText={
            <div>
              {this.state.Edit_Data &&
                <table className="LeadTable">
                  <thead>
                    <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                      <th class="column1">Type</th>
                      <th class="column2">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Edit_Data.name && <tr>
                      <td class="column1">Name </td>
                      <td class="column2"> {Edit_Data.name} </td>
                    </tr>}
                    {Edit_Data.mobile && <tr>
                      <td class="column1">Mobile Number </td>
                      <td class="column2"> {this.Call(Edit_Data.mobile)} </td>
                    </tr>}
                    {Edit_Data.make && <tr>
                      <td class="column1">Make</td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.make, this.state.MakeOptions)} </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Model </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.model, this.state.modelopt)} </td>
                    </tr>}
                    {Edit_Data.variant && <tr>
                      <td class="column1">Variant </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.variant, this.state.variantopt)} </td>
                    </tr>}
                    {Edit_Data.color && <tr>
                      <td class="column1">Color </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.color, this.state.coloropt)} </td>
                    </tr>}
                    {Edit_Data && Edit_Data.commit == 1 && <tr>
                      <td class="column1">Commit</td>
                      <td class="column2"> Yes </td>
                    </tr>}
                    {Edit_Data && Edit_Data.commit_work && Edit_Data.commit_work.length > 0 && <tr>
                      <td class="column1">Commit Work  </td>
                      <td class="column2">
                        {Edit_Data.commit_work && Edit_Data.commit_work.length && Edit_Data.commit_work.map((ival, i) => {
                          return (
                            <>
                              {i + 1}: {ival}<br />
                            </>
                          )
                        })}
                      </td>
                    </tr>}
                    {Edit_Data.Sales_employee_name && <tr>
                      <td class="column1">Sales Consultant </td>
                      <td class="column2"> {Edit_Data.Sales_employee_name} </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Approval </td>
                      <td class="column2"> {Edit_Data.paymentStatus == 1 ? Edit_Data.approval == 1 ? "Approved" : Edit_Data.approval == 2 ? "Rejected" : Edit_Data.approval == 0 ? "Waiting for Approval" : "-" : "Please Fill Payment Details"} </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Accounts Approval  </td>
                      <td class="column2"> {Edit_Data.paymentStatus == 1 ? Edit_Data.accounts_approval == 1 ? "Approved" : Edit_Data.accounts_approval == 2 ? "Rejected" : Edit_Data.accounts_approval == 0 ? "In Progress" : "-" : "Please Fill Payment Details"} </td>
                    </tr>}
                  </tbody>
                </table>
              }
            </div>
          }
        />
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Contract</h1>
                  </div>

                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          title="Payment Process"
                          id="Payment"
                          extraStyle={{ width: 'auto' }}
                          // extraStyle={{ width: 1200, marginLeft: -200 }}
                          buttonStyle={{ backgroundColor: "white", border: "white" }}
                          bodyText={
                            <PaymentModal
                              EditData={this.state.EditData}
                              these={this.MainFunction}
                              isEdit={this.state.isEdit}
                              FullData={this.state.Data}
                              close={this.resetModal}
                              statusarray={[{ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: "", paymentdate: '', index: 0, error: null }]}
                            />}
                        />
                      </div>
                    </div>
                    {
                      this.state.FilterData && this.state.FilterData.length ?
                        <>
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-6">
                                <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6">
                                <MultiSelect
                                  id="locationFilter"
                                  name="location"
                                  placeholder="Filter Location"
                                  options={LocationOptions}
                                  handleChange={d => this.locationFilter(d)}
                                  selectedService={this.state.filteredLoc}
                                />
                              </div>
                              <div className="col-sm-6">
                                <MultiSelect
                                  id="makefilter"
                                  name="make"
                                  placeholder="Filter Make"
                                  options={MakeOptions}
                                  handleChange={d => this.makeFilter(d)}
                                  selectedService={this.state.filteredMake}
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              <div className="col-sm-5" />
                              <div className="col-sm-2">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                  this.clearFilter()
                                }}> Clear filter</button>
                              </div>
                              <div className="col-sm-5"></div>
                            </div>
                          </div>
                          <br></br>
                        </>
                        :
                        ""

                    }

                    {this.state.loading
                      ?
                      <div className="text-center"> <i
                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                      ></i></div>
                      :
                      this.state.Data && this.state.Data.length > 0
                        ? (this.state.Datas &&
                          this.state.Datas.length > 0
                          ?
                          <Datatable
                            data={this.state.Datas}
                            columnHeading={this.state.column}
                            excelColumn={this.state.excelColumn}
                          />
                          :
                          <>
                            <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                            <br></br>
                          </>
                        )
                        :
                        (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default TLContracted;