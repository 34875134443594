import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import DfCarsAddEnquiry from "../Screen/DfCarsAddEnquiry";
import Updatefollowup from "./../Updatestatus";
import swal from 'sweetalert';
import SingleSelect from "../../../../components/Form/SingleSelect";
import { Link } from "react-router-dom";
import { ic_directions_walk } from 'react-icons-kit/md/ic_directions_walk'
import { time } from 'react-icons-kit/ikons/time'
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'
import { user_delete } from 'react-icons-kit/ikons/user_delete'
import { close } from 'react-icons-kit/ikons/close'
import { x } from 'react-icons-kit/iconic/x'
import { alarmClock } from 'react-icons-kit/metrize/alarmClock'
import Icon from "react-icons-kit";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../../../config/index";
import { shuffle } from "react-icons-kit/ikons/shuffle";
import DatePicker from "../../../../components/Form/DatePicker";


class DfcarsSalesEnquiry extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            statusbtn:false,
            StatusupdatealertVisible: false,
            alertVisible: false,
            alertVisible1: false,
            editvalue: "",
            editid: null,
            isEdit: false,
            EnquiryData: [],
            ftdval: false,
            EditData: {},
            StatusEditData: {},
            status: {},
            status1: {},
            status2: {},
            status3: {},
            sellerstatus1options: [{ label: "Yes, Interested in Buying", value: 0 },
            { label: "Yes, but not interested, Mark closed", value: 1 },
            { label: "Couldn’t speak to customer", value: 2 }
            ],
            sellerstatus1: {},
            sellerstatus2: {},
            sellerstatus3: {},
            sellerstatus2options: {},
            sellerstatus3options: {},
            remarkVisible: false,
            remarks: "",
            followup: "",
            EnquiryDatas: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            statuschg1: false,
            statuschg2: false,
            status1options: [
                { value: 0, label: "Scheduled Follow up" },
                { label: "Scheduled Evaluation", value: 1 },
                { value: 2, label: "Mark Closed" },
                // {value:2,label:"Evaluated"},
            ],
            status2options: {},
            status3options: {},
            modelopt: [],
            Index: null,
            loading: false,
            userid: JSON.parse(localStorage.getItem("userlog")),
            customerId: JSON.parse(localStorage.getItem(`userlog`)),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            LocationID: localStorage.getItem(`LocationID`),
            CloseEnquiry: false,
            status: [{ label: "Scheduled Follow up", value: 1 }, { label: "Closed", value: 2 }, { label: "Evaluated", value: 3 }],
            LocationDetail: [],
            suggestion: [],
            suggestion1: [],
            dataof: [],
            remainder: [],
            data1: [],
            FullData1: [],
            closeddata: [],
            livedata: [],
            allEnquiry: [],
            ftddata: [],
            noncontactdata: [],
            dateVisible: false,
            ftdDashDate: moment(new Date()).format("YYYY-MM-DD"),
            ftd: null,
            dashboard: [{ "live": 0, "close": 0, "today": 0, "MTD": 0, "remainder": 0 }],
            FullData: [],
            statusArray: [],
            followJson1: [{
                date: "",
                status1: "",
                status2: "",
                status3: "",
                remarks: "",
                follow: ""
            }],
            column: [
                {
                    Header: "View status",
                    accessor: "edit",
                    width: 120,
                    Cell: d => this.StatusView(d)
                },
                {
                    Header: "Name",
                    accessor: "name"
                },
                {
                    Header: "Reg no",
                    accessor: "Reg_no"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                // {
                //   Header: "Email_id",
                //   accessor: "email"
                // },
                // {
                //   Header: "Type",
                //   accessor: "type"
                // },
                {
                    Header: "Enquriry date",
                    accessor: "createdAt"
                },
                {
                    Header: "Make",
                    accessor: "makeName",
                },
                {
                    Header: "Model",
                    accessor: "modelName",
                },
                {
                    Header: "Variant",
                    accessor: "variantName",
                },
                {
                    Header: "Color",
                    accessor: "colorName",
                },

                // {
                //   Header: "Budget",
                //   accessor: "Budget"
                // },
               
                {
                    Header: "Form",
                    accessor: "edit",
                    Cell: d => this.UpdateStatus(d)
                },

                // {
                //   Header: "Status 1",
                //   accessor: "pendingStatus1",

                // },
                // {
                //   Header: "Status 2",
                //   accessor: "pendingStatus2",

                // },
                // {
                //   Header: "Status 3",
                //   accessor: "pendingStatus3",

                // },

                // {
                //   Header: "Next Action plan",
                //   accessor: "followup",
                //   Cell: d => this.followup(d)
                // },

                // {
                //   Header: "NAP status",
                //   accessor: "PendingStatus",
                //   Cell: d => this.NAPstatus(d)
                // },
                // {
                //   Header: "Status 2",
                //   accessor: "followstatus",
                //   //Cell: d => this.followstatus(d)
                // },
                // {
                //   Header: "View History",
                //   accessor: "edit",
                //   Cell: d => this.ViewLead(d)
                // },
                // {
                //   Header: "Convert to Prospect",
                //   accessor: "edit",
                //   Cell: d => this.ViewLead1(d)
                // },
                // {
                //   Header: "Close Enquiry",
                //   accessor: "type",
                //   Cell: d => this.CloseEnquiry(d)
                // }
            ],
            column1: [
                {
                    Header: "Update status",
                    accessor: "edit",
                    Cell: d => this.UpdateStatus(d),
                    width: 140,
                    className: "text-center"
                },
                {
                    Header: "View status",
                    accessor: "edit",
                    width: 120,
                    Cell: d => this.StatusView(d),
                    className: "text-center"
                },
                {
                    Header: "View Details",
                    accessor: "view",
                    width: 120,
                    Cell: d => this.fullview(d),
                    className: "text-center"

                },
                {
                    Header: "Leads",
                    accessor: "name"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                // {
                //     Header: "Contact",
                //     accessor: "mobile"
                // },
                {
                    Header: "Make",
                    accessor: "makeName",
                },
                {
                    Header: "Model",
                    accessor: "modelName",
                },
                {
                    Header: "Variant",
                    accessor: "variantName",
                },
                {
                    Header: "Color",
                    accessor: "colourName",
                },
                // {
                //   Header: "Email_id",
                //   accessor: "email"
                // },
                {
                    Header: "Source",
                    accessor: "sources"
                },
                {
                    Header: "Type",
                    accessor: "type"
                },
                {
                    Header: "Enquriry date",
                    accessor: "createdAt"
                },

                {
                    Header: "Budget",
                    accessor: "budget",
                    width:150
                },
               
                // {
                //   Header: "CustomerId",
                //   accessor: "customerid"
                // },
                {
                    Header: "Print pdf",
                    accessor: "print",
                    Cell: d => this.viewpdf(d),
                    width: 110,
                    className: "text-center"

                },
            ],
            statuscolumn1: [
                {
                    Header: "Update status",
                    accessor: "edit",
                    Cell: d => this.UpdateStatus(d),
                    width: 140,
                    className: "text-center"

                },
                {
                    Header: "View status",
                    accessor: "edit",
                    width: 120,
                    Cell: d => this.StatusView(d),
                    className: "text-center"

                },
                {
                    Header: "View Details",
                    accessor: "view",
                    width: 100,
                    Cell: d => this.fullview(d),
                    className: "text-center"

                },
                {
                    Header: "Leads",
                    accessor: "name"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                {
                    Header: "Contact",
                    accessor: "mobile"
                },
                {
                    Header: "Make",
                    accessor: "makeName",
                },
                {
                    Header: "Model",
                    accessor: "modelName",
                },
                {
                    Header: "Variant",
                    accessor: "variantName",
                },
                {
                    Header: "Color",
                    accessor: "colourName",
                },
                // {
                //   Header: "Email_id",
                //   accessor: "email"
                // },
                {
                    Header: "Source",
                    accessor: "sources"
                },
                {
                    Header: "Status",
                    minWidth: 180,
                    accessor: "pendingStatus2"
          
                },
                {
                    Header: "Type",
                    accessor: "type"
                },
                {
                    Header: "Enquriry date",
                    accessor: "createdAt"
                },

                {
                    Header: "Budget",
                    accessor: "budget"
                },
               
                // {
                //   Header: "CustomerId",
                //   accessor: "customerid"
                // },
                {
                    Header: "Print pdf",
                    accessor: "print",
                    Cell: d => this.viewpdf(d),
                    width: 100
                },
               
               
            ],
            excelColumn1: [
                {
                    label: "Leads",
                    key: "name"
                },
                {
                    label: "Mobile",
                    key: "mobile",
                },
                // {
                //     label: "Contact",
                //     key: "mobile"
                // },
                {
                    label: "Make",
                    key: "makeName",
                },
                {
                    label: "Model",
                    key: "modelName",
                },
                {
                    label: "Variant",
                    key: "variantName",
                },
                {
                    label: "Color",
                    key: "colourName",
                },
                {
                    label: "Source",
                    key: "sources"
                },
                {
                    label: "Type",
                    key: "type"
                },
                {
                    label: "Enquriry date",
                    key: "createdAt"
                },

                {
                    label: "Budget",
                    key: "budget"
                }

            ],
            evalcolumn: [

                {
                    Header: "Name",
                    accessor: "name",
                    // clickable: d => this.name(d)

                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                    // cell: d => this.call(d)
                },

                {
                    Header: "Form",
                    accessor: "id",
                    Cell: d => this.fFrom(d.original)
                }
            ],
            CustomerList: []
        };
    }
    fullview = (d) => {
        return (<button type="button" className="btn btn-sm btn-success"
            data-toggle="modal" data-target={"#fullview"}
            onClick={() => this.ViewHistory1(d)}
        >Details</button>)
    }
    viewpdf = d => {
        // console.log(d.original, 'view pdf');
        if (d.original.pendingStatus1 && d.original.pendingStatus2) {
            return (<button type="button" className="btn btn-warning btn-sm"
                onClick={() => this.generatepdf(d)}>Print PDF</button>)
        }
        else {
            return (
                <span className="label" style={{ backgroundColor: "white" }}></span>)
        }
    }
    generatepdf = async (d) => {
        const fproofid = await cmsContent.getFreedom("*", "tbl_dfcarz_enquiryform", `id=${d.original.id}`, 1, 1);

        if (fproofid) {
            // console.log("Enter PDF");
            let fproofpdf = await cmsContent.autoterrace("tbl_dfcarz_enquiryform", fproofid.data[0].id);
            // console.log(fproofpdf, "fproofpdf")
            if (fproofpdf.data == "success") {
                window.open(`${ACCESS_POINT}/cmsContent/downloadauto/${fproofid.data[0].name}`, '_self')

            }
        }
    }
    fFrom = (d) => {
        if (d.F_formfilled == "no") {
            return <button className='btn btn-sm btn-primary' onClick={() => this.navigateF(d)}>Start Evaluation</button>
        } else {
            return true;
        }
    }
    navigateF = (d) => {
        window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.id}`)
    }
    followup = (d) => {
        let d1 = [];
        if (d.original.Next_Contact_date) {
            d1 = d.original.Next_Contact_date.split(" ")[0];
            d1 = moment(d1).format("DD-MM-YYYY");
        }
        return (<span style={{ cursor: 'pointer', textDecoration: "underline" }} data-toggle="modal" data-target={"#followup"}
            onClick={() => this.Editdata(d)}
        >{d1}</span>)
    }

    Editdata = async (d) => {
        ////console.log(111)
        this.setState({ EditData: d.original, CustomerId: d.original.id, IsClose: false, isEdit: true, Index: d.index })
    }
    NAPstatus = (d) => {
        if (d.original.pendingStatus == 'Scheduled Follow up') {
            return (<button type="button" className="btn btn-success btn-sm"
                onClick={() => this.ChangeStatus1(d)}
            >Scheduled Follow up</button>)
        }
        else if (d.original.pendingStatus == "Evaluate") {
            return (<button type="button" className="btn btn-warning btn-sm"
                onClick={() => this.ChangeStatus1(d)}
            >Evaluated</button>)
        } else if (d.original.pendingStatus == "Closed") {
            return (<button type="button" className="btn btn-danger btn-sm"
                onClick={() => this.ChangeStatus1(d)}
            >Closed</button>)
        }

    }


    StatusView = (d) => {
        if (d.original.filterclosed == true) {
            return (
                <button
                    type="button"
                    style={{ width: "100px" }}
                    className="btn btn-danger btn-sm"
                >
                    Closed
                </button>
            );
        } else {
            return (
                <button
                    type="button"
                    style={{ width: "100px" }}
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target={"#matchcarmodal"}
                    onClick={() => this.Status(d)}
                >
                    View
                </button>
            );
        }
    }
    Status = (d) => {
        console.log(d.original.followup, "d.orginal.followup");
        let statusArray = []
        if (d.original.followup) {
            statusArray = JSON.parse(d.original.followup)
        }

        this.setState({ StatusEditData: d.original, remarks: "", followup: "", statusArray })
        this.form_filled(d)
    }
    form_filled = async (d) => {
        console.log(d.original, "ddd")

        let ans = d.original;
        let { usertypelog } = this.state
        let followJson1 = this.state.followJson1;
        if (usertypelog.orderBy == 8) {
            // console.log(ans);
            if (ans.pendingStatus1) {
                let followJson1 = this.state.followJson1;
                let w1 = await this.state.sellerstatus1options.map(async (ival, i) => {
                    // console.log(ival);
                    if (ival.label == ans.pendingStatus1) {
                        // console.log("ok");
                        await this.handlestatusseller1(ival)
                    }
                })
                await Promise.all(w1)
            }
            if (ans.pendingStatus2) {
                let w = await this.state.sellerstatus2options.map(async (ival, i) => {
                    if (ival.label == ans.pendingStatus2) {
                        await this.handlestatusseller2(ival)
                    }
                })
                await Promise.all(w)
            }
            if (ans.followup) {
                let followup = JSON.parse(ans.followup)
                console.log(followup[0].remarks, "remarks")
                followup[0].remarks && this.setState({ remarks: followup[0].remarks })
                followup[0].follow && this.setState({ followup: followup[0].follow })
            }

        }
        else {
            if (ans.pendingStatus1) {
                let followJson1 = this.state.followJson1;
                let w1 = await this.state.status1options.map(async (ival, i) => {
                    // console.log(ival);
                    if (ans.pendingStatus1 == "Schedule walkin") {
                        ans.pendingStatus1 = "Scheduled Evaluation"
                    }
                    if (ival.label == ans.pendingStatus1) {
                        // console.log("ok");
                        await this.handlestatus1(ival)
                    }
                })
                await Promise.all(w1)
            }
            if (ans.pendingStatus2) {
                let w = await this.state.status2options.map(async (ival, i) => {
                    if (ival.label == ans.pendingStatus2) {
                        await this.handlestatus2(ival)
                    }
                })
                await Promise.all(w)
            }
        }

    }
    status1 = () => {
        let { usertypelog } = this.state
        if (usertypelog.orderBy == 8) {
            return (
                <div>
                    <SingleSelect
                        placeholder="Select..."
                        options={this.state.sellerstatus1options}
                        handleChange={d => this.handlestatusseller1(d)}
                        selectedService={this.state.sellerstatus1}
                    />
                </div>)
        }
        else {
            return (
                <div>
                    <SingleSelect
                        placeholder="Select..."
                        options={this.state.status1options}
                        handleChange={d => this.handlestatus1(d)}
                        selectedService={this.state.status1}
                    />

                </div>)
        }
    }
    status2 = () => {
        if (this.state.usertypelog.orderBy == 8) {
            return (
                <div>
                    <SingleSelect
                        placeholder="Select..."
                        options={this.state.sellerstatus2options}
                        handleChange={d => this.handlestatusseller2(d)}
                        selectedService={this.state.sellerstatus2}
                    />

                </div>)
        }
        else {
            return (
                <div>
                    <SingleSelect
                        placeholder="Select..."
                        options={this.state.status2options}
                        handleChange={d => this.handlestatus2(d)}
                        selectedService={this.state.status2}
                    />
                </div >
            )
        }
    }
    status3 = () => {
        if (this.state.usertypelog.orderBy == 8) {
            return (
                <div>
                    <SingleSelect
                        placeholder="Select..."
                        options={this.state.sellerstatus3options}
                        handleChange={d => this.handlestatusseller3(d)}
                        selectedService={this.state.sellerstatus3}
                    />
                </div>)
        } else {
            return (
                <div>
                    <SingleSelect
                        placeholder="Select..."
                        options={this.state.status3options}
                        handleChange={d => this.handlestatus3(d)}
                        selectedService={this.state.status3}
                    />
                </div >
            )
        }
    }
    handlestatusseller1 = async e => {
        this.setState({ sellerstatus1: e, sellerstatus2: {}, sellerstatus3: {}, status3visible: false });
        if (e.label == "Yes, Interested in Buying") {
            this.setState({
                status2visible: true,
                remarkVisible: true,
                sellerstatus2options: [
                    { label: "Schedule walkin", value: 0 },
                    { label: "Schedule follow up", value: 0 }
                ]
            })
        } else if (e.label == "Yes, but not interested, Mark closed") {
            this.setState({
                status2visible: true,
                remarkVisible: false,
                remarks: "",
                followup: "",
                sellerstatus2options: [{ label: "Fake Lead", value: 0 },
                { label: "Customer Bought another car", value: 1 },
                { label: "Customer not picking the call", value: 2 },
                { label: "Desired car not available", value: 3 },
                { label: "price issue", value: 4 },
                { label: "Other", value: 5 }],
                status3visible: false, sellerstatus3: {}
            })
        } else if (e.label == "Couldn’t speak to customer") {
            this.setState({
                status2visible: true,
                remarkVisible: false,
                remarks: "",
                followup: "",
                sellerstatus2options: [{ label: "Schedule followup", value: 0 },
                { label: "Mark closed", value: 1 }
                ], status3visible: false, sellerstatus3: {}, sellerstatus3options: {}
            })
        }
    };
    handlestatusseller2 = async e => {
        this.setState({ sellerstatus2: e, sellerstatus3: {} });
        if (e.label == "Mark closed") {
            this.setState({
                status3visible: true, sellerstatus3options: [{ label: "Fake Lead", value: 0 },
                { label: "Customer Bought another car", value: 1 },
                { label: "Customer not picking the call", value: 2 },
                { label: "Desired car not available", value: 3 },
                { label: "price issue", value: 4 },
                { label: "Other", value: 5 }
                ], sellerstatus3: {}
            })
        } else {
            this.setState({ status3visible: false })
        }
    };
    handlestatusseller3 = async e => {
        this.setState({ sellerstatus3: e });
    };

    handlestatus1 = async e => {
        await this.setState({ status1: e, status2: {} });
        if (e.label == "Scheduled Follow up") {
            await this.setState({
                status2visible: true, status2options: [
                    { label: "Under Follow up", value: 1 },
                    { label: " Ringing no response", value: 2 },
                    { label: "Researching", value: 3 },
                    { label: "  Not attempted", value: 4 }
                ], status3options: [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }], status3visible: true
            })
        } else if (e.label == "Scheduled Evaluation") {
            await this.setState({
                status2visible: true, status2options: [{ label: "Appointment taken", value: 1 }],
                status3visible: false
            })
        } else if (e.label == "Mark Closed") {
            await this.setState({
                status2visible: true, status2options: [{ label: " Plan dropped", value: 0 },
                { label: "Non contactable", value: 1 },
                { label: "Lost", value: 2 },
                { label: "Wrong capture", value: 3 },
                { label: "No response", value: 4 },
                { label: "Huge Price Gap", value: 5 },
                ], status3visible: false
            })
        } else if (e.label == "Evaluated") {
            this.setState({
                status2visible: true, status2options: [{ label: " Trade in", value: 0 },
                { label: "Follow-up", value: 1 },
                { label: "Researching", value: 2 },
                { label: "Postponned", value: 3 },
                { label: "Dropped", value: 4 },
                { label: "Not attempted", value: 5 },
                { label: "Non contactable", value: 6 },
                { label: "Price not offered", value: 7 },
                { label: "Huge Price gap", value: 8 },
                { label: "Lost", value: 9 },
                ], status3visible: false
            })
            // console.log(this.state.status2options);
        }
        // else{this.setState({status2visible:false,status3visible:false})}
    };

    handlestatus2 = async e => {
        this.setState({ status2: e });
        if (e.label == "Lost") {
            this.setState({
                status3visible: true, status3options: [{ label: " Co dealer", value: 0 },
                { label: "Competition", value: 1 },
                { label: "End user", value: 2 },
                { label: "Broker", value: 3 },
                { label: "Not known", value: 4 }
                ]
            })
        } else if (e.label == "Non contactable") {
            this.setState({
                status3visible: true, status3options: [{ label: "RNR-3", value: 0 },
                { label: "Wrong number", value: 1 },
                ]
            })
        } else {
            // this.setState({status3visible:false})
        }
    };
    handlestatus3 = async e => {
        this.setState({ status3: e });
    }
    ChangeStatus1 = async (d) => {
        try {

            let result = await CmsContent.getFreedom(
                `tbl_dfcars_comments.*,tbl_UserType.usertype,tbl_user_web.userName as Name,tbl_user_web.id as UserID`,
                `tbl_dfcars_comments,tbl_user_web,tbl_MaptoLocation,tbl_UserType`,
                `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
                1,
                `tbl_dfcars_comments.id DESC`,
            );
            if (result.data.length) {

                let CommentData = result.data[0];


                let arr = {};
                if (d.original.pendingStatus1 == "Scheduled Follow up") {
                    arr.status = "Evaluate"
                }
                else if (d.original.pendingStatus1 == "Evaluated") {
                    arr.status = "Closed"
                }
                else if (d.original.pendingStatus1 == "Closed") {
                    arr.status = "Closed"
                }
                // if (d.original.pendingStatus == 'pending') {
                //   arr.status = 'completed';
                // } else {
                //   arr.status = 'pending';
                // }

                const result1 = await CmsContent.updateMaster(
                    "tbl_dfcars_comments",
                    CommentData.id,
                    arr
                );
                if (result1) {
                    ////console.log(result1);
                    let newData = [...this.state.EnquiryData];
                    newData[d.index].pendingStatus = arr.status;
                    this.setState({
                        Data: newData
                    })
                }

            }

        } catch (error) {
            console.log(error);
        }
    }
    async componentDidMount() {
        try {
            this.setState({ loading: true })

            this.mainFun()
            // const allOpt = await CmsContent.getAllOption()
            let decr = this.Decrypt(localStorage.getItem('getcar'))
            let allOpt = JSON.parse(decr)
            if (allOpt) {
                this.setState({
                    MakeOptions: allOpt.makeopt,
                    coloropt: allOpt.coloropt,
                    variantopt: allOpt.variantopt,
                    modelopt: allOpt.modelopt
                })
            }


        } catch (error) {
            console.log(error);
        }

    }
    mainFun = async () => {
        try {
            this.setState({ EnquiryDatas: [] })
            let user = JSON.parse(localStorage.getItem(`userlog`));
            let logos = await CmsContent.getDfcarzEnquiry(user.id)
            console.log(logos, "logos")

            let data = []


            if (logos.user[0].orderBy == 8) {
                const result = await data.filter(word => word.type == "buyer");
                data = result


                let s1 = [{ label: "Yes, Interested in Buying", value: "Yes, Interested in Buying" },
                { label: "Yes, but not interested, Mark closed", value: "Yes, but not interested, Mark closed" },
                { label: "Couldn?t speak to customer", value: "Couldn?t speak to customer" },
                ]

                this.setState({ options: s1 })
            }
            let dashboard = logos.status

            let fulldata = []
            if (logos.statusdata[0].fulldata && logos.statusdata[0].fulldata.length > 0) {
                fulldata = logos.statusdata[0].fulldata
            }
            // console.log(fulldata, "fulldata");
            this.setState({
                allEnquiry: fulldata,
                EnquiryData: fulldata,
                ftddata: fulldata.length ? fulldata.filter(e => e.filterftd == true) : [],
                noncontactdata: fulldata.length ? fulldata.filter(e => e.filternoncontact == true) : [],
                livedata: fulldata.length ? fulldata.filter(e => e.filterfollowup == true) : [],
                closeddata: fulldata.length ? fulldata.filter(e => e.filterclosed == true) : [],
                data1: fulldata.length ? fulldata.filter(e => e.filterToday == true) : [],
                dataof: fulldata.length ? fulldata.filter(e => e.filtermtd == true) : [],
                dashboard,
                UserId: logos.user[0].id,
                remainder: logos.statusdata[0].remainder,
                orberby: logos.user[0].orderBy,
                loading: false
            });

        } catch (error) {
            await this.setState({ loading: false })
            console.log(error);
        }


    }

    ViewLead = d => {
        return (
            <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.ViewHistory(d)}
            >
                View
            </button>
        );
    };

    CloseEnquiry = d => {
        return (
            <button
                type="button"
                className="btn btn-danger btn-sm"
                data-toggle="modal"
                data-target={"#status"}
                onClick={() => this.ClosethisEnquiry(d)}
            >
                Close
            </button>
        );
    };
    Viewbuylead = d => {
        return (
            <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => this.ViewHistory2(d)}
            >
                Add
            </button>
        );
    };
    ViewLead1 = d => {

        if (d.original.pendingStatus1 == "Scheduled Follow up") {
            return (
                <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.ViewHistory2(d)}
                >
                    Add
                </button>
            );
        }
        else
            if (d.original.pendingStatus1 == "Scheduled Follow up") {
                return (
                    <span className="label" style={{ backgroundColor: "white" }}></span>)
            }
            else {
                return (
                    <span className="label" style={{ backgroundColor: "white" }}></span>)
            }
    };

    ClosethisEnquiry = async d => {
        this.setState({ CloseEnquiry: true });
        this.setState({ Index: d.index, EditData: d.original });
    };

    UpdateStatus = d => {
        if (this.state.usertypelog.orderBy == 8) {
            return (
                <div>
                    <button
                        type="button"
                        className="btn btn-warning btn-sm"
                        data-toggle="modal"
                        data-target={"#status"}
                        onClick={() => this.F_proofForm(d)}
                    >
                        Edit Form
                    </button>
                </div >
            );
        }
        else {
            return (
                <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    data-toggle="modal"
                    data-target={"#status"}
                    onClick={() => this.ViewHistory1(d)}
                >
                    Edit
                </button>
            );
        }
    };

    changeModelstate = async (s, v) => {
        this.setState({ [s]: v });
    };

    ChangeStatus = async (data, id) => {
        try {
            let subgroupArray = {};
            //subgroupArray.enquiry_status = "inactive";
            subgroupArray.prosid = id
            const result = await CmsContent.updateMaster(
                "tbl_dfcarz_enquiryform",
                data.id,
                subgroupArray
            );

            // //console.log(result);
        } catch (error) {
            console.log(error);
        }
    };

    ViewHistory2 = async d => {
        let data = d
        // console.log(d);
        let groupArray2 = {};
        groupArray2.name = data.name;
        groupArray2.mobile = data.mobile;
        groupArray2.email = data.email;
        groupArray2.status = "active";
        groupArray2.companyid = data.companyid;
        groupArray2.enquiry_id = data.id;
        groupArray2.userid = data.evaluatorid;
        groupArray2.type = data.type;
        groupArray2.model = data.Model;
        groupArray2.make = data.Make;
        groupArray2.variant = data.variant;
        //groupArray.budget = data.Budget;
        groupArray2.color = data.color;
        groupArray2.prospective_status = "active";
        groupArray2.location = data.location;
        groupArray2.salesid = data.salesid;
        groupArray2.variant = data.variant;
        groupArray2.evaluatorid = data.evaluatorid;
        groupArray2.reg_no = data.Reg_no;
        groupArray2.year = data.yom;
        // let DaTE = new Date();
        // groupArray2.followup = moment(DaTE).format("YYYY-MM-DD");
        // console.log(groupArray2);
        try {
            const history = await CmsContent.addMaster(
                "tbl_dfcarz_prosperities",
                groupArray2
            );
            // console.log(history);
            if (history) {
                // console.log(history, 'history');
                const prosid = history.data.insertId;
                this.ChangeStatus(data, prosid);
            }

        } catch (error) {
            console.log(error);
        }
    };
    F_proofForm = async (d) => {

        // console.log("id:", d.original.id, "customer id :", d.original.customerid);
        window.open(`/DFCars/Autoterrace_enquiry_form/${d.original.id}`, '_self')
    }
    Autoterrace = () => {
        window.open(`/DFCars/Autoterrace_enquiry_form/`, '_self')

    }
    ViewHistory1 = async d => {
        this.setState({ Index: d.index });
        // console.log(d.original, 'dd');
        this.setState({ isEdit: true, EditData: d.original });
    };

    ViewHistory = async d => {
        window.open(`/DFCars/DfCarsViewhistory/${d.original.id}`);
    };
    reset = async () => {
        //console.log("reset");
        this.setState({
            isEdit: false,
            Editdata: null,
            CustomerId: null,
            EditData: {},
            FullData: null,
            Index: null,
            IsClose: null
        });
    };

    edash = () => {
        return (

            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className=''>
                        <div className="row col-12">
                            <div className="col-sm" ><center>
                                <div className="col-sm-3"> <button className="btn btn-primary btn-sm" onClick={this.Autoterrace}  > Add Enquiry</button>
                                </div>
                            </center>
                            </div>
                        </div><br />
                        <div className="row col-12 mt-2 text-center">
                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                                    <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                                    <h4 className="mt-2 black" >FTD Enquiry</h4>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].FTD ? this.state.dashboard[0].FTD : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                                    <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                                    <h4 className="mt-2 black" >MTD Enquiry</h4>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(1)}>
                                    <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} /><br />
                                    <h4 className="mt-2 black" > Followup</h4>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].live ? this.state.dashboard[0].live : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(10)}>
                                    <Icon icon={user_delete} style={{ color: 'purple' }} size={35} /><br />
                                    <h4 className="mt-2 black" >Non-Contactable</h4>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].noncontact ? this.state.dashboard[0].noncontact : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(6)}>
                                    <Icon icon={close} style={{ color: 'red' }} size={35} /><br />
                                    <h4 className="mt-2 black" >Closed</h4>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].Closed ? this.state.dashboard[0].Closed : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(9)}>
                                    <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                                    <h4 className="mt-2 black" >All Enquiry</h4>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.allEnquiry ? this.state.allEnquiry.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </div >)
    }
    cng = async (e) => {
        this.setState({ ftdval: true })
        console.log(e.target.value);
        let { usertypelog } = this.state
        // console.log(usertypelog.orderBy);
        let d = e.target.value
        let d1 = {}
        d1.date = e.target.value
        d1.orderBy = usertypelog.orderBy
        // d1.id = this.state.UserId
        d1.locationId = this.state.LocationID
        // console.log(d1, 'd1');
        let result = await cmsContent.GetFTD(d, d1)
        if (result.data.length) {
            // console.log(result);
            let v3 = 0;
            let data2 = []
            result.data.map((ival, i) => {
                if (ival.createdAt) {
                    let date11 = new Date(ival.createdAt)
                    let d1 = date11.getDate()
                    let m1 = date11.getMonth() + 1
                    let y1 = date11.getFullYear()
                    if (m1 < 10) {
                        m1 = "0" + m1
                    }
                    let date2 = y1 + "-" + m1 + "-" + d1
                    if (d && date2) {
                        // console.log("enterr");
                        v3 = ++v3;
                    }
                }
            })
            console.log(v3 && v3);
            await this.setState({ ftd: v3, EnquiryDatas: result.data, ftdDashDate: d })
        }
        else {

            let data = []
            await this.setState({ ftd: "0", EnquiryDatas: data })
            console.log(this.state.ftd, "ftddd");
        }
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });

    }
    onClk = (val) => {
        if (val === 2) { this.setState({ EnquiryDatas: this.state.ftddata, dateVisible: false, Eval: false , statusbtn:true}) }
        if (val === 1) { this.setState({ EnquiryDatas: this.state.livedata, dateVisible: false, Eval: false , statusbtn:false}) }
        // if (val === 2) {this.setState({ dateVisible: true, EnquiryData: [] })}
        if (val === 3) { this.setState({ EnquiryDatas: this.state.dataof, dateVisible: true, Eval: false, statusbtn:true }) }
        if (val === 4) { this.setState({ EnquiryDatas: this.state.data1, dateVisible: false, Eval: false , statusbtn:false}) }
        if (val === 5) { this.setState({ EnquiryDatas: this.state.remainder, dateVisible: false, Eval: false, statusbtn:false }) }
        if (val === 6) { this.setState({ EnquiryDatas: this.state.closeddata, dateVisible: false, Eval: false , statusbtn:false}) }
        if (val === 7) { this.setState({ EnquiryDatas: this.state.evaldata, dateVisible: false, Eval: true, statusbtn:false }) }
        if (val === 10) { this.setState({ EnquiryDatas: this.state.noncontactdata, dateVisible: false, Eval: false, statusbtn:false }) }
        if (val === 9) { this.setState({ EnquiryDatas: this.state.allEnquiry, dateVisible: false, Eval: false, statusbtn:false }) }

        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    StatusReset = () => {
        this.setState({
            statuschg1: false, status2visible: false, status2: "",
            status1: null, StatusEditData: {}, status3visible: false,
            sellerstatus1: {}, sellerstatus2: {}, sellerstatus3: {}
        })
    }
    StatusUpdate = async () => {

        if (this.state.usertypelog.orderBy == 8) {
            let { sellerstatus1, sellerstatus2, sellerstatus3, remarks, followup, statusArray } = this.state
            let groupArray = {}
            console.log(followup, "followup");
            let Userlog = JSON.parse(localStorage.getItem("userlog"))
            if (statusArray && statusArray.length) {
                let obj = {}
                if (sellerstatus1) {
                    obj.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
                }
                if (sellerstatus2) {
                    obj.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
                }
                if (sellerstatus3) {
                    obj.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
                }
                else {
                    obj.pendingStatus3 = "-"
                }
                if (remarks && remarks.length && remarks.length > 0) {
                    obj.remarks = remarks && remarks.length > 0 && remarks
                }
                else if (remarks == "") {
                    obj.remarks = "-"
                }
                if (followup && followup.length && followup.length > 0) {
                    obj.Next_Contact_date = followup && followup.length > 0 && moment(followup).format('YYYY-MM-DD')
                }
                else if (followup == "") {
                    obj.Next_Contact_date = "-"
                }
                obj.sino = statusArray.length + 1
                obj.userName = Userlog.userName
                if (obj) {
                    statusArray.unshift(obj)
                }
            }
            else if (statusArray && statusArray.length == 0) {
                let obj = {}
                if (sellerstatus1) {
                    obj.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
                }
                if (sellerstatus2) {
                    obj.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
                }
                if (sellerstatus3) {
                    obj.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
                }
                else {
                    obj.pendingStatus3 = "-"
                }
                if (remarks && remarks.length && remarks.length > 0) {
                    obj.remarks = remarks && remarks.length > 0 && remarks
                }
                else if (remarks == "") {
                    obj.remarks = "-"
                }
                if (followup && followup.length && followup.length > 0) {
                    obj.Next_Contact_date = followup && followup.length > 0 && moment(followup).format('YYYY-MM-DD')
                }
                else if (followup == "") {
                    obj.Next_Contact_date = "-"
                }
                obj.sino = statusArray.length + 1
                obj.userName = Userlog.userName
                if (obj) {
                    statusArray.unshift(obj)
                }
            }
            else {
                statusArray = []
                let obj = {}
                if (sellerstatus1) {
                    obj.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
                }
                if (sellerstatus2) {
                    obj.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
                }
                if (sellerstatus3) {
                    obj.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
                }
                else {
                    obj.pendingStatus3 = "-"
                }
                if (remarks && remarks.length && remarks.length > 0) {
                    obj.remarks = remarks && remarks.length > 0 && remarks
                }
                else if (remarks == "") {
                    obj.remarks = "-"
                }
                if (followup && followup.length && followup.length > 0) {
                    obj.Next_Contact_date = followup && followup.length > 0 && moment(followup).format('YYYY-MM-DD')
                }
                else if (followup == "") {
                    obj.Next_Contact_date = "-"
                }
                obj.sino = statusArray.length + 1
                obj.userName = Userlog.userName
                if (obj) {
                    statusArray.unshift(obj)
                }
            }
            console.log(statusArray, "statusArray");
            let id = this.state.StatusEditData.id

            if (sellerstatus1) {
                groupArray.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
            }
            if (sellerstatus2) {
                groupArray.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
            }
            if (sellerstatus3) {
                groupArray.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
            }
            // let followJson = [];
            // followJson.push({
            //     date: new Date(),
            //     remarks: remarks && remarks.length > 0 && remarks,
            //     follow: followup && followup.length > 0 && followup
            // })
            groupArray.followup = JSON.stringify(statusArray)
            // console.log(followup, "followJson")
            groupArray.Next_Contact_date = followup
            console.log(groupArray, "groupArray", statusArray, "statusArray");
            this.setState({ statusbtnDisable: true })
            try {
                let result = await CmsContent.UpdateSaleEnquiryadd(
                    "tbl_dfcarz_enquiryform",
                    id,
                    groupArray
                );
                if (result) {
                    // console.log(result)
                }
                await this.setState({
                    statusbtnDisable: false,
                    answerJson: "",
                    json: "",
                    StatusupdatealertVisible: true,
                    Statusupdataetextalert: "Your Status have been Updated successfully",
                    Statusupdatecolor: "success"
                });
                window.scroll({ top: 0, left: 0, behavior: "smooth" });
                setTimeout(() => { this.setState({ StatusupdatealertVisible: false }); }, 3000);

            } catch (error) {
                console.log(error);
            }
        }
        else {
            const { status1, status2, status3, followup, statusArray } = this.state
            let groupArray = {}
            let Userlog = JSON.parse(localStorage.getItem("userlog"))
            if (statusArray && statusArray.length) {
                let obj = {}
                obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
                obj.status2 = status2 ? status2.label : null;
                obj.status3 = status3 ? status3.label : null;
                if (followup) {
                    obj.Next_Contact_date = followup && followup.length && moment(followup).format('DD-MM-YYYY')
                }
                else {
                    obj.Next_Contact_date = "-"
                }
                obj.sino = statusArray.length + 1
                obj.userName = Userlog.userName
                if (obj) {
                    statusArray.unshift(obj)
                }
            } else if (statusArray && statusArray.length == 0) {
                let obj = {}
                obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
                obj.status2 = status2 ? status2.label : null;
                obj.status3 = status3 ? status3.label : null;
                if (followup) {
                    obj.Next_Contact_date = followup && followup.length && moment(followup).format('DD-MM-YYYY')
                }
                else {
                    obj.Next_Contact_date = "-"
                }
                obj.sino = statusArray.length + 1
                obj.userName = Userlog.userName
                if (obj) {
                    statusArray.unshift(obj)
                }
            }
            else {
                statusArray = []
                let obj = {}
                obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
                obj.status2 = status2 ? status2.label : null;
                obj.status3 = status3 ? status3.label : null;
                if (followup) {
                    obj.Next_Contact_date = followup && followup.length && moment(followup).format('DD-MM-YYYY')
                }
                else {
                    obj.Next_Contact_date = "-"
                }
                obj.sino = 1
                obj.userName = Userlog.userName
                if (obj) {
                    statusArray.unshift(obj)
                }
            }

            console.log(statusArray, "statusArray else ");
            groupArray.statusArray = JSON.stringify(statusArray);
            groupArray.pendingStatus1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
            // groupArray.pendingStatus1 = status1 && status1.label;
            groupArray.pendingStatus2 = status2 ? status2.label : null;
            groupArray.pendingStatus3 = status3 ? status3.label : null;
            groupArray.Next_Contact_date = followup && followup.length && moment(followup).format('DD-MM-YYYY')
            console.log("groupArray", groupArray);
            let id = this.state.StatusEditData.id
            this.setState({ statusbtnDisable: true })
            try {
                const result = await cmsContent.UpdateEnquiryadd(
                    id,
                    groupArray
                );
                this.setState({
                    // data: newData,
                    statusbtnDisable: false,
                    StatusupdatealertVisible: true,
                    Statusupdataetextalert: "Your Status have been Updated successfully",
                    Statusupdatecolor: "success",
                    errorColourName: "",
                    status1: "",
                    status2: "",
                    status3: "",
                    modelPresent: false
                });
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
                setTimeout(() => this.setState({ StatusupdatealertVisible: false }), 3000);
            } catch (error) {
                console.log(error);
            }
        }
        this.mainFun()
    }

    handleChangeRemark = async (e, name) => {
        console.log(e.target.value, "e")
        await this.setState({ [name]: e.target.value })
    }

    render() {
        // console.log(moment(new Date()).format("DD-MM-YYYY"));
        let { loading, StatusEditData, EditData, statusArray,statusbtn } = this.state;
        console.log(statusArray, "statusArray render");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Enquiry Report</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {this.state.textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        {this.state.EditData != null && <div>
                                            <LoginModal
                                                title={EditData.name}
                                                id="fullview"

                                                extraStyle={{ width: '100%' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <div className="table-responsive">
                                                        <table class="table  table-striped">
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">NAME</th>
                                                                    <td>{EditData && EditData.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">MOBILE</th>
                                                                    <td>{EditData && EditData.mobile}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">MAKE</th>
                                                                    <td>{this.getValueFromArray(EditData && EditData.Make, this.state.MakeOptions)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">MODAL</th>
                                                                    <td>{this.getValueFromArray(EditData && EditData.Model, this.state.modelopt)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">COLOR</th>
                                                                    <td>{this.getValueFromArray(EditData && EditData.Variant, this.state.variantopt)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">OWNER</th>
                                                                    <td>{EditData && EditData.owner_type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">KMS</th>
                                                                    <td>{EditData && EditData.Km}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">EMAIL</th>
                                                                    <td>{EditData && EditData.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">REG NO</th>
                                                                    <td>{EditData && EditData.Reg_no}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">STATUS 1</th>
                                                                    <td>{EditData && EditData.pendingStatus1}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">STATUS 2</th>
                                                                    <td>{EditData && EditData.pendingStatus2}</td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                }
                                            /></div>
                                        }
                                        {this.state.StatusEditData != null && <div>
                                            <LoginModal
                                                title={StatusEditData.name}
                                                id="matchcarmodal"
                                                extraStyle={{ width: 'auto' }}
                                                reset={this.StatusReset}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <React.Fragment>
                                                        <main>
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                <h1> Edit Status </h1>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <Alert
                                                                                            className="badge-content"
                                                                                            color={this.state.Statusupdatecolor}
                                                                                            isOpen={this.state.StatusupdatealertVisible}
                                                                                            toggle={this.onDismiss}
                                                                                        >
                                                                                            {this.state.Statusupdataetextalert}
                                                                                        </Alert>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div>
                                                                                    {statusArray && statusArray.length ?
                                                                                        <div className="row " style={{ overflow: "auto" }}>
                                                                                            <table class="table table-bordered">
                                                                                                <thead style={{ background: '#151E80', color: 'white' }}>
                                                                                                    <tr>
                                                                                                        <th scope="col">SiNo</th>
                                                                                                        <th scope="col">Status1</th>
                                                                                                        <th scope="col">Status2</th>
                                                                                                        <th scope="col">Followup Date</th>
                                                                                                        <th scope="col">Remarks</th>
                                                                                                        <th scope="col">Update By</th>

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {statusArray && statusArray.map((jval, i) => {
                                                                                                        return (
                                                                                                            < tr >
                                                                                                                <th scope="row">{jval.sino}</th>
                                                                                                                <td>{jval.pendingStatus1}</td>
                                                                                                                <td>{jval.pendingStatus2}</td>
                                                                                                                <td>{jval.Next_Contact_date}</td>
                                                                                                                <td>{jval.remarks}</td>
                                                                                                                <td>{jval.userName}</td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div> : null}
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-2" style={{ left: "0%" }}></div>
                                                                                    <div className="col-sm-7" style={{ left: "0%" }}>
                                                                                        <div  >
                                                                                            &nbsp;
                                                                                            <h4>Status 1 : </h4>
                                                                                            &nbsp;
                                                                                            {this.status1()}
                                                                                            &nbsp;
                                                                                            <h4>Status 2 : </h4>

                                                                                            &nbsp;
                                                                                            {this.state.status2visible == true && this.status2()}
                                                                                            <br />
                                                                                            {this.state.remarkVisible == true ?
                                                                                                <>
                                                                                                    <label style={{ fontWeight: 500 }}> Remarks : </label>
                                                                                                    <br></br>
                                                                                                    <input
                                                                                                        type="textarea"
                                                                                                        placeholder="remarks"
                                                                                                        value={this.state.remarks}
                                                                                                        className="form-control"
                                                                                                        onChange={(e) => { this.handleChangeRemark(e, "remarks") }}
                                                                                                    />
                                                                                                    <br></br>
                                                                                                    <label style={{ fontWeight: 500 }}> Next-follow-up : </label>
                                                                                                    <br></br>
                                                                                                    <input
                                                                                                        type="date"
                                                                                                        placeholder="Follow-up date"
                                                                                                        min={moment().format('YYYY-MM-DD')}
                                                                                                        value={this.state.followup}
                                                                                                        className="form-control"
                                                                                                        onChange={(e) => { this.handleChangeRemark(e, "followup") }}
                                                                                                    />
                                                                                                    <br></br>
                                                                                                    <br></br>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            {/* {this.state.status3visible == true && <h4>Status 3 :  </h4> &nbsp; */}
                                                                                            {/* {this.state.status3visible == true && this.status3()} */}
                                                                                            <br />
                                                                                            {this.state.status2visible == true &&
                                                                                                <>
                                                                                                    <button
                                                                                                        className="btn btn-sm btn-block btn-warning"
                                                                                                        disabled={this.state.statusbtnDisable}
                                                                                                        onClick={() => { this.StatusUpdate() }}>
                                                                                                        {this.state.statusbtnDisable
                                                                                                            ?
                                                                                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                                                                                            :
                                                                                                            null}&nbsp;Update
                                                                                                    </button>
                                                                                                </>
                                                                                            }
                                                                                            &nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </React.Fragment>
                                                }
                                            /></div>
                                        }
                                        <div className="col-sm-4">
                                            <LoginModal
                                                buttonTitle="Add Enquiry"
                                                title="Update Status"
                                                id="followup"
                                                extraClass="btn btn-sm btn-primary"
                                                extraStyle={{ width: 'auto' }}
                                                onClick={this.reset}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <Updatefollowup
                                                        EditData={this.state.EditData}
                                                        these={this.changeModelstate}
                                                        isEdit={this.state.isEdit}
                                                        FullData={this.state.Data}
                                                        Index={this.state.Index}
                                                        IsClose={this.state.IsClose}
                                                        CustomerId={this.state.CustomerId}
                                                    />}
                                            />
                                        </div>


                                        {loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.usertypelog.orderBy == 8 ?
                                            this.state.EnquiryDatas && this.state.EnquiryDatas.length ?
                                                <Datatable
                                                    data={this.state.EnquiryDatas}
                                                    columnHeading={statusbtn==true? this.state.statuscolumn1 : this.state.column1}
                                                    // columnHeading={this.state.column1}
                                                    excelColumn={this.state.excelColumn1}
                                                /> : null
                                            : "No Data Found"}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment >
        );
    }
}
export default DfcarsSalesEnquiry;
