import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
//import UpdateStatus from "./UpdateStatus"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import { Icon } from 'react-icons-kit';
//import Icon from "react-icons-kit";
import { alert } from 'react-icons-kit/ionicons/alert'
import { clock } from 'react-icons-kit/iconic/clock'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { ACCESS_POINT } from "../../../../config/index";

import { confirmAlert } from "react-confirm-alert";
import { time } from "react-icons-kit/ikons/time";
import { calendar } from "react-icons-kit/ikons/calendar";
import { shuffle } from "react-icons-kit/ikons/shuffle";
import { shopping_cart_delete } from "react-icons-kit/ikons/shopping_cart_delete";
class TLDelivery extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Datas: [],
      MakeOptions: [],
      coloropt: [],
      alldelivery: [],

      variantopt: [],
      modelopt: [],
      deliveryFTD: [],
      deliveredMTD: [],
      loading: false,
      User: JSON.parse(localStorage.getItem("userlog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      //dashboard: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0 }],
      alertVisible: false,
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          Cell: d => this.Specification(d),
          className: "text-center"

        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        // {
        //   Header: "Contact",
        //   accessor: "mobile"
        // },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Sales Consultant",
          minWidth: 180,
          accessor: "Sales_employee_name"
        },
        {
          Header: "Print PDF",
          accessor: "pdf",
          Cell: d => this.viewpdf(d),
          className: "text-center"
        },
        {
          Header: "Status",
          accessor: "pdf",
          minWidth: 130,
          Cell: d => this.deliverystatus(d)
        },


      ],
      excelColumn: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "Mobile",
          key: "mobile",
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Color",
          key: "colourName",
        },
        {
          label: "Sales Consultant",
          key: "Sales_employee_name"
        }

      ],
      data_for_f: [{ "fill": 0, "view": 0 }],
      stage: "",
      CustomerId: "",
      LocationOptions: [],
      filteredLoc: "",
      filteredMake: "",
      FilterData: [],
    };
    this.vals()
  }
  async vals() {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      await this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt,
        LocationOptions: allOpt.locopt,
      })
    }
  }
  // deliverystatus

  deliverystatus = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<div>
        <button type="button" className="btn btn-sm btn-success" style={{ width: "130px" }}
        >Delivered</button>
      </div>)
    }
    else {
      return (
        <div> <span className="text-center btn btn-sm btn-danger" style={{ width: "130px" }}
        >Not Delivered</span></div>)
    }
  }

  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<div><button type="button" className="btn btn-sm btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button></div>)
    }
    else {
      return (
        <div> <span className="text-center">-</span></div>)
    }
  }
  generatepdf = async (d) => {
    const seller = await cmsContent.getFreedom("*", "tbl_Delivery_Note_Buyer_Reproof", `customerId=${d.original.id}`, 1, 1);
    console.log(seller, "sellerrrr")
    if (seller) {
      let sellpdf = await cmsContent.Deliverybuyer("tbl_Delivery_Note_Buyer_Reproof", seller.data[0].customerid);
      console.log(sellpdf)
      if (sellpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadbuyer/${seller.data[0].from_name}`, '_self')
      }
    }
  }

  F_proofForm = async (d) => {
    window.open(`/DFCars/AT_Delivery_Note_Buyer_Reproof/${d.original.id}`, '_self')
  }

  Specification = (d) => {

    return (<button type="button" className="btn btn-sm btn-primary"
      onClick={() => this.F_proofForm(d)}
    >View Form</button>);


  }

  MainFunction = async () => {
    try {
      this.setState({ loading: true })
      // let results = await cmsContent.BUYDFCARS(this.state.User.id, 'buyerreport')
      let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'delreport')
      console.log(results, "API");
      if (results) {
        let fulldata = []
        if (results.fulldata && results.fulldata.length > 0) {
          fulldata = results.fulldata
        }
        let logos = results;
        let Data = fulldata
        this.setState({ Data });

        await this.setState({
          alldelivery: fulldata,
          deliveryFTD: fulldata.length ? fulldata.filter(e => e.deliveryFTD == true) : [],
          deliveredMTD: fulldata.length ? fulldata.filter(e => e.deliveredMTD == true) : [],
          data2: fulldata.length ? fulldata.filter(e => e.filterdata2 == true) : [],
          data3: fulldata.length ? fulldata.filter(e => e.filterdata3 == true) : [],
          data_for_f: results.data_for_f,
          loading: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }


  async componentDidMount() {
    this.MainFunction()

  }
  edash = () => {
    return (<div className="">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                <h4 className="mt-2 black" >FTD Delivery</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].FTD ? this.state.data_for_f[0].FTD : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                <h4 className="mt-2 black" >MTD Delivered</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].MTD ? this.state.data_for_f[0].MTD : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                <h2 className="mt-2 black"> Delivered</h2>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].view ? this.state.data_for_f[0].view : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col " onClick={() => this.onClk(2)} >
              <div className="dashboardCard1">
                <Icon icon={shopping_cart_delete} size={35} style={{ color: 'red' }} />
                <h2 className="mt-2 black"> Delivery Pending</h2>
                <div>
                  <h1 className="bold mt-4 mb-2 black" ><b>{this.state.data_for_f[0].fill ? this.state.data_for_f[0].fill : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col" >
              <div className="dashboardCard1" onClick={() => this.onClk(9)}>
                <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                <h4 className="mt-2 black" >All Delivery</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.alldelivery ? this.state.alldelivery.length : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data2, FilterData: this.state.data2, filteredLoc: "", filteredMake: "" }) }
    if (val === 2) { this.setState({ Datas: this.state.data3, FilterData: this.state.data3, filteredLoc: "", filteredMake: "" }) }
    if (val === 3) { this.setState({ Datas: this.state.deliveryFTD, FilterData: this.state.deliveryFTD, filteredLoc: "", filteredMake: "" }) }
    if (val === 4) { this.setState({ Datas: this.state.deliveredMTD, FilterData: this.state.deliveredMTD, filteredLoc: "", filteredMake: "" }) }
    if (val === 9) { this.setState({ Datas: this.state.alldelivery, FilterData: this.state.alldelivery, filteredLoc: "", filteredMake: "" }) }

    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  makeFilter = async (d) => {
    let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

    //   console.log(d, "d")

    await this.setState({ filteredMake: d })

    if (d && d.length == 0 && filteredLoc.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredLoc.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
      var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
      var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
      var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }
  }
  locationFilter = async (d) => {
    let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
    await this.setState({ filteredLoc: d })

    if (d && d.length == 0 && filteredMake.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredMake.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length != 0) {
      var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
      var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
      var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }

  }
  clearFilter = async () => {
    await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
  }
  render() {
    let { LocationOptions, MakeOptions } = this.state
    console.log(this.state.Datas, "this.state.Datas")

    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          {this.edash()}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Delivery Report</h1>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="status"
                        extraStyle={{ width: 'auto' }}
                        extraClass="btn btn-sm btn-primary"
                        onClick={this.reset}
                        // extraStyle={{ width: '1000px' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                            stage={this.state.stage}
                            customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                  {
                    this.state.FilterData && this.state.FilterData.length ?
                      <>
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-6">
                              <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                            </div>
                            <div className="col-sm-6">
                              <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <MultiSelect
                                id="locationFilter"
                                name="location"
                                placeholder="Filter Location"
                                options={LocationOptions}
                                handleChange={d => this.locationFilter(d)}
                                selectedService={this.state.filteredLoc}
                              />
                            </div>
                            <div className="col-sm-6">
                              <MultiSelect
                                id="makefilter"
                                name="make"
                                placeholder="Filter Make"
                                options={MakeOptions}
                                handleChange={d => this.makeFilter(d)}
                                selectedService={this.state.filteredMake}
                              />
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <div className="col-sm-5" />
                            <div className="col-sm-2">
                              <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.clearFilter()
                              }}> Clear filter</button>
                            </div>
                            <div className="col-sm-5"></div>
                          </div>
                        </div>
                        <br></br>
                      </>
                      :
                      ""
                  }
                  {this.state.loading
                    ?
                    <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div>
                    :
                    this.state.Data && this.state.Data.length > 0
                      ?
                      (this.state.Datas &&
                        this.state.Datas.length > 0
                        ?
                        <Datatable
                          data={this.state.Datas}
                          columnHeading={this.state.column}
                          excelColumn={this.state.excelColumn}
                        />
                        :
                        <>
                          <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                          <br></br>
                        </>
                      )
                      :
                      (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
    )
  }
}
export default TLDelivery;