import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
// import DfCarsEnquiry from "..//DfCarsEnquiry";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { confirmAlert } from "react-confirm-alert";
import { x } from 'react-icons-kit/iconic/x'
import FormModal from "../../../../components/Modal/FormModal";
import { ACCESS_POINT } from "../../../../config";

class TLdocumentation extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      loading: false,
      User: JSON.parse(localStorage.getItem("userlog")),
      // userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      FilterData: [],
      alertVisible: false,
      Datas: [],
      Edit_Data: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      modelopt: [],
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          width: 150,
          Cell: d => this.Document(d)
        },
        {
          Header: "View",
          accessor: "approval",
          width: 130,
          Cell: d => this.view_Approval(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          width: 180,
          Cell: d => this.Approval(d),
          className: "text-center"
        },

        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "regno"
        },
        {
          Header: "Date",
          accessor: "created_At",
        },

        {
          Header: "Evaluator name ",
          accessor: "jobroleName",
          width: 150
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Print PDF",
          accessor: "pdf",
          minWidth: 140,
          Cell: d => this.viewpdf(d),
        },

        {
          Header: "Data Type",
          accessor: "Auction",
          minWidth: 140,
          Cell: (d) => this.Auction(d.original),
        },
      ],
      excelColumn: [

        {
          label: "Name",
          key: "name"
        },
        {
          label: "REG NO",
          key: "regno"
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Evaluator name ",
          key: "jobroleName"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Color",
          key: "colourName",
        },
        {
          label: "Data Type",
          key: "Auction_Status",
        },
      ],
      data_for_f: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0, "ftd": 0 }],
      stage: "",
      CustomerId: "",
      filteredLoc: "",
      filteredMake: "",
      LocationOptions: [],
      FilterData: [],

    };
    this.vals()
  }
  async vals() {
    let Downline_Details = await this.decryption('Downline_Details')
    console.log(Downline_Details, "Downline_Details");
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      await this.setState({
        MakeOptions: allOpt.makeopt, LocationOptions: allOpt.locopt, coloropt: allOpt.coloropt, variantopt: allOpt.variantopt, modelopt: allOpt.modelopt,
      })
    }
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<center><button type="button" className="btn btn-warning btn-sm text-center"
        style={{ minWidth: "100px" }}
        onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
    }
    else {
      return (
        <div className="text-center">-</div>)
    }
  }
  generatepdf = async (d) => {
    const document = await cmsContent.getFreedom("*", "tbl_AT_Booklet_Purchase_Contract_Proof", `customerId=${d.original.id}`, 1, 1);
    console.log(document.data)
    if (document) {
      let documentpdf = await cmsContent.document("tbl_AT_Booklet_Purchase_Contract_Proof", document.data[0].customerid);
      console.log(documentpdf)
      if (documentpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/documentpdf/${document.data[0].name}`, '_self')
      }
    }
  }
  Auction = (d) => {
    if (d && d.Auction_Status) {
      return (
        <div>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "130px" }}>
            {d.Auction_Status}
          </span>
        </div>
      )
    } else {
      return (
        <div>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "130px" }}>
            {d.Auction_Status}
          </span>
        </div>
      )
    }
  };
  view_Approval = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-primary"
        style={{ minWidth: "100px" }}
        onClick={() => this.opentable(d.original)}
      >
        View Details
      </button>
    )
  };
  opentable = async (d) => {
    if (d) {
      console.log(d, "d.original");
      await this.setState({ Edit_Data: d })
      window.$("#formmodel").modal("show")
    }
  }
  Approval = d => {
    console.log(d.original);
    let { usertypelog } = this.state;
    // let amount_price = JSON.stringify(500000)
    if (d.original.FormFilled === "no") {
      return (
        <button className="btn btn-sm btn-secondary" style={{ minWidth: "150px" }}>Form Not Filled</button>
      );
    }
    else if (d.original.FormFilled === "yes" && d.original.approval == 0 && usertypelog.orderBy != 3 && d.original.buying_price < 500000) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          style={{ minWidth: "150px" }}
          onClick={() => this.alert_validation(this.Approval_in_tl, d)}
        >Approve</button>
      );
    }
    else if (d.original.FormFilled === "yes" && d.original.approval == 0 && d.original.price_approval === 0 && usertypelog.orderBy != 3 && usertypelog.orderBy != 1 && d.original.buying_price >= 500000) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-info"
          style={{ minWidth: "150px" }}
          onClick={() => this.alert_validation(this.Approval_in_Manager_Verify, d)}
        >Verify</button>
      );
    }
    else if (d.original.FormFilled === "yes" && d.original.approval == 0 && d.original.price_approval === 1 && usertypelog.orderBy != 3 && usertypelog.orderBy != 1 && d.original.buying_price >= 500000) {

      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          style={{ minWidth: "150px" }}
          // onClick={() => this.alert_validation(this.Approval_in_tl, d)}
        >Verified</button>
      );
    }
    else if (d.original.FormFilled === "yes" && d.original.approval == 0 && d.original.price_approval === 0 && usertypelog.orderBy != 3 && usertypelog.orderBy != 2 && d.original.buying_price >= 500000) {

      return (
        <button
          type="button"
          className="btn btn-sm btn-info"
          style={{ minWidth: "150px" }}
          onClick={() => this.alert_validation(this.Approval_in_tl, d)}
        >Verify and Approve</button>
      );
    }
    else if (d.original.FormFilled === "yes" && d.original.approval == 0 && d.original.price_approval === 1 && usertypelog.orderBy != 3 && usertypelog.orderBy != 2 && d.original.buying_price >= 500000) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-info"
          style={{ minWidth: "150px" }}
          onClick={() => this.alert_validation(this.Approval_in_tl, d)}
        >Approve</button>
      );
    }
    else if (d.original.approval == 1 && d.original.accounts_approval == 0 && usertypelog.orderBy != 3) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-warning"
          style={{ minWidth: "150px" }}
        >Waiting for Accounts</button>
      )
    }
    else if (d.original.approval == 1 && d.original.accounts_approval == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          style={{ minWidth: "150px" }}
        >Approved</button>
      )
    }
    else if ((d.original.approval == 1 || d.original.price_approval == 1) && d.original.accounts_approval == 2) {
      return (
        <button
          type="button"
          style={{ minWidth: "150px" }}
          className="btn btn-sm btn-danger"

        >Accounts Rejected</button>
      )
    }
    else if (d.original.approval == 2) {
      return (<button
        type="button"
        className="btn btn-sm btn-danger"
        style={{ minWidth: "150px" }}
        onClick={() => this.alert_validation(this.Approval_in_tl, d)}
      >Rejected</button>)
    }
    else {
      if (usertypelog.orderBy != 3) {

        return (
          <button
            type="button"
            className="btn btn-sm btn-warning"
            style={{ minWidth: "150px" }}
            onClick={() => this.alert_validation(this.Approval_in_tl, d)}
          >Approve</button>
        );
      }
      else {
        return (
          <>
            -
          </>
        )
      }
    };
  }
  Document = d => {
    if (d.original.FormFilled === "no") {
      return (<center><button type="button" className="btn btn-warning btn-sm text-center"
        onClick={() => this.F_proofForm1(d)}
        style={{ minWidth: "100px" }}
      >Form</button></center>)

    } else {
      return (
        <center>
          <button
            type="button"
            className="btn btn-sm btn-warning"
            style={{ minWidth: "100px" }}
            onClick={() => this.F_proofForm1(d)}
          >
            View Form
          </button>
        </center >
      );
    }
  };

  F_proofForm1 = async d1 => {
    window.open(
      `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d1.original.id}`,
      "_self"
    );
  };

  Approval_in_tl = async (d, text) => {
    let data = d.original;
    let body = {};
    body.customerid = data.id;
    if (text == "yes") {
      body.approval = 1;
    } else {
      body.approval = 2;
    }
    body.Approved_date = moment().format("YYYY-MM-DDThh:mm")
    body.Approved_by = this.state.User.id
    try {
      let results = await cmsContent.updateMaster(
        'tbl_AT_Booklet_Purchase_Contract_Proof',
        data.formid,
        body)
      if (results) {
        let sub = {}
        sub.enquiryid = data.enquiry_id
        sub.prosid = data.id
        sub.from = "documentation"
        sub.userid = data.userid
        sub.process = "1"
        sub.documentationid = data.formid
        sub.approved_user_id = this.state.User.id
        if (data.approval == 2) {
          results = await cmsContent.updateMaster('tbl_dfcars_RF', data.formid, sub, "documentationid")
        }
        if (data.approval == 1) {
          results = true
        }
        if (data.approval == 0) {
          results = await cmsContent.addMaster('tbl_dfcars_RF', sub)
        }
        if (results) {
          await this.MainFunction()
        }
      }
    }
    catch (error) {
      console.log(error);
    }

  };
  Approval_in_Manager_Verify = async (d, text) => {
    let data = d.original;
    let body = {};
    body.customerid = data.id;
    if (text == "yes") {
      body.price_approval = 1;
    } else {
      body.price_approval = 2;
    }
    try {
      let results = await cmsContent.updateMaster(
        'tbl_AT_Booklet_Purchase_Contract_Proof',
        data.formid,
        body)
       
        if (results) {
          await this.MainFunction()
        }
    }
    catch (error) {
      console.log(error);
    }

  };
 
  MainFunction = async () => {
    try {
      await this.setState({ loading: true, data2: [], data3: [], data4: [], data5: [], Datas: [] })
      console.log("Api");
      let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'Documents')
      console.log(results, "Api");
      if (results.Documentation_Prospective) {
        let fulldata = []
        if (results && results.Documentation_Prospective) {
          fulldata = results.Documentation_Prospective;
        }

        let logos = results;
        let Data = logos.Documentation_Prospective;
        await this.setState({
          Data,
          data2: fulldata.length ? fulldata.filter(e => e.filter_approved == true) : [],
          data3: fulldata.length ? fulldata.filter(e => e.filter_filled == true) : [],
          data4: fulldata.length ? fulldata.filter(e => e.filternon_filled == true) : [],
          data5: fulldata.length ? fulldata.filter(e => e.filter_reject == true) : [],
          userId: results.userid,
          data_for_f: results.data_for_f,
          loading: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    await this.MainFunction()

  }
  edash = () => {

    return (<div className="">
      <div className="card">

        <div className="card-label">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Approved</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].approved ? this.state.data_for_f[0].approved : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                <Icon icon={x} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Rejected</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].reject ? this.state.data_for_f[0].reject : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Waiting For Approval</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].approval ? this.state.data_for_f[0].approval : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Non-Filled</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].nonfilled ? this.state.data_for_f[0].nonfilled : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data2, FilterData: this.state.data2, filteredLoc: "", filteredMake: "" }) }
    if (val === 2) { this.setState({ Datas: this.state.data3, FilterData: this.state.data3, filteredLoc: "", filteredMake: "" }) }
    if (val === 3) { this.setState({ Datas: this.state.data4, FilterData: this.state.data4, filteredLoc: "", filteredMake: "" }) }
    if (val === 4) { this.setState({ Datas: this.state.data5, FilterData: this.state.data5, filteredLoc: "", filteredMake: "" }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  makeFilter = async (d) => {
    let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

    //   console.log(d, "d")

    await this.setState({ filteredMake: d })

    if (d && d.length == 0 && filteredLoc.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredLoc.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
      var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
      var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
      var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }
  }
  locationFilter = async (d) => {
    let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
    await this.setState({ filteredLoc: d })

    if (d && d.length == 0 && filteredMake.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredMake.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length != 0) {
      var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
      var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
      var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }

  }
  clearFilter = async () => {
    await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
  }
  render() {
    let { Edit_Data, Datas, LocationOptions, MakeOptions } = this.state
    return (
      <React.Fragment>
        <FormModal
          extraStyle={{ width: '100%' }}
          id={"formmodel"}
          bodyText={
            <div>
              {this.state.Edit_Data &&
                <table className="LeadTable">
                  <thead>
                    <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                      <th class="column1">Type</th>
                      <th class="column2">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Edit_Data.name && <tr>
                      <td class="column1">Name </td>
                      <td class="column2"> {Edit_Data.name} </td>
                    </tr>}
                    {Edit_Data.mobile && <tr>
                      <td class="column1">Mobile Number </td>
                      <td class="column2"> {this.Call(Edit_Data.mobile)} </td>
                    </tr>}
                    {Edit_Data.make && <tr>
                      <td class="column1">Make</td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.make, this.state.MakeOptions)} </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Model </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.model, this.state.modelopt)} </td>
                    </tr>}
                    {Edit_Data.variant && <tr>
                      <td class="column1">Variant </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.variant, this.state.variantopt)} </td>
                    </tr>}
                    {Edit_Data.color && <tr>
                      <td class="column1">Color </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.color, this.state.coloropt)} </td>
                    </tr>}
                    {Edit_Data.jobroleName && <tr>
                      <td class="column1">Evaluator </td>
                      <td class="column2"> {Edit_Data.jobroleName} </td>
                    </tr>}
                    {Edit_Data.Auction_Status && <tr>
                      <td class="column1">Form Status</td>
                      <td class="column2">
                        <h4 className="" style={{ color: Edit_Data.Auction == 0 ? "#07278f" : Edit_Data.Auction == 1 ? "orange" : Edit_Data.Auction == 2 ? "green" : "red" }}>{Edit_Data.Auction_Status}</h4>
                      </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Approval </td>
                      <td class="column2"> {Edit_Data.approval == 1 ? "Approved" : Edit_Data.approval == 2 ? "Rejected" : Edit_Data.approval == 0 ? "In Progress" : ""} </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Accounts Approved  </td>
                      <td class="column2"> {Edit_Data.accounts_approval == 1 ? "Approved" : Edit_Data.accounts_approval == 2 ? "Rejected" : Edit_Data.accounts_approval == 0 ? "In Progress" : ""} </td>
                    </tr>}
                  </tbody>
                </table>
              }
            </div>
          }
        />
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Documentation</h1>
                  </div>

                  <div className="card-body">
                    {
                      this.state.FilterData && this.state.FilterData.length ?
                        <>
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-6">
                                <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <MultiSelect
                                  id="locationFilter"
                                  name="location"
                                  placeholder="Filter Location"
                                  options={LocationOptions}
                                  handleChange={d => this.locationFilter(d)}
                                  selectedService={this.state.filteredLoc}
                                />
                              </div>
                              <div className="col-sm-6">
                                <MultiSelect
                                  id="makefilter"
                                  name="make"
                                  placeholder="Filter Make"
                                  options={MakeOptions}
                                  handleChange={d => this.makeFilter(d)}
                                  selectedService={this.state.filteredMake}
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              <div className="col-sm-5" />
                              <div className="col-sm-2">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                  this.clearFilter()
                                }}> Clear filter</button>
                              </div>
                              <div className="col-sm-5"></div>
                            </div>
                          </div>
                          <br></br>
                        </>
                        :
                        ""

                    }

                    {this.state.loading
                      ?
                      <div className="text-center">
                        <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}></i>
                      </div>
                      :
                      this.state.Data && this.state.Data.length > 0
                        ?
                        (this.state.Datas &&
                          this.state.Datas.length > 0
                          ?
                          <Datatable
                            data={this.state.Datas}
                            columnHeading={this.state.column}
                            excelColumn={this.state.excelColumn}
                          />
                          :
                          <>
                            <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                            <br></br>
                          </>
                        )
                        :
                        (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default TLdocumentation;
