import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index"
import Icon from "react-icons-kit";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import { notepad_ok } from 'react-icons-kit/ikons/notepad_ok'
import { notepad_remove } from 'react-icons-kit/ikons/notepad_remove'
import CmsContent from "../../../../MiddleWare/CmsContent";
class pendingtask extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            dashboard: [],
            Data: [],
            Datas: [],
            userid: JSON.parse(localStorage.getItem("userlog")),
            loading: true,
            userList: ["enquiry", "eval", "doc", "phy", "lead", "contract", "delivery"]
        };

    }
    async componentDidMount() {
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt
            })
        }
        await this.mainfun()

    }
    async mainfun(type = 'all') {
        try {
            this.setState({ loading: true })
            let { userList } = this.state
            let CategoryArray = {}
            CategoryArray.id = this.state.userid.id
            let result = await CmsContent.BUYDFCARZ(type, 'TodayTask', CategoryArray)
            console.log(result);
            if (result && type == 'all') {
                this.setState({ Data: result })
                userList.map(ival => { this.setState({ [ival]: result[ival] ? result[ival] : [] }) })
            }
            else {
                this.setState({ [type]: result[type], Data: result })
            }
            await this.setState({ loading: false })
        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Reg no",
            accessor: "Reg_no"
        },
        {
            Header: "Mobile",
            accessor: "mobile"
        },
        {
            Header: "Enquriry date",
            accessor: "created_At"
        },
        {
            Header: "Make",
            accessor: "make",
            Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        },
        {
            Header: "Model",
            accessor: "model",
            Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
            Header: "Variant",
            accessor: "variant",
            Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        },
        {
            Header: "Color",
            accessor: "color",
            Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        // {
        //     Header: "View status",
        //     // accessor: "edit",
        //     width: 150,
        //     // Cell: d => this.StatusView(d)
        // },
        {
            Header: "Status",
            accessor: "id",
            width: 220,
            Cell: d => this.status_Approval(d)
        },

    ]

    status_Approval = d => {
        if (d.original.docs_status && d.original.docs_status == "Pending" && d.original.FormFilled == "yes" || d.original.approval == 0 && d.original.accounts_approval == 0) {
            return (
                <>
                    <button className="btn btn-sm btn-primary"> Waiting for Poc Approval</button>
                </>
            )
        }
        else if (d.original.docs_status && d.original.docs_status == "In Progress" && d.original.FormFilled == "yes") {
            return (
                <>
                    <button type="button" className="btn btn-sm btn-warning"> Waiting for Accounts Approval</button>
                </>
            )
        } else if (d.original.approval == 1 && d.original.accounts_approval == 1) {
            return (<>
                <div>Approved</div>
            </>)
        }
        else if (d.original.FormFilled == "no") {
            return (
                <>
                    <button type="button"
                        className="btn btn-sm btn-secondary"
                    >Form Not Filled</button>
                </>
            )
        }
        else if (d.original.pendingStatus1 && d.original.pendingStatus1 == "Schedule walkin") {

            return (
                <>
                    <button type="button"
                        className="btn btn-sm btn-secondary"
                    >Form Not Filled</button>
                </>
            )
        }
        else if (d.original.pendingStatus1 && d.original.pendingStatus1 == "Scheduled Follow up") {
            return (
                <>
                    <button type="button"
                        className="btn btn-sm btn-success"
                    >Form  Filled</button>
                </>
            )
        }
        else {
            return (
                <>
                    <div>-</div>
                </>
            )
        }
    }
    onClk = (val) => {
        const { enquiry, phy, doc } = this.state
        const evaluation = this.state.eval
        if (val === 1) { this.setState({ Datas: enquiry && enquiry.flldata && enquiry.flldata.pending ? enquiry.flldata.pending : [] }) }
        if (val === 2) { this.setState({ Datas: evaluation && evaluation.flldata && evaluation.flldata.pending ? evaluation.flldata.pending : [] }) }
        if (val === 3) { this.setState({ Datas: doc && doc.flldata && doc.flldata.pending ? doc.flldata.pending : [] }) }
        if (val === 4) { this.setState({ Datas: phy && phy.flldata && phy.flldata.pending ? phy.flldata.pending : [] }) }


        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    edash = () => {
        const { enquiry, phy, doc } = this.state
        const evaluation = this.state.eval

        return (<div className="container-fluid">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Pending Task</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className='container-fluid'>
                        <div className="row mt-4 text-center">
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                    <Icon icon={notepad_ok} style={{ color: 'green' }} size={35} />
                                    <h2 className="mt-2 black" >Enquiry</h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{enquiry && enquiry.flldata && enquiry.flldata.pending ? enquiry?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                                    <Icon icon={notepad_remove} size={35} style={{ color: 'red' }} />
                                    <h2 className="mt-2 black" >Evalution</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black" ><b>{evaluation && evaluation.flldata && evaluation.flldata.pending ? evaluation?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                                    <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                                    <h2 className="mt-2 black" >Documentation</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{doc && doc.flldata && doc.flldata.pending ? doc?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                                    <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                                    <h2 className="mt-2 black" >physical Process</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{phy && phy.flldata && phy.flldata.pending ? phy?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    render() {
        let { loading } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Pending Task</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-4">

                                            </div>
                                        </div>
                                        {loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.Data ? (
                                            this.state.Datas.length ?
                                                <Datatable
                                                    data={this.state.Datas}
                                                    columnHeading={this.column}
                                                />
                                                :
                                                null
                                        ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default pendingtask;
