import React, { Component } from "react";
import { ACCESS_POINT } from "../../../../config";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";

import Icon from "react-icons-kit";
import { alert } from 'react-icons-kit/ionicons/alert'
import { clock } from 'react-icons-kit/iconic/clock'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
class RcTransfer extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            customerId: localStorage.getItem("userId"),
            LocationID: localStorage.getItem(`LocationID`),
            User: JSON.parse(localStorage.getItem("userlog")),
            Data: [],
            Datas: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            modelopt: [],
            rcdata1: [],
            rcdata2: [],
            loading: false,
            rcdata3: [],
            data_for_f: [{ "fill": 0, "view": 0, "view1": 0 }],
            filteredLoc: "",
            filteredMake: "",
            LocationOptions: [],
            FilterData: [],

        }
        console.log(this.state.customerId)
        this.vals()
    }
    column = [
        {
            Header: "Form",
            accessor: "FormFilled",
            width: 120,
            Cell: (d) => this.formfill(d.original.FormFilled, d.original.id),
            className: "text-center"
        },
        {
            Header: "Name",
            accessor: "name",
            width: 150
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            Cell: d => this.Call(d.original.mobile)
        },
        // {
        //     Header: "Contact",
        //     accessor: "mobile"
        //   },
        {
            Header: "Make",
            accessor: "makeName",
            width: 110,
            // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
            Header: "Model",
            accessor: "modelName",
            width: 100,
            // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
            Header: "Variant",
            accessor: "variantName",
            width: 100,
            // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
            Header: "Color",
            accessor: "colourName",
            width: 100,
            // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
            Header: "Year",
            accessor: "year",
            width: 100
        },
        {
            Header: "Delivery Date",
            accessor: "deliveryDate",
            width: 160
            // Cell: d => this.delivery(d.original.deliveryDate)
        },
        {
            Header: "RC File",
            accessor: "RCFile",
            minWidth: 100,
            Cell: d => this.deliverystatus(d),
            className: "text-center"

        },

    ]
    excelColumn = [
        {
            label: "Name",
            key: "name",
        },
        {
            label: "Mobile",
            key: "mobile",
        },
        {
            label: "Make",
            key: "makeName",
        },
        {
            label: "Model",
            key: "modelName",
        },
        {
            label: "Variant",
            key: "variantName",
            
        },
        {
            label: "Color",
            key: "colourName",
        },
        {
            label: "Year",
            key: "year",
        },
        {
            label: "Delivery Date",
            key: "deliveryDate",
            
        },
        

    ]

    deliverystatus = d => {
        if (d.original.RCFile && d.original.FormFilled == "yes") {
            return (<div>
                <button type="button" className="btn btn-sm btn-success" style={{ width: "130px" }}
                >Uploaded</button>
            </div>)
        }
        else if (!d.original.RCFile && d.original.FormFilled == "yes") {
            return (<div>
                <button type="button" className="btn btn-sm btn-warning" style={{ width: "130px" }}
                >Not Uploaded</button>
            </div>)
        }
        else {
            return (
                <div className="text-center"> Not Filled</div>)
        }
    }
    formfill = (val, id) => {
        if (val === "yes") {
            return (<button className="btn btn-sm btn-primary" onClick={() => { window.location.href = "/DFCars/Rcform/" + id }} >View Form</button>)
        } else if (val === "no") {
            return (<button className="btn btn-sm btn-warning" onClick={() => { window.location.href = "/DFCars/Rcform/" + id }}>Fill from</button>)
        }
    }
    async vals() {
        let { data: dataval1 } = await CmsContent.getFreedom(
            "*",
            `tbl_dfcarz_enquiryform`,
            `enquiry_status='active' and location =${this.state.LocationID} AND (pendingStatus1='Closed' OR pendingStatus1='Yes, but not interested, Mark closed' OR pendingStatus1='Couldn’t speak to customer')`,
            1,
            `id DESC`
        );
        if (dataval1) {
            this.setState({ dataval: dataval1 })
        }
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt,
                LocationOptions: allOpt.locopt,
            })
        }

    }
    MainFunction = async () => {
        try {
            this.setState({ loading: true })
            let results1 = await CmsContent.BUYDFCARZ(this.state.User.id, 'RCdel')
            if (results1) {
                let fulldata = []
                if (results1 && results1.fulldata) {
                    fulldata = results1.fulldata;
                }
                await this.setState({
                    rcdata2: fulldata.length ? fulldata.filter(e => e.filterdata3 == true) : [],
                    rcdata3: fulldata.length ? fulldata.filter(e => e.filterdata4 == true) : [],
                    rcdata1: fulldata.length ? fulldata.filter(e => e.filterdata2 == true) : [],
                    data_for_f: results1.data_for_f, loading: false
                })
            }
            console.log(results1, "apiiiii");

        }
        catch (error) {
            this.setState({ loading: false })
            console.log(error);
        }
    }
    async componentDidMount() {
        this.MainFunction()

    }
    edash = () => {
        return (<div className="">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="row mt-4 text-center">
                            <div className="col" >
                                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                    <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                                    <h2 className="mt-2 black" > Completed</h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].fill}</b></h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col" onClick={() => this.onClk(3)} >
                                <div className="dashboardCard1">
                                    <Icon icon={clock} size={35} style={{ color: 'rgb(203 44 146)' }} />
                                    <h2 className="mt-2 black" > Awaiting Docs </h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].view1}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col" onClick={() => this.onClk(2)} >
                                <div className="dashboardCard1">
                                    <Icon icon={alert} size={35} style={{ color: 'red' }} />
                                    <h2 className="mt-2 black" > Pending 21 Days</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black" ><b>{this.state.data_for_f[0].view}</b></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    onClk = (val) => {
        if (val === 1) {
            this.setState({ Datas: this.state.rcdata1, FilterData: this.state.rcdata1, filteredLoc: "", filteredMake: "" })
        }
        if (val === 2) {
            this.setState({ Datas: this.state.rcdata2, FilterData: this.state.rcdata2, filteredLoc: "", filteredMake: "" })
        }
        if (val === 3) {
            this.setState({ Datas: this.state.rcdata3, FilterData: this.state.rcdata3, filteredLoc: "", filteredMake: "" })
        }
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    makeFilter = async (d) => {
        let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

        //   console.log(d, "d")

        await this.setState({ filteredMake: d })

        if (d && d.length == 0 && filteredLoc.length == 0) {
            await this.setState({ Datas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredLoc.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
            var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
            var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
            var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else {
            //nothing
        }
    }
    locationFilter = async (d) => {
        let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
        await this.setState({ filteredLoc: d })

        if (d && d.length == 0 && filteredMake.length == 0) {
            await this.setState({ Datas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredMake.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length != 0) {
            var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
            var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
            var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else {
            //nothing
        }

    }
    clearFilter = async () => {
        await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
    }

    render() {
        let { loading, LocationOptions, MakeOptions } = this.state;
        console.log(this.state.Datas, "this.state.Datas");
        return (<React.Fragment>
            <main className="main my-4">
                <div className="container-fluid">
                    {this.edash()}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Rc Transfer</h1>
                                </div>
                                <div className="card-body">
                                    {
                                        this.state.FilterData && this.state.FilterData.length ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="locationFilter"
                                                                name="location"
                                                                placeholder="Filter Location"
                                                                options={LocationOptions}
                                                                handleChange={d => this.locationFilter(d)}
                                                                selectedService={this.state.filteredLoc}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="makefilter"
                                                                name="make"
                                                                placeholder="Filter Make"
                                                                options={MakeOptions}
                                                                handleChange={d => this.makeFilter(d)}
                                                                selectedService={this.state.filteredMake}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="col-sm-5" />
                                                        <div className="col-sm-2">
                                                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                this.clearFilter()
                                                            }}> Clear filter</button>
                                                        </div>
                                                        <div className="col-sm-5"></div>
                                                    </div>
                                                </div>
                                                <br></br>
                                            </>
                                            :
                                            ""

                                    }
                                    {loading ?
                                        <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div>
                                        :
                                        this.state.Datas && this.state.Datas.length
                                            ?
                                            <Datatable
                                                data={this.state.Datas}
                                                columnHeading={this.column}
                                                excelColumn={this.excelColumn}
                                            />
                                            :
                                            <>
                                                <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                                                <br></br>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>)
    }
}
export default RcTransfer;