import React from 'react'
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { alert } from 'react-icons-kit/ionicons/alert'
import { clock } from 'react-icons-kit/iconic/clock'
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import LoginModal from '../../../../components/Modal/Modal';
import SingleSelect from '../../../../components/Form/SingleSelect';
import { FilePond, registerPlugin } from "react-filepond";
import { ACCESS_POINT } from '../../../../config';
import { wrench } from 'react-icons-kit/iconic/wrench'
import { warning } from 'react-icons-kit/iconic/warning'
import { task } from 'react-icons-kit/iconic/task'
import FormModal from '../../../../components/Modal/FormModal';
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { MDBBtn, MDBIcon } from "mdbreact";
import Camera from '../../../../MiddleWare/camera';
import { remove } from 'lodash';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class RFinchargelist extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            // imagealeart1: false,
            Data: [],
            Datas: [],
            data1: [], data2: [], data3: [], data4: [], data5: [], data6: [], data7: [],
            MakeOptions: [],
            modelopt: [],
            yearopt: [],
            coloropt: [],
            SelectCurrentStatus_filtered: [],
            images: [],
            Edit_Data: [],
            rfprice: "",
            work_checklist: false,
            data8: [],
            pricealert: "",
            filesArray: [],
            workval: null,
            variantopt: [],
            RFT: [],
            AllRFTopt: [],
            SelectCurrentStatus: [{ label: "Work yet start", value: 0 }, { label: "Work in progress", value: 1 }, { label: "Waiting for parts", value: 2 }, { label: "Completed", value: 3 }],
            RFTopt: [],
            editid: "",
            alertVisible: false,
            alertVisible1: false,
            User: JSON.parse(localStorage.getItem("userlog")),
            loading: false,
            data_for_f: [{ "fill": 0, "view": 0, "assign": 0, "approve": 0, "comp": 0, "pend": 0 }],
            column: [
                // {
                //     Header: "Name",
                //     accessor: "name"
                // },

                {
                    Header: "REG NO",
                    accessor: "reg_no",
                    Cell: (d) => this.regno(d.original)
                },
                // {
                //     Header: "Mobile",
                //     accessor: "mobile"
                // },
                {
                    Header: "MAKE",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
                },
                {
                    Header: "MODEL",
                    accessor: "model",
                    minWidth: 150,
                    Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
                },
                // {
                //     Header: "Color",
                //     accessor: "color",
                //     Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
                // },
                // {
                //     Header: "Variant",
                //     accessor: "variant",
                //     minWidth: 100,
                //     Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
                // },
                {
                    Header: "MAKE YEAR",
                    accessor: "year"
                    // Cell: (d) => this.getValueFromArray(d.original.year, this.state.yearopt)
                },
                {
                    Header: "Inspection type",
                    accessor: "Data_Inspection_type",
                    Cell: d => this.data_type(d),
                    minWidth: 150
                },
                {
                    Header: "Enquiry Date",
                    minWidth: 150,
                    accessor: "created_At"
                },
                {
                    Header: "Technician Name",
                    minWidth: 150,
                    accessor: "RFTechnicianID",
                    Cell: (d) => this.getValueFromArray(d.original.RFTechnicianID, this.state.RFTopt)
                },
                {
                    Header: "Bad Summary",
                    accessor: "name",
                    minWidth: 150,
                    Cell: d => this.FormFill1(d.original)
                },
                {
                    Header: "Assign",
                    minWidth: 180,
                    accessor: "Rfassign",
                    Cell: d => this.assignAuction(d)
                },
                {
                    Header: "View",
                    accessor: "approval",
                    width: 180,
                    Cell: d => this.view_Approval(d)
                },
            ],
            excelColumn: [

                {
                    label: "REG NO",
                    key: "REGNO",
                },
                {
                    label: "MAKE",
                    key: "makeName",
                },
                {
                    label: "MODEL",
                    key: "modelName",
                },
                // {
                //     label: "Color",
                //     key: "colourName",
                // },
                // {
                //     label: "Variant",
                //     key: "variantName",
                // },
                {
                    label: "MAKE YEAR",
                    key: "year"
                },
                {
                    label: "Inspection type",
                    key: "Data_Inspection_type",
                },
                {
                    label: "Enquiry Date",
                    key: "created_At"
                },
            ],
            column2: [
                // {
                //     Header: "Name",
                //     accessor: "name"
                // },
                {
                    Header: "REG NO",
                    accessor: "REGNO",
                },
                // {
                //     Header: "Mobile",
                //     accessor: "mobile"
                // },
                {
                    Header: "MAKE",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
                },
                {
                    Header: "MODEL",
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
                },
                {
                    Header: "Variant",
                    accessor: "variant",
                    Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
                },
                {
                    Header: "MAKE YEAR",
                    accessor: "year"
                    // Cell: (d) => this.getValueFromArray(d.original.year, this.state.yearopt)
                },
                {
                    Header: "Inspection type",
                    accessor: "Data_Inspection_type",
                    Cell: d => this.data_type(d),
                    minWidth: 130
                },
                {
                    Header: "Enquiry Date",
                    accessor: "created_At"
                },
                {
                    Header: "Technician Name",
                    accessor: "RFTechnicianName",
                    minWidth: 100,
                    // Cell: (d) => this.getValueFromArray(d.original.RFTechnicianID, this.state.RFTopt)
                },
                {
                    Header: "Bad Summary",
                    accessor: "name",
                    minWidth: 130,
                    Cell: d => this.FormFill1(d.original)
                },
                {
                    Header: "Status",
                    minWidth: 180,
                    accessor: "Rfassign",
                    Cell: d => this.AssignWork(d.original)
                },
                {
                    Header: "View",
                    accessor: "approval",
                    width: 180,
                    Cell: d => this.view_Approval(d)
                },
            ],
            excelColumn2: [

                {
                    label: "REG NO",
                    key: "REGNO",
                },

                {
                    label: "MAKE",
                    key: "makeName",
                },
                {
                    label: "MODEL",
                    key: "modelName",
                },
                {
                    label: "Color",
                    key: "colourName",
                },
                {
                    label: "Variant",
                    key: "variantName",
                },
                {
                    label: "MAKE YEAR",
                    key: "year"
                },
                {
                    label: "Inspection type",
                    key: "Data_Inspection_type",

                },
                {
                    label: "Enquiry Date",
                    key: "created_At"
                },
                {
                    label: "Technician Name",
                    key: "RFTechnicianName",

                }
            ]
        }

    }
    Data_Inspection_type = (d) => {
        if (d.Data_Inspection_type) {
            return d.Data_Inspection_type
        } else {
            return "Normal"
        }
    }
    data_type = d => {
        if (d.original.Data_Inspection_type == "Commit Work") {
            return (
                <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    style={{ minWidth: "100px" }}
                >{d.original.Data_Inspection_type}</button>

            );
        }
        else {
            return (
                <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    style={{ minWidth: "100px" }}

                >{d.original.Data_Inspection_type}</button>
            );
        }

    };
    view_Approval = d => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-warning"
                style={{ minWidth: "100px" }}
                onClick={() => this.opentable(d.original)}
            >
                View Details
            </button>
        )
    };
    opentable = async (d) => {
        if (d) {
            let Edit_Data = d
            console.log(Edit_Data, "Djdgjdgdjgddd");
            await this.setState({ Edit_Data })
            window.$("#formmodel").modal("show")
        }
    }
    regno = (d) => {
        if (d.reg_no) {
            return d.reg_no
        } else if (d.REGNO) {
            return d.REGNO
        } else {
            return "-"
        }
    }
    async vals() {
        let ALL_User_Details = await this.decryption('Downline_Details')
        if (ALL_User_Details) {
            console.log(ALL_User_Details.Current_User[0], 'ALL_User_Details.TECHNICIANOPT');
            await this.setState({
                Current_User: ALL_User_Details.Current_User[0],
                RFTopt: ALL_User_Details && ALL_User_Details.TECHNICIANOPT ? ALL_User_Details.TECHNICIANOPT : [],
                AllRFTopt: ALL_User_Details && ALL_User_Details.ALLTECHNICIANOPT ? ALL_User_Details.ALLTECHNICIANOPT : []
            })
        }
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt
            })
        }
    }
    handlemake = async (e) => {
        // console.log(e);
        this.setState({ selectedmake: e });
    };
    submitauction = async (d) => {
        let { selectedmake, editid, auctionData } = this.state;
        console.log(auctionData, "auctionData");
        let categoryArray = {};

        if (this.state.User.id) {
            categoryArray.RFInchargeID = this.state.User.id
        }
        categoryArray.RFTechnicianID = selectedmake.value
        categoryArray.Rfassign = 1;
        categoryArray.Rfstatus = auctionData.Data_Inspection_type;
        this.setState({ assignbtnDisable: true })

        try {
            const result = await CmsContent.updateMaster(
                'tbl_dfcarz_prosperities',
                auctionData.id,
                categoryArray
            );
            if (result) {
                let sub = {}
                sub.RFTechnicianID = selectedmake.value
                sub.RFInchargeID = this.state.User.id
                let results = null
                results = await CmsContent.updateMaster('tbl_dfcars_RF', auctionData.RFtable_id, sub)
                if (results) {
                    this.setState({ textalert: "Assigned Sucessfully", alertVisible: true, assignbtnDisable: false })
                    this.MainFunction()
                    setTimeout(() => { this.setState({ alertVisible: false }); }, 3000);
                    // window.$('#auction').modal('hide')
                    const elements = document.getElementsByClassName('modal-backdrop');
                    elements[0].parentNode.removeChild(elements[0]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    asignautopopulate = async (d) => {
        await this.setState({ auctionData: d.original })
    }
    asignautopopulatereset = async (d) => {
        await this.setState({ auctionData: [] })
    }

    assignAuction = (d) => {
        let { RFTopt, editid, auctionData } = this.state;
        this.state.selectbox = RFTopt
        if (d.original.hide && RFTopt && RFTopt.length > 0) {
            return (
                <div>
                    <LoginModal
                        buttonTitle="Assign"
                        extraStyle={{ width: 'auto' }}
                        title={`Assign Technician (${auctionData && auctionData.name})`}
                        id="auction"
                        extraClass="btn btn-sm btn-primary "
                        onClick={() => this.asignautopopulate(d)}
                        reset={() => this.asignautopopulatereset()}
                        bodyText={
                            <div>
                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {this.state.textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="exampleInputEmail1">Select Assign :</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <SingleSelect
                                                placeholder="Select Assign"
                                                options={RFTopt && RFTopt}
                                                onChange={(e) => this.handlemake(e)}
                                                selectedService={this.state.selectedmake}
                                            />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>&nbsp;
                                    <div className="row-form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-3" />
                                        <div className="col text-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => this.submitauction(d.original.id)}
                                                // style={{ marginLeft: "250px" }}
                                                disabled={this.state.assignbtnDisable}
                                            >
                                                {this.state.assignbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    /></div>
            )
        }
        else if (d.original.Rfassign == 1 && d.original.FormFilled == "yes") {
            return (
                <span className="btn btn-sm btn-primary">Awaiting For Approval</span>)
        }
        else if (d.original.Rfassign == 1 && d.original.FormFilled == "approved") {
            return (
                < span className="btn btn-sm btn-success" > Approved</span >)
        }
        else if (d.original.Rfassign == 1 && d.original.FormFilled == "no") {
            return (
                <span className="btn btn-sm btn-primary">Awaiting</span>)
        }
        else {
            return (
                <span className="btn btn-sm btn-warning">Please Wait for Assign...</span>)

        }

    }
    UploadIMG = (e, name) => {
        console.log(e, '6066066066');
        if (e) {
            this.setState({ [name]: e, filesArray: e.map(fileItem => fileItem.file) });
        }
    }

    pricechg = async (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            await this.setState({ rfprice: e.target.value, pricealert: "" })
        }
    }
    AssignWork1 = (d) => {

        return this.AssignWork(d)

    }
    webcam2 = (e, field, ival = null) => {
        let Files = this.state[field];
        let Files1 = this.state.filesArray1;
        if (e) {
            Files = Files ? Files : []
            Files1 = Files1 ? Files1 : []
            Files.push({ source: e })
            Files1.push({ source: e })
            this.setState({ [field]: Files, filesArray1: Files1 })
            if (ival) this.setState({ [ival]: false })
        }
    }
    AssignWork = (d) => {
        let { RFTopt, editid, WorkData, filesArray1, filesArray, AllRFTopt, SelectCurrentStatus_filtered } = this.state
        this.state.selectbox = RFTopt
        // console.log(WorkData, "WorkData", RFTopt)
        let filtered_rft = []
        // let filtered_rft =  RFTopt.filter(x => x.id == WorkData.RFTechnicianID)
        return (
            <div>
                <LoginModal
                    buttonTitle="Assign Status"
                    extraStyle={{ width: 'auto' }}
                    title={`Assign Current Status ${WorkData && WorkData.name}`}
                    id="auction"
                    extraClass="btn btn-sm btn-primary "
                    onClick={() => this.StatusAutoPopulate(d)}
                    reset={() => this.StatusAutoPopulateReset()}
                    //  buttonStyle={{ backgroundColor: "white", border: "white" }}
                    bodyText={
                        <div>
                            <div className="card-body">
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">
                                        <Alert
                                            className="badge-content"
                                            color={this.state.color}
                                            isOpen={this.state.alertVisible1}
                                            toggle={this.onDismiss}
                                        >
                                            {this.state.textalert}
                                        </Alert>
                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                {WorkData && WorkData.second_userid == null ? <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label htmlFor="exampleInputEmail1">Select Assign :</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <SingleSelect
                                            placeholder="Select Assign"
                                            options={RFTopt.filter(x => x.id == WorkData.RFTechnicianID)}
                                            onChange={(e) => this.handlemake(e)}
                                            selectedService={this.state.selectedmake}
                                        />
                                    </div>
                                    <div className="col-sm-3" />
                                </div> :
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="exampleInputEmail1">Work Technician :</label>
                                        </div>
                                        <div className="col-sm-5 text-center text-success">
                                            {WorkData && WorkData.second_username ? WorkData.second_username : "NONE"}
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>

                                } &nbsp;

                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label htmlFor="exampleInputEmail1">Select Status :</label>
                                    </div>
                                    <div className="col-sm-5">
                                        {this.state.SelectCurrentStatus_filtered.length > 0 ?
                                            <SingleSelect
                                                placeholder="Select Status"
                                                options={this.state.SelectCurrentStatus_filtered}
                                                onChange={(e) => this.selectopt(e, "workval")}
                                                selectedService={this.state.workval}
                                            />
                                            :

                                            <div className=" text-center text-success">
                                                Please Wait..
                                            </div>
                                        }

                                    </div>
                                    <div className="col-sm-3" />
                                </div>&nbsp;
                                {this.state.workval && this.state.workval && this.state.workval.value == 3 &&
                                    <div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Actual RF Cost:</label>
                                            </div>
                                            <div className="col-sm-6" >
                                                <input type="text" placeholder="Enter Actual RF Cost" value={this.state.rfprice} className="form-control" name="price"
                                                    onChange={(e) => this.pricechg(e)} />
                                                {this.state.pricealert && this.state.pricealert != null &&
                                                    <p style={{ color: "red" }}>{this.state.pricealert && this.state.pricealert}
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">File Upload: {<button className="btn btn-default btn-sm" value={this.state.Isfiles1Cam} onClick={() => this.setState({ Isfiles1Cam: !this.state.Isfiles1Cam })}>
                                                    <MDBIcon icon="camera" size="lg" />
                                                </button>}</label>
                                            </div>
                                            <div className="col-sm-6" >
                                                {
                                                    this.state.Isfiles1Cam ? <Camera
                                                        image={(e) => { this.webcam2(e, 'filesArray', 'Isfiles1Cam') }}
                                                        width={300}
                                                        height={400}
                                                        id={'Isfiles1Cam'}
                                                    /> :
                                                        <div><FilePond
                                                            files={Array.isArray(filesArray1) ? filesArray1 : []}
                                                            allowReorder={true}
                                                            allowMultiple={true}
                                                            allowImageResize={true}
                                                            imagePreviewMinHeight="50"
                                                            imagePreviewMaxHeight="50"
                                                        //    maxFileSize={'3072KB'}
                                                            acceptedFileTypes={["image/*"]}
                                                            onupdatefiles={(fileItems) => {
                                                                this.UploadIMG(fileItems, 'filesArray1')
                                                            }}
                                                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                        />
                                                            {/* {this.state.imagealeart1 ?
                                                                <div>
                                                                    <label style={{ color: "red" }} >Please Check Image is Greater than 300KB</label>
                                                                    <br />
                                                                </div>
                                                                : <></>
                                                            }
                                                            <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                                            <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}

                                                        </div>
                                                }
                                                {/* <div className="bgcolor"> */}

                                                {/* <FilePond
                                                        files={filesArray}
                                                        allowReorder={true}
                                                        allowMultiple={true}
                                                        allowImageResize={true}
                                                        imagePreviewMinHeight="50"
                                                        imagePreviewMaxHeight="50"
                                                        acceptedFileTypes={["image/*"]}
                                                        onupdatefiles={fileItems => {
                                                            this.BillUploadIMG(fileItems);
                                                        }}
                                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                    /> */}
                                                {/* </div> */}
                                            </div>
                                            {this.state.img && this.state.img.length && this.state.workval.value == 3 ? this.state.img : null}
                                        </div>
                                    </div>
                                }
                                <div className="row-form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-3" />
                                    <div className="col text-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => this.OnSubmitWorkStatus()}
                                            // style={{ marginLeft: "250px" }}
                                            disabled={this.state.workbtnDisable}
                                        >
                                            {this.state.workbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                /></div >
        )


    }
    FormFill1 = (d) => {
        if (d.Rfassign == 1 && d.FormFilled == "yes" || d.FormFilled == "work") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-danger'

                        onClick={() => this.BAdSumaryData(d)}
                    >
                        Bad Summary
                    </button>
                </div>
            );
        }
        else if (d.Rfassign == 1 && d.FormFilled == "approved") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-danger'

                        onClick={() => this.BAdSumaryData(d)}
                    >
                        Bad Summary
                    </button>
                </div>
            )
        }
        else if (d.Rfassign == 1 && d.FormFilled == "no") {
            return (
                <span className="btn btn-sm btn-primary">Awaiting</span>)
        }
        else {
            return (
                <span >-</span>
            )
        }
    }
    BAdSumaryData = async (d, name = "normal") => {
        console.log('entered bad')
        // console.log(d, 'ddd')
        let js = []
        js = d.checkjson
        // console.log(js, 'js')
        let badselected = []
        let total_estimation_COst = 0
        let Selected_estimation_COst = 0
        let Inspection_technician = d.RFTechnicianName
        let Work_technician = d.second_technician
        let work_checklist = false
        if (js && js.length) {
            await js.map((ival, i) => {
                ival.map((jval) => {
                    if (jval.status == "Repair" || jval.status == "Average") {
                        // console.log(jval, 'jval2')
                        badselected.push(jval)
                        if (jval && jval.estimate && jval.estimate) {
                            total_estimation_COst = parseInt(total_estimation_COst) + parseInt(jval.estimate)
                        }
                        if (jval && jval.estimate && jval.estimate && jval.work == true) {
                            Selected_estimation_COst = parseInt(Selected_estimation_COst) + parseInt(jval.estimate)
                        }
                        if (jval.work == true && jval.workProcess == false) {

                            work_checklist = true
                        }
                    }
                })
            })
            await this.setState({ data_come: d, badselected, Work_technician, Inspection_technician, total_estimation_COst, work_checklist, Selected_estimation_COst })
            if (name == 'normal') {
                await this.badsummarymodal(badselected, d)
                window.$("#badmodal").modal("show")
            }
        }
    }
    Selected_work = async (e, name = null, id, value, index, data_find_name) => {
        let { badselected, data_come, total_estimation_COst } = this.state
        console.log(e, name, id, value, index, data_find_name, "e, name = null, id, value, index, data_find_name", badselected);

        let Selected_estimation_COst = 0
        if (data_come.process == 2 || data_come.approval != 0) {
            return false
        } else {
            var checkBox = document.getElementById(id);
            if (checkBox.checked == true) {
                badselected[index][`${name}`] = value
            } else {
                badselected[index][`${name}`] = false
            }
            let data = data_come.checkjson
            let w1 = await data.map(async (ival, i) => {
                await ival.map((jval) => {
                    badselected.map((kval, k) => {
                        if ((jval.status == "Repair" || jval.status == "Average") && jval.index == kval.index && jval.index == kval.index && jval.name == data_find_name) {
                            jval.work = badselected[index][`${name}`]
                        }
                    })
                })
            })
            let w2 = await data.map(async (ival, i) => {
                await ival.map((jval) => {

                    if ((jval.status == "Repair" || jval.status == "Average") && jval && jval.estimate && jval.estimate && jval.work == true) {
                        console.log(jval.estimate, "estimate");
                        Selected_estimation_COst = Selected_estimation_COst + parseInt(jval.estimate)
                    }

                })
            })
            await Promise.all(w1, w2)
            data_come.checkjson = data
            await this.setState({ badselected, Selected_estimation_COst })
            await this.badsummarymodal(badselected, data_come)
        }
    }
    badsummarymodal = async (badselected, d) => {
        console.log(d, "dddd");
        let modaldata1 = []
        let { total_estimation_COst, work_checklist, Inspection_technician, Work_technician, Selected_estimation_COst } = this.state
        modaldata1.push(
            <>
                <div className="bgcolor" style={{ width: "100%", overflow: "auto" }}>
                    <div class="set-form">
                        <table id="myTable" class="table table-bordered">
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Remarks</th>
                                <th>Estimate</th>
                                <th>Work</th>
                                <th>Process</th>
                            </tr>
                            {badselected.map((item, i) => {
                                return (
                                    <tbody ><tr>
                                        <td className="text-left " >
                                            {item.status && item.status == "Repair" ?
                                                <p style={{ color: "red", fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                                                :
                                                <p style={{ fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                                            }
                                        </td>

                                        <td>
                                            {item.status && item.status == "Repair" ?
                                                <label
                                                    style={{ color: "red", fontSize: "18px" }}
                                                >{
                                                        item.status
                                                    }</label>
                                                :
                                                <label
                                                    style={{ fontSize: "18px" }}
                                                >{
                                                        item.status
                                                    }</label>
                                            }
                                        </td>
                                        <td>
                                            {item.status && item.status == "Repair" ?
                                                <p style={{ color: "red", fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                                                :
                                                <p style={{ fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                                            }
                                        </td>
                                        <td>
                                            {item.status && item.status == "Repair" ?
                                                <p style={{ color: "red", fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                                                :
                                                <p style={{ fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                                            }
                                        </td>
                                        <td>
                                            {d.work_Selection ?
                                                <input
                                                    style={{ marginTop: "-1.2px" }}
                                                    className=""
                                                    id={`badselect${i}`}
                                                    checked={item && item.work && item.work == true ? true : false}
                                                    type='checkbox'
                                                    onClick={(e) => this.Selected_work(e, 'work', `badselect${i}`, true, i, item.name)}
                                                />
                                                :
                                                <input
                                                    style={{ marginTop: "-1.2px" }}
                                                    className=""
                                                    id={`badselect${i}`}
                                                    checked={item && item.work && item.work == true ? true : false}
                                                    type='checkbox'
                                                />
                                            }

                                        </td>
                                        <td>
                                            <input
                                                style={{ marginTop: "-1.2px" }}
                                                className=""
                                                checked={item && item.workProcess && item.workProcess == true ? true : false}
                                                type='checkbox'
                                            />

                                        </td>
                                    </tr>
                                    </tbody>
                                );
                            })}
                        </table>
                    </div>
                </div >
                {d.work_Selection &&
                    <button
                        type="button"
                        style={{ minWidth: "250px" }}
                        className="btn btn-sm btn-primary"
                        onClick={() => this.alert_validation(this.Approval1, d)}
                    >
                        Work Approve
                    </button>
                }
                <div className="text-right"><b>Total Work Estimation : {total_estimation_COst} RS</b></div>
                <div className="text-right"><b>Selected Work Estimation : {Selected_estimation_COst} RS</b></div>
                {Inspection_technician ? <div className="text-right"><b style={{ color: "#07278f" }}>Inspection Technician : {Inspection_technician}</b></div> : ""}
                {Work_technician ? <div className="text-right"><b style={{ color: "#fc4c0d" }}>Work Technician : {Work_technician}</b></div> : ""}
                <br />
                <br />
                <div className="text-right" style={{ color: work_checklist == false ? "#07278f" : "#fc4c0d" }}><b>{work_checklist == false ? "Technician Process Completed" : d.work_Selection ? "Technician Inspection Process Completed" : "Technician Process OnGoing"}</b></div>

            </>
        )
        this.setState({ modaldata1 })
    }
    Approval1 = async (d, text) => {
        let data = d
        let { Current_User } = this.state
        let body = {};
        if (text == "yes") {
            body.checkjson = JSON.stringify(data.checkjson)
            body.rf_incharge_name = Current_User.userName
        }
        try {
            let result = await CmsContent.updateMaster("tbl_checklist350", data.Formid, body);
            console.log(result);
            await this.setState({ reloadfun: true })
            if (result) {
                window.$("#badmodal").modal("hide")
                await this.MainFunction()
            }
        } catch (error) {
            console.log(error);
        }
    };
    removeimg = async (i) => {
        let { oldFiles, img, images } = this.state;
        if (oldFiles || img) {
            images && images.splice(i, 1)
            img && img.splice(i, 1)
            await this.setState({ images, img })
            console.log(images, img,);
            this.imgFun()
        }
    }
    StatusAutoPopulate = async (d) => {
        await this.BAdSumaryData(d, "Assign")
        let { SelectCurrentStatus, work_checklist, SelectCurrentStatus_filtered } = this.state
        // console.log(SelectCurrentStatus, 'SelectCurrentStatus')
        // console.log(SelectCurrentStatus_filtered, 'SelectCurrentStatus_filtered');
        // console.log(work_checklist, 'work_checklist')
        if (work_checklist) {
            SelectCurrentStatus_filtered = SelectCurrentStatus.filter(x => x.value != 3)
            if (SelectCurrentStatus_filtered.length) {
                await this.setState({ SelectCurrentStatus_filtered })
            }
        } else {
            await this.setState({ SelectCurrentStatus_filtered: SelectCurrentStatus })
        }
        await this.setState({ rfprice: "", img: [], images: [] })
        console.log(d, "editit");
        if (d && d) {
            await this.setState({ WorkData: d })
        }
        if (d.status1) {
            this.state.SelectCurrentStatus.map((ival, i) => {
                if (ival.value == d.status1) {
                    this.setState({ workval: ival })
                }
            })
        }
        if (d.rfcost && d.rfcost != null) {
            await this.setState({ rfprice: d.rfcost })
        }
        if (d.bill && d.bill.length && d.bill) {
            let images = this.state.images;
            images = JSON.parse(d.bill && d.bill.length && d.bill);

            let img = [];
            if (images != null) {
                images.map((ival, i) => {


                    if (ival) {
                        let spliting = ival.split(".");
                        if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                            let a = null
                            a = "false/pdfIconTrans_1599478202382.png";

                            let wait = img.push(
                                <div>
                                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                        <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                            onClick={() => this.removeimg(i)}></img>
                                    </div>
                                    <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                                        src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                                        style={{
                                            width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                                        }} />
                                </div>
                            )
                        } else {

                            let wait = img.push(
                                <div>
                                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                        <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                            onClick={() => this.removeimg(i)}></img>
                                    </div>
                                    <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                                        src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                                        style={{
                                            width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                                        }} />
                                </div>
                            )
                        }
                    }
                })
                await this.setState({ img, oldFiles: images, images })
            }
        }

    }
    imgFun = async () => {
        let { images } = this.state
        let img = [];
        if (images && images.length) {
            images.map((ival, i) => {
                if (ival) {
                    let spliting = ival.split(".");
                    if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                        let a = null
                        a = "false/pdfIconTrans_1599478202382.png";

                        let wait = img.push(
                            <div>
                                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                        onClick={() => this.removeimg(i)}></img>
                                </div>
                                <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                                    src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                                    style={{
                                        width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                                    }} />
                            </div>
                        )
                    } else {

                        let wait = img.push(
                            <div>
                                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                        onClick={() => this.removeimg(i)}></img>
                                </div>
                                <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                                    src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                                    style={{
                                        width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                                    }} />
                            </div>
                        )
                    }
                }
            })
            await this.setState({ img, oldFiles: images })
            console.log(this.state.oldFiles, 'old');
        }
    }
    StatusAutoPopulateReset = async () => {
        await this.setState({ workval: [], pricealert: "", price: "", WorkData: null, rfprice: "", img: [] })
    }
    reset1 = async (d) => {
        // console.log(d.original);
        await this.setState({ modaldata1: [] })

    }
    onClk = (val) => {
        let { Data } = this.state
        if (val === 1) {
            this.setState({ Datas: this.state.data3, WorkVal: false })
        }
        if (val === 2) {
            this.setState({ Datas: this.state.data5, WorkVal: false })
        }
        if (val === 3) {
            this.setState({ Datas: this.state.data2, WorkVal: false })
        }
        if (val === 4) {
            this.setState({ Datas: this.state.data4, WorkVal: false })
        }
        if (val === 6) {
            this.setState({ Datas: this.state.data8, WorkVal: true })
        }
        // if (val === 5) {
        //     this.setState({ Datas: this.state.data7, WorkVal: false })
        // }
        if (val === 7) {
            let { data8 } = this.state;
            console.log(data8, '888')
            this.setState({ Datas: this.state.data9, WorkVal: true })
        }

        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    MainFunction = async () => {
        await this.setState({ loading: true, Datas: [] })
        try {
            let data8 = []
            let data9 = []
            let datanew = []
            let removeFromData8 = []
            await this.vals();
            let Stage5_Prospective = await CmsContent.BUYDFCARZ(this.state.User.id, "Incharge_RF")
            if (Stage5_Prospective) {
                console.log(Stage5_Prospective, "APII");
                if (Stage5_Prospective && Stage5_Prospective.Refurbishment_Prospective) {
                    datanew = await Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_work == true)

                    //seperate data from data8 (where rf technician completed work) and add it to data9 
                    if (datanew && datanew.length > 0) {
                        for (let index = 0; index < datanew.length; index++) {
                            let ival = datanew[index];
                            let count = false
                            if (ival.checkjson && ival.checkjson.length > 0) {
                                await ival.checkjson.map((jval) => {
                                    for (let kval of jval) {
                                        if (kval.status == "Repair" || kval.status == "Average") {
                                            if (kval.work == true && kval.workProcess == true) {
                                                count = true
                                            }
                                        }
                                    }

                                })
                            }
                            if (count) {
                                data9.push(ival)
                                removeFromData8.push(ival.id)
                            }
                        }
                    }

                    //remove data which are completed by rf technician from data8
                    data8 = datanew.filter(ival => !removeFromData8.some(jval => ival.id == jval))

                }

                //data count correction for dashboard
                let data_for_f = Stage5_Prospective.data_for_f
                data_for_f[0].work = data8.length
                data_for_f[0].comp = data9.length

                await this.setState({
                    data: Stage5_Prospective.Refurbishment_Prospective,
                    data2: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_assingned == true),
                    data3: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_Recieved == true),
                    data4: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_approved == true),
                    data5: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_assigned == true),
                    // data6: Stage5_Prospective.data7,
                    data8,
                    data9,
                    data_for_f,
                    loading: false
                })
            }
        } catch (error) {
            this.setState({ loading: false })
            console.log(error);
        }
    }
    async componentDidMount() {
        this.MainFunction()
    }
    Status = (d) => {
        if (d.original.status == 'inactive') {
            return (<div><button type="button" className="btn btn-danger btn-sm"
            // onClick={()=>this.F_proofForm(d)}
            >SOLD</button></div>);
        } else {
            return (
                <div>
                    <button type="button" className="btn btn-success btn-sm"
                    >AVAILABLE</button>
                </div>
            );
        }
    }
    edash = () => {
        return (<div className="">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="row col-12 mt-4 text-center">
                            <div className="col ">
                                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                    <Icon icon={wrench} style={{ color: 'purple' }} size={35} />
                                    <h2 className="mt-2 black" >Trade in</h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f && this.state.data_for_f[0].fill ? this.state.data_for_f[0].fill : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col " onClick={() => this.onClk(2)} >
                                <div className="dashboardCard1">
                                    <Icon icon={warning} size={35} style={{ color: 'red' }} />
                                    <h2 className="mt-2 black"> Assigned Inspection</h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f && this.state.data_for_f[0].assign ? this.state.data_for_f[0].assign : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col " onClick={() => this.onClk(3)} >
                                <div className="dashboardCard1">
                                    <Icon icon={checkmark} size={35} style={{ color: '#0C635A' }} />
                                    <h2 className="mt-2 black"> Inspection Completed </h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f && this.state.data_for_f[0].view ? this.state.data_for_f[0].view : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col " onClick={() => this.onClk(6)} >
                                <div className="dashboardCard1">
                                    <Icon icon={clock} style={{ color: '#FFC300' }} size={35} />
                                    <h2 className="mt-2 black"> WIP </h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].work ? this.state.data_for_f[0].work : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col " onClick={() => this.onClk(7)} >
                                <div className="dashboardCard1">
                                    <Icon icon={task} style={{ color: '#FFC300' }} size={35} />
                                    <h2 className="mt-2 black"> RF Completed </h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].comp ? this.state.data_for_f[0].comp : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col " onClick={() => this.onClk(4)} >
                                <div className="dashboardCard1">
                                    <Icon icon={task} size={35} style={{ color: 'green' }} />
                                    <h2 className="mt-2 black"> Accepted </h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f && this.state.data_for_f[0].approve ? this.state.data_for_f[0].approve : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    selectopt = async (e, ch) => {
        // console.log(e);
        this.setState({ [ch]: e, })
    }
    OnSubmitWorkStatus = async () => {

        let { workval, WorkData, selectedmake, filesArray } = this.state
        // console.log("enter", WorkData, workval, selectedmake);

        // let imagealeart = ''
        // if (filesArray && filesArray.length) {
        //     const fileSize = filesArray[0].size / 1024 / 1024;
        //     const maxFileSize = 3; // Maximum file size in MB
        //     // Validate file size
        //     if (fileSize > maxFileSize) {
        //         this.setState({ imagealeart1: true })
        //         setTimeout(() => {
        //             this.setState({ imagealeart1: false })
        //         }, 2000);
        //         imagealeart = true
        //         return true;
        //     }
        // }
        // if (imagealeart == true) {
        //     return false;
        // }

        let { pricealert, rfprice } = this.state
        if (!workval && workval.value) {
            alert("Please Select Work Status")
            return false
        }
        if (this.state && this.state.workval && this.state.workval.value == 3) {
            if (!rfprice) {
                let content = "* Please Enter Price"
                await this.setState({ pricealert: content })
                return true
            }
            let formdata = new FormData()
            formdata.append('status1', workval.value)
            formdata.append('RF_cost', rfprice)
            formdata.append('customeid', WorkData.userid)
            if (this.state.filesArray && this.state.filesArray.length) {
                this.state.filesArray.map((ival, i) => {
                    formdata.append(`files`, ival);
                })
            }
            if (this.state.oldFiles) {
                formdata.append(`oldFiles`, JSON.stringify(this.state.oldFiles))
            }
            console.log(...formdata, "formdata");

            this.setState({ workbtnDisable: true })
            try {
                let result = await CmsContent.RFUpdateMaster("tbl_checklist350", WorkData.Formid, formdata)
                if (result) {
                    console.log(result);
                    await this.setState({ workbtnDisable: false, textalert: "Submited Sucessfully", alertVisible1: true, color: "success" })
                    // this.MainFunction()
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
            }
            catch (err) {
                console.log(err);
                this.setState({ workbtnDisable: false })
            }
        }
        else {
            let body = {};
            body.status1 = workval.value
            if (selectedmake && selectedmake.value == 0) {
                body.second_userid = 0
            }
            if (selectedmake && selectedmake.value && selectedmake.value) {
                body.second_userid = selectedmake.value
            }
            this.setState({ workbtnDisable: true })
            // console.log(body, 'body')
            // console.log(WorkData.Formid, 'WorkData.Formid');
            // return false;
            try {
                let results = await CmsContent.updateMaster("tbl_checklist350", WorkData.Formid, body)
                if (results) {
                    await this.setState({ workbtnDisable: false, textalert: "Assigned Sucessfully", alertVisible1: true, color: "success" })
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
            }
            catch (err) {
                console.log(err);
                this.setState({ workbtnDisable: false })
            }
        }

    }
    render() {
        const { alertVisible, textalert, modeloptions, work_checklist } = this.state;
        const { Data, modaldata1, data_come, WorkData, Edit_Data } = this.state;
        // console.log(Edit_Data, "Edit_Data", work_checklist, 'vwork_checklist');
        return (<React.Fragment>
            <FormModal
                id={"formmodel"}
                extraStyle={{ width: 'auto' }}
                bodyText={
                    <div>
                        {this.state.Edit_Data &&
                            <table className="LeadTable">
                                <thead>
                                    <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                                        <th class="column1">Type</th>
                                        <th class="column2">Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Edit_Data.name && <tr>
                                        <td class="column1">Name </td>
                                        <td class="column2"> {Edit_Data.name} </td>
                                    </tr>}
                                    {Edit_Data.mobile && <tr>
                                        <td class="column1">Mobile Number </td>
                                        <td class="column2"> {this.Call(Edit_Data.mobile)} </td>
                                    </tr>}
                                    {Edit_Data.make && <tr>
                                        <td class="column1">Make</td>
                                        <td class="column2"> {this.getValueFromArray(Edit_Data.make, this.state.MakeOptions)} </td>
                                    </tr>}
                                    {Edit_Data.model && <tr>
                                        <td class="column1">Model </td>
                                        <td class="column2"> {this.getValueFromArray(Edit_Data.model, this.state.modelopt)} </td>
                                    </tr>}
                                    {Edit_Data.variant && <tr>
                                        <td class="column1">Variant </td>
                                        <td class="column2"> {this.getValueFromArray(Edit_Data.variant, this.state.variantopt)} </td>
                                    </tr>}
                                    {Edit_Data.color && <tr>
                                        <td class="column1">Color </td>
                                        <td class="column2"> {this.getValueFromArray(Edit_Data.color, this.state.coloropt)} </td>
                                    </tr>}
                                    {Edit_Data.RFTechnicianName && <tr>
                                        <td class="column1">Inspection Technician </td>
                                        <td class="column2"> {Edit_Data.RFTechnicianName} </td>
                                    </tr>}
                                    {Edit_Data.second_technician && <tr>
                                        <td class="column1">Work Technician </td>
                                        <td class="column2"> {Edit_Data.second_technician} </td>
                                    </tr>}
                                    {Edit_Data && Edit_Data.commit == 1 && <tr>
                                        <td class="column1">Commit</td>
                                        <td class="column2"> Yes </td>
                                    </tr>}
                                    {Edit_Data && Edit_Data.commit_work && Edit_Data.commit_work.length > 0 && <tr>
                                        <td class="column1">Commit Work  </td>
                                        <td class="column2">
                                            {Edit_Data.commit_work && Edit_Data.commit_work.length && Edit_Data.commit_work.map((ival, i) => {
                                                return (
                                                    <>
                                                        {i + 1}: {ival}<br />
                                                    </>
                                                )
                                            })}
                                        </td>
                                    </tr>}

                                </tbody>
                            </table>
                        }
                    </div>
                }
            />
            <main className="main my-4">
                <div className="container-fluid">
                    {/* {Modmodaldata1 && Modmodaldata1 == true && */}
                    <div class="modal fade" id="badmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content text-center" style={{ width: "auto" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle">      Bad Summary ({data_come && data_come.Points && data_come.Points})</h5>
                                    <button type="button" class="close" onClick={() => { this.reset1() }} data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {modaldata1 && modaldata1}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* } */}
                    {this.edash()}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Refurbishment</h1>
                                </div>
                                <div className="card-body">
                                    {this.state.loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : this.state.Datas && this.state.Datas.length > 0 ? (
                                        this.state.Datas.length > 0 ?
                                            <Datatable
                                                data={this.state.Datas}
                                                columnHeading={this.state.WorkVal ? this.state.column2 : this.state.column}
                                                excelColumn={this.state.WorkVal ? this.state.excelColumn2 : this.state.excelColumn}
                                            /> : null
                                    ) : (<div style={{ textAlign: "center" }} >{null}</div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment >)
    }
}
export default RFinchargelist;