import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import moment from "moment";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { confirmAlert } from "react-confirm-alert";
import { ACCESS_POINT } from "../../../../config";
import { x } from 'react-icons-kit/iconic/x'


class TLRefurbishment extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [], total_estimation_COst: 0,
      User: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      loading: false,
      reloadfun: false,
      Datas: [],
      MakeOptions: [],
      data6: [],
      coloropt: [],
      variantopt: [],
      modelopt: [],
      BilluploadChanges: 0,
      SelectCurrentStatus: [{ label: "Work yet start", value: 0 }, { label: "Work in progress", value: 1 }, { label: "Waiting for parts", value: 2 }, { label: "Completed", value: 3 }],
      price: "",
      pricealert: "",
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          minWidth: 110,
          Cell: d => this.Document(d),
          className: "text-center"
        },
        {
          Header: "Bad Summary",
          accessor: "name",
          minWidth: 120,
          Cell: d => this.FormFill1(d.original),
          className: "text-center"
        },
        {
          Header: "Approval",
          accessor: "mobile",
          minWidth: 170,
          Cell: d => this.Approval(d),
          className: "text-center"
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "reg_no"
        },
        {
          Header: "Employee Name",
          accessor: "jobroleName"
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "RF Incharge",
          accessor: "RFInchargeName",
          minWidth: 170,

        },
        {
          Header: "RF Technician",
          minWidth: 170,
          accessor: "RFTechnicianName"
        },
        {
          Header: "type",
          accessor: "Data_Inspection_type",
          minWidth: 100,

        },

      ],
      excelColumn: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "REG NO",
          key: "reg_no"
        },
        {
          label: "Employee Name",
          key: "jobroleName"
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Color",
          key: "colourName",
        },
        {
          label: "RF Incharge",
          key: "RFInchargeName"
        },
        {
          label: "RF Technician",
          key: "RFTechnicianName"
        },
        {
          label: "type",
          key: "Data_Inspection_type",

        },
      ],
      column2: [
        {
          Header: "Form",
          accessor: "mobile",
          minWidth: 110,
          Cell: d => this.Document(d),
          className: "text-center"

        },
        {
          Header: "Bad Summary",
          accessor: "name",
          minWidth: 120,
          Cell: d => this.FormFill1(d.original),
          className: "text-center"

        },
        {
          Header: "Status",
          accessor: "mobile",
          minWidth: 180,
          Cell: d => this.WorkStatus(d),
          className: "text-center"

        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "reg_no"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        // {
        //   Header: "Contact",
        //   accessor: "mobile",
        // },
        {
          Header: "Employee name ",
          accessor: "jobroleName"
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "RF Incharge",
          accessor: "RFInchargeName",
          minWidth: 170,
        },
        {
          Header: "RF Technician",
          minWidth: 170,
          accessor: "RFTechnicianName"
        },
        {
          Header: "type",
          accessor: "Data_Inspection_type",
          minWidth: 100,

        },

      ],
      excelColumn2: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "REG NO",
          key: "reg_no"
        },
        {
          label: "Mobile",
          key: "mobile",
        },
        {
          label: "Date",
          key: "created_At"
        },
        // {
        //   label: "Contact",
        //   key: "mobile",
        // },
        {
          label: "Employee name ",
          key: "jobroleName"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Color",
          key: "colourName",
        },
        {
          label: "RF Incharge",
          key: "RFInchargeName"
        },
        {
          label: "RF Technician",
          key: "RFTechnicianName"
        },
        {
          label: "type",
          key: "Data_Inspection_type",

        }
      ],
      stage: "",
      CustomerId: "",
      data_for_f: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0, "ftd": 0 }],
      filteredLoc: "",
      filteredMake: "",
      LocationOptions: [],
      FilterData: [],

    };
    this.vals()
  }

  MainFunction = async () => {
    let { usertypelog } = this.state;
    try {
      await this.setState({ loading: true, Datas: [] })

      let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'Refurbishment')
      // console.log(results, "mAInaPI");
      if (results) {
        await this.setState({
          data_for_f: results.data_for_f,
          data2: results.Fulldata && results.Fulldata.filter(x => x.filter_Approval == true),
          data3: results.Fulldata && results.Fulldata.filter(x => x.filter_Approved == true),
          data4: results.Fulldata && results.Fulldata.filter(x => x.filter_Working == true),
          data5: results.Fulldata && results.Fulldata.filter(x => x.filter_Completed == true),
          data6: results.Fulldata && results.Fulldata.filter(x => x.filter_Reject == true),
          userId: results.userId,
          loading: false
        })
      }
    } catch (error) {
      await this.setState({ loading: false })
      console.log(error);
    }
  }

  async componentDidMount() {
    await this.MainFunction()
  }
  async vals() {
    let ALL_User_Details = await this.decryption('Downline_Details')
    if (ALL_User_Details) {
      // console.log(ALL_User_Details, 'ALL_User_Details.TECHNICIANOPT');
    }
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      await this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt,
        LocationOptions: allOpt.locopt,

      })
    }
  }
  UpdateSTatus = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-warning"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button>
    );
  };

  Editdata = async d => {
    this.setState({ stage: "refurbishment", CustomerId: d.original.id });
  };

  Approval = d => {
    // console.log(d.original, "ddddddddddddddddddddddd")
    let { usertypelog } = this.state;
    if (d.original.rf_incharge_name && (d.original.approval == 0 || d.original.FormFilled === "yes") && usertypelog.orderBy != 3 && d.original.Total < 5000) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary btnwidth"
          onClick={() => this.alert_validation(this.Approval1, d.original)}
        // onClick={() => this.buttonApproval1(d)}
        >
          Approve
        </button>
      );
    }
    else if (d.original.rf_incharge_name && (d.original.approval == 0 || d.original.FormFilled === "yes") && usertypelog.orderBy != 3 && d.original.Total >= 5000 && usertypelog.orderBy != 1 && d.original.managerapprove == 0) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-info btnwidth"
          onClick={() => this.alert_validation(this.Approval1_Mange, d.original)}
        // onClick={() => this.buttonApproval1(d)}
        >
          Verify
        </button>
      );
    }
    else if (d.original.rf_incharge_name && (d.original.approval == 0 || d.original.FormFilled === "yes") && usertypelog.orderBy != 3 && d.original.Total >= 5000 && usertypelog.orderBy != 1 && d.original.managerapprove == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success btnwidth"
        // onClick={() => this.alert_validation(this.Approval1_Mange, d.original)}
        // onClick={() => this.buttonApproval1(d)}
        >
          Verified
        </button>
      );
    }
    else if (d.original.rf_incharge_name && (d.original.approval == 0 || d.original.FormFilled === "yes") && usertypelog.orderBy != 3 && d.original.Total >= 5000 && usertypelog.orderBy != 2 && d.original.managerapprove == 0) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-info btnwidth"
          onClick={() => this.alert_validation(this.Approval1, d.original)}
          style={{ width: "100 %" }}
        // onClick={() => this.buttonApproval1(d)}
        >
          Verify and Approve
        </button>
      );
    }
    else if (d.original.rf_incharge_name && (d.original.approval == 0 || d.original.FormFilled === "yes") && usertypelog.orderBy != 3 && d.original.Total >= 5000 && usertypelog.orderBy != 2 && d.original.managerapprove == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-info btnwidth"
          onClick={() => this.alert_validation(this.Approval1, d.original)}
        // onClick={() => this.buttonApproval1(d)}
        >
          Approve
        </button>
      );
    }
    else if (d.original.approval == 1 || d.original.FormFilled === "approved") {
      return (
        this.View(d)
      )
    }
    else {
      return (
        <label>-</label>
      )
    }
  };

  WorkStatus = d => {
    let { usertypelog } = this.state;
    console.log(usertypelog.orderBy, "usertypelog.orderBy");
    if (usertypelog.orderBy == 15) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-warning"
        // onClick={() => this.F_proofForm(d)}
        >
          Waiting for approval
        </button>
      );
    } else {
      if (parseInt(d.original.status1) == 3) {
        return (
          this.View(d)
        );
      }
      else {
        return (
          <span>{this.state.SelectCurrentStatus.map((ival, i) => {
            if (d.original.status1 == ival.value) {
              return (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                >
                  {ival.label}
                </button>
              )
            }
          })
          }</span>
        )
      }
    }
  };
  pricereset = async () => {
    await this.setState({ pricealert: "", price: "", BilluploadChanges: 0, isToggleOn: false })
  }
  pricechg = async (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      await this.setState({ price: e.target.value, pricealert: "" })
    }
  }
  StatusAutoPopulate = async (d) => {
    // console.log(d, "dddd");
    if (d && d) {
      await this.setState({ WorkData: d })

      let images = this.state.images;
      images = JSON.parse(d.bill);
      let img = [];

      if (images != null) {
        images.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                style={{
                  width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                }}
                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
              )
            } else {
              let wait = img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                style={{
                  width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                }}
                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
              )
            }
          }
        })
        this.setState({ img })
      }
      // console.log(img, "img");
    }
  }
  BilluploadChanges = async () => {
    await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
    if (this.state.isToggleOn === true) {
      await this.setState({ BilluploadChanges: 1 })
    } else (
      await this.setState({ BilluploadChanges: 0 })
    )
  };
  View = (d) => {

    let { orderdata, users, WorkData, BilluploadChanges, usertypelog } = this.state
    // console.log(WorkData, "WorkData");
    let data = d.original
    if (d) {
      return (
        <LoginModal
          buttonTitle={data && data.FormFilled == "approved" ? "Approved" : "Completed"
          }
          title={`Evaluator Estimation ${WorkData && WorkData.name}`
          }
          extraClass={data && data.FormFilled == "approved" ? "btn btn-sm btn-success w-100" : "btn btn-sm btn-primary w-100"}
          onClick={() => this.StatusAutoPopulate(data)}
          id="evaluation"
          extraStyle={{ width: '170' }}
          reset={() => this.pricereset()}
          bodyText={
            < div >


              < section className="container-fluid" >
                <div className="row">
                  <div className="col-sm-12 table-responsive">
                    {this.state.BilluploadChanges === 0 && (
                      <table class="table  table-striped ">
                        <tbody>
                          <tr>
                            <th scope="row">NAME</th>
                            <td>{WorkData && WorkData.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">MOBILE</th>
                            <td>{WorkData && this.Call(WorkData.mobile)}</td>
                          </tr>
                          <tr>
                            <th scope="row">EMAIL</th>
                            <td>{WorkData && WorkData.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">REG NO</th>
                            <td>{WorkData && WorkData.reg_no}</td>
                          </tr>
                          <tr>
                            <th scope="row">MAKE</th>
                            <td>{this.getValueFromArray(WorkData && WorkData.make, this.state.MakeOptions)}</td>
                          </tr>
                          <tr>
                            <th scope="row">MODAL</th>
                            <td>{this.getValueFromArray(WorkData && WorkData.model, this.state.modelopt)}</td>
                          </tr>
                          <tr>
                            <th scope="row">COLOR</th>
                            <td>{this.getValueFromArray(WorkData && WorkData.color, this.state.coloropt)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Employee Name</th>
                            <td>{WorkData && WorkData.jobroleName && WorkData.jobroleName}</td>
                          </tr>

                          <tr>
                            <th scope="row">RF incharge </th>
                            <td>{WorkData && WorkData.RFInchargeName && WorkData.RFInchargeName}</td>
                          </tr>
                          <tr>
                            <th scope="row">RF Technician</th>
                            <td>{WorkData && WorkData.RFTechnicianName && WorkData.RFTechnicianName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Work Technician</th>
                            <td>{WorkData && WorkData.second_technician && WorkData.second_technician}</td>
                          </tr>
                          <tr>
                            <th scope="row">Inspection Type</th>
                            <td>{WorkData && WorkData.Data_Inspection_type && WorkData.Data_Inspection_type}</td>
                          </tr>
                          {WorkData && WorkData.Data_Inspection_type && WorkData.Data_Inspection_type == "Commit Work" ?
                            "" :
                            <>
                              <tr>
                                <th scope="row">TOTAL RECONDITIONING ESTIMATE (B)</th>
                                <td>{WorkData && WorkData.recondition_est && WorkData.recondition_est}</td>
                              </tr>
                              <tr>
                                <th scope="row">STANDARD SELLING PRICE (A)</th>
                                <td>{WorkData && WorkData.std_sell_price && WorkData.std_sell_price}</td>
                              </tr>
                              <tr>
                                <th scope="row">MARGIN (C)</th>
                                <td>{WorkData && WorkData.margin && WorkData.margin}</td>
                              </tr>
                              <tr>
                                <th scope="row">WARRANTY POOL (D)</th>
                                <td>{WorkData && WorkData.warranty_pool && WorkData.warranty_pool}</td>
                              </tr>
                              <tr>
                                <th scope="row">GST (E)</th>
                                <td>{WorkData && WorkData.gst && WorkData.gst}</td>
                              </tr>
                              <tr>
                                <th scope="row">EXCHANGE BONUS (F)</th>
                                <td>{WorkData && WorkData.exchange_bonus && WorkData.exchange_bonus}</td>
                              </tr>
                              <tr>
                                <th scope="row">BODY SHOP (G)</th>
                                <td>{WorkData && WorkData.body_shop && WorkData.body_shop}</td>
                              </tr>
                              <tr>
                                <th scope="row">TRADE-IN PRICE (A-B-C-D-E+F+G)</th>
                                <td>{WorkData && WorkData.trade_in_price && WorkData.trade_in_price}</td>
                              </tr>
                            </>}
                          {WorkData && WorkData.FormFilled == "approved" ?
                            <React.Fragment>
                              <tr>
                                <th scope="row">Actual RF Cost</th>
                                <td>{WorkData && WorkData.rfcost && WorkData.rfcost}</td>
                              </tr>
                              {WorkData && WorkData.Data_Inspection_type &&
                                WorkData.Data_Inspection_type == "Commit Work" ? "" :
                                <tr>
                                  <th scope="row">PRICE</th>
                                  <td>{WorkData && WorkData.price && WorkData.price}</td>
                                </tr>
                              }
                            </React.Fragment>
                            :
                            WorkData && WorkData.Data_Inspection_type && WorkData.Data_Inspection_type == "Commit Work" ? "" :
                              <tr>
                                <th scope="row">PRICE</th>
                                <td>  <input type="text" placeholder="price" value={this.state.price} className="form-control" name="price"
                                  onChange={(e) => this.pricechg(e)} /></td>
                              </tr>
                          }
                          {this.state.pricealert && this.state.pricealert != null && <tr>
                            <th scope="row"></th>
                            <td> <p style={{ color: "red" }}>{this.state.pricealert && this.state.pricealert}</p></td>
                          </tr>
                          }
                        </tbody>

                      </table>
                    )}
                    <div class="d-flex justify-content-center">
                      <button onClick={this.BilluploadChanges}
                        className="btn btn-sm btn-primary btn-sm " >   {this.state.isToggleOn ? "Close Bill Upload Images" : "View Bill Upload Images"}
                      </button>
                    </div>
                    {
                      this.state.BilluploadChanges === 1 && (
                        <div>
                          {this.state.img ? this.state.img : null}

                        </div>
                      )
                    }
                  </div>
                </div>
                <br />
                {WorkData && WorkData.FormFilled != "approved" && usertypelog.orderBy != 3 &&
                  this.state.BilluploadChanges === 0 && (<div className="row text-center" >
                    <div className="col">
                      <button className="btn btn-sm btn-success" onClick={() => this.Approval2(d)} disabled={this.state.approvebtnDisable} style={{ width: 180 }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin">&nbsp;</i> : null}APPROVAL</button> <br />&nbsp;
                    </div>
                    <div className="col">
                      <button className="btn btn-sm btn-danger" data-dismiss="modal" onClick={() => this.pricereset()} style={{ width: 180 }}>Close</button>
                    </div>
                  </div>
                  )
                }
              </ section >

            </div >
          }
        />
      )
    } else {
      return true
    }
  }
  Document = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={() => this.F_proofForm(d)}
      >
        View Form
      </button>
    );
  };

  F_proofForm = async d => {
    window.open(
      `/DFCars/CERTIFICATION_350_CheckPoints/${d.original.id}`,
      "_self"
    );
  };
  FormFill1 = (d) => {
    // console.log(d);
    return (
      <button
        class='btn btn-sm btn-danger'
        onClick={() => this.BAdSumaryData(d)}
      >
        Bad Summary
      </button>
    )
  }
  BAdSumaryData = async (d) => {
    let js = []
    // console.log(d, "d");
    js = d.checkjson
    let Inspection_technician = d.RFTechnicianName
    let Work_technician = d.second_technician
    let badselected = []
    let total_estimation_COst = 0
    let Selected_estimation_COst = 0
    let work_checklist = false
    if (js && js.length) {
      await js.map((ival, i) => {
        ival.map((jval) => {
          if (jval.status == "Repair" || jval.status == "Average") {
            badselected.push(jval)
            if (jval && jval.estimate && jval.estimate) {
              total_estimation_COst = parseInt(total_estimation_COst) + parseInt(jval.estimate)
            }
            if (jval && jval.estimate && jval.estimate && jval.work == true) {
              Selected_estimation_COst = parseInt(Selected_estimation_COst) + parseInt(jval.estimate)
            }
            if (jval.work == true && jval.workProcess == false) {
              work_checklist = true
            }
          }
        })
      })
      await this.setState({ data_come: d, badselected, Work_technician, Inspection_technician, total_estimation_COst, work_checklist, Selected_estimation_COst })
      await this.badsummarymodal(badselected, d)
      window.$("#badmodal").modal("show")
    }
  }
  badsummarymodal = async (badselected, d) => {
    let { usertypelog } = this.state
    let modaldata1 = []
    let { total_estimation_COst, work_checklist, Selected_estimation_COst, Inspection_technician, Work_technician } = this.state

    modaldata1.push(
      <>
        <div className="bgcolor" style={{ width: "100%", overflow: "auto" }}>
          <div class="set-form">
            <table id="myTable" class="table table-bordered">
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Remarks</th>
                <th>Estimate</th>
                <th>Work</th>
                {d && d.FormFilled && (d.FormFilled != "yes" && d.FormFilled != "no") && <th>Process</th>}
              </tr>
              {badselected.map((item, i) => {
                return (
                  <tbody ><tr>
                    <td className="text-left " >
                      {item.status && item.status == "Repair" ?
                        <p style={{ color: "red", fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                        :
                        <p style={{ fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                      }
                    </td>

                    <td>
                      {item.status && item.status == "Repair" ?
                        <label
                          style={{ color: "red", fontSize: "18px" }}
                        >{
                            item.status
                          }</label>
                        :
                        <label
                          style={{ fontSize: "18px" }}
                        >{
                            item.status
                          }</label>
                      }
                    </td>
                    <td>
                      {item.status && item.status == "Repair" ?
                        <p style={{ color: "red", fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                        :
                        <p style={{ fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                      }
                    </td>
                    <td>
                      {item.status && item.status == "Repair" ?
                        <p style={{ color: "red", fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                        :
                        <p style={{ fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                      }
                    </td>
                    <td>
                      <input
                        style={{ marginTop: "-1.2px" }}
                        className=""
                        id={`badselect${i}`}
                        checked={item && item.work && item.work == true ? true : false}
                        type='checkbox'
                        onClick={(e) => this.Selected_work(e, 'work', `badselect${i}`, true, i, item.name)}
                      />

                    </td>
                    {d && d.FormFilled && (d.FormFilled != "yes" && d.FormFilled != "no") &&
                      <td>
                        <input
                          style={{ marginTop: "-1.2px" }}
                          className=""
                          checked={item && item.workProcess && item.workProcess == true ? true : false}
                          type='checkbox'
                        />

                      </td>
                    }
                  </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div >
        {d.rf_incharge_name && usertypelog.orderBy == 3 ?
          d.approval == 0 &&
          <>
            -
          </>
          :
          <>
            {d.rf_incharge_name && usertypelog.orderBy != 3 && d.Total < 5000 ?
              d.approval == 0 &&
              <button
                type="button"
                style={{ minWidth: "250px" }}
                className="btn btn-sm btn-primary"
                onClick={() => this.alert_validation(this.Approval1, d)}
              >
                Approve
              </button>
              :
              d.rf_incharge_name && usertypelog.orderBy != 3 && d.Total >= 5000 && usertypelog.orderBy != 1 && d.managerapprove == 0 ?
                d.approval == 0 &&
                <button
                  type="button"
                  style={{ minWidth: "250px" }}
                  className="btn btn-sm btn-info"
                  onClick={() => this.alert_validation(this.Approval1_Mange, d)}
                >
                  Verify
                </button>
                :
                d.rf_incharge_name && usertypelog.orderBy != 3 && d.Total >= 5000 && usertypelog.orderBy != 1 && d.managerapprove == 1 ?
                  d.approval == 0 &&
                  <button
                    type="button"
                    style={{ minWidth: "250px" }}
                    className="btn btn-sm btn-success"
                  // onClick={() => this.alert_validation(this.Approval1, d)}
                  >
                    Verified
                  </button>
                  :
                  d.rf_incharge_name && usertypelog.orderBy != 3 && d.Total >= 5000 && usertypelog.orderBy != 2 && d.managerapprove == 0 ?
                    d.approval == 0 &&
                    <button
                      type="button"
                      style={{ minWidth: "250px" }}
                      className="btn btn-sm btn-info"
                      onClick={() => this.alert_validation(this.Approval1, d)}
                    >
                      Verify and Approval
                    </button>
                    :
                    d.rf_incharge_name && usertypelog.orderBy != 3 && d.Total >= 5000 && usertypelog.orderBy != 2 && d.managerapprove == 1 ?
                      d.approval == 0 &&
                      <button
                        type="button"
                        style={{ minWidth: "250px" }}
                        className="btn btn-sm btn-info"
                        onClick={() => this.alert_validation(this.Approval1, d)}
                      >
                        Approve
                      </button>
                      :
                      <div className="text-center"><b style={{ color: "#07278f" }}>Inspection Completed. Please Wait for RF Incharge Approval</b></div>
            }
          </>

        }

        {/* {d.rf_incharge_name ?
          d.approval == 0 &&
          <button
            type="button"
            style={{ minWidth: "250px" }}
            className="btn btn-sm btn-primary"
            onClick={() => this.alert_validation(this.Approval1, d)}
          >
            Approve
          </button>
          :
          <div className="text-center"><b style={{ color: "#07278f" }}>Inspection Completed. Please Wait for RF Incharge Approval</b></div>
        } */}
        <div className="text-right"><b>Total Work Estimation : {total_estimation_COst} RS</b></div>
        <div className="text-right"><b>Selected Work Estimation : {Selected_estimation_COst} RS</b></div>
        {Inspection_technician ? <div className="text-right"><b style={{ color: "#07278f" }}>Inspection Technician : {Inspection_technician}</b></div> : ""}
        {Work_technician ? <div className="text-right"><b style={{ color: "#fc4c0d" }}>Work Technician : {Work_technician}</b></div> : ""}
        <br />
        <br />
        <div className="text-right" style={{ color: work_checklist == false ? "#07278f" : "#fc4c0d" }}><b>{work_checklist == false ? "Technician Process Completed" : "Technician Process OnGoing"}</b></div>

      </>
    )
    this.setState({ modaldata1 })
  }
  Selected_work = async (e, name = null, id, value, index, data_find_name) => {
    let { badselected, data_come } = this.state
    let Selected_estimation_COst = 0
    if (data_come.process == 2 || data_come.approval != 0) {
      return false
    } else {
      var checkBox = document.getElementById(id);
      if (checkBox.checked == true) {
        badselected[index][`${name}`] = value
      } else {
        badselected[index][`${name}`] = false
      }
      let data = data_come.checkjson
      let w1 = await data.map(async (ival, i) => {
        await ival.map((jval) => {
          badselected.map((kval, k) => {
            if ((jval.status == "Repair" || jval.status == "Average") && jval.index == kval.index && jval.index == kval.index && jval.name == data_find_name) {
              jval.work = badselected[index][`${name}`]
            }
            // if ((jval.status == "Bad" || jval.status == "Average") && jval && jval.estimate && jval.estimate && jval.index == kval.index && kval.work == true) {
            //   total_estimation_COst = parseInt(total_estimation_COst) + parseInt(jval.estimate)
            // }
          })
        })
      })
      let w2 = await data.map(async (ival, i) => {
        await ival.map((jval) => {
          if ((jval.status == "Repair" || jval.status == "Average") && jval && jval.estimate && jval.estimate && jval.work == true) {
            // console.log(jval.estimate, "estimate");
            Selected_estimation_COst = Selected_estimation_COst + parseInt(jval.estimate)
          }

        })
      })
      await Promise.all(w1, w2)

      data_come.checkjson = data
      await this.setState({ badselected, Selected_estimation_COst })
      await this.badsummarymodal(badselected, data_come)
    }
  }
  reset1 = async (d) => {
    await this.setState({ modaldata1: [] })
  }

  Approval1 = async (d, text) => {
    let data = d
    let body = {};
    body.customerid = data.id;
    body.checkjson = JSON.stringify(data.checkjson)
    if (text == "yes") {
      body.approval = 2;
    } else {
      body.approval = 3;
    }
    body.status1 = 0
    try {
      let result = await cmsContent.updateMaster("tbl_checklist350", data.Formid, body);
      // console.log(result);
      await this.setState({ reloadfun: true })

      if (result) {
        window.$("#badmodal").modal("hide")
        await this.MainFunction()
      }
    } catch (error) {
      console.log(error);
    }
  };
  Approval1_Mange = async (d, text) => {
    let data = d
    let body = {};
    body.customerid = data.id;
    body.checkjson = JSON.stringify(data.checkjson)
    if (text == "yes") {
      body.managerapprove = 1;
    } else {
      body.managerapprove = 2;
    }
    try {
      let result = await cmsContent.updateMaster("tbl_checklist350", data.Formid, body);
      // console.log(result);
      await this.setState({ reloadfun: true })

      if (result) {
        window.$("#badmodal").modal("hide")
        await this.MainFunction()
      }
    } catch (error) {
      console.log(error);
    }
  };

  Approval2 = async d => {

    d = d.original
    let { pricealert, price, WorkData } = this.state
    if (!price) {
      if (WorkData.Data_Inspection_type == "Commit Work") { }
      else {
        let content = "* Please Enter Price"
        await this.setState({ pricealert: content })
        return true
      }
    }
    let groupArray1 = {}
    // groupArray1.userId = this.state.userId
    if (WorkData && WorkData) {
      groupArray1.data = JSON.stringify(WorkData)
    }
    groupArray1.process = 1
    if (price) {
      groupArray1.finalprice = price
    }
    else {
      groupArray1.finalprice = 0
    }


    // console.log(groupArray1, "WorkData_uPdate");
    this.setState({ approvebtnDisable: true })
    try {
      // console.log(groupArray1);
      let result = await cmsContent.CheckListAddProduct1("tbl_checklist350", groupArray1);
      if (result) {
        await this.setState({ approvebtnDisable: false })
        // if (this.state.reloadfun == true) {
        window.location.reload()
        // this.MainFunction()
        // }
      }
    } catch (error) {
      console.log(error);
      this.setState({ approvebtnDisable: false })
    }

  };

  edash = () => {
    let { data_for_f } = this.state
    return (<div className="">
      <div className="card">

        <div className="card-header">
          <div className="col" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Approved</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {data_for_f && data_for_f[0].today ? data_for_f[0].today : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(5)} >
                <Icon icon={x} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Reject</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {data_for_f && data_for_f[0].Reject ? data_for_f[0].Reject : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Waiting For Approval</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{data_for_f && data_for_f[0].fill ? data_for_f[0].fill : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Working</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{data_for_f && data_for_f[0].work ? data_for_f[0].work : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Work Completed</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{data_for_f && data_for_f[0].completed ? data_for_f[0].completed : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data3, workproc: false, FilterData: this.state.data3, filteredLoc: "", filteredMake: "" }) }
    if (val === 2) { this.setState({ Datas: this.state.data2, workproc: false, FilterData: this.state.data2, filteredLoc: "", filteredMake: "" }) }
    if (val === 3) { this.setState({ Datas: this.state.data4, workproc: true, FilterData: this.state.data4, filteredLoc: "", filteredMake: "" }) }
    if (val === 4) { this.setState({ Datas: this.state.data5, workproc: true, FilterData: this.state.data5, filteredLoc: "", filteredMake: "" }) }
    if (val === 5) { this.setState({ Datas: this.state.data6, workproc: true, FilterData: this.state.data6, filteredLoc: "", filteredMake: "" }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  makeFilter = async (d) => {
    let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

    //   console.log(d, "d")

    await this.setState({ filteredMake: d })

    if (d && d.length == 0 && filteredLoc.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredLoc.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
      var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
      var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
      var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }
  }
  locationFilter = async (d) => {
    let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
    await this.setState({ filteredLoc: d })

    if (d && d.length == 0 && filteredMake.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredMake.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length != 0) {
      var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
      var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
      var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }

  }
  clearFilter = async () => {
    await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
  }
  render() {
    const { modaldata1, data_come } = this.state;
    let { LocationOptions, MakeOptions } = this.state

    // console.log(this.state.Datas, "this.state.Datas")
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div class="modal fade" id="badmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true" >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content text-center" style={{ width: "auto" }}>
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle"> Bad Summary ({data_come && data_come.Points && data_come.Points})</h5>
                    <button type="button" class="close" onClick={() => { this.reset1() }} data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {modaldata1 && modaldata1}
                  </div>
                </div>
              </div>
            </div>
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Refurbishment</h1>
                  </div>
                  <div className="card-body">
                    {
                      this.state.FilterData && this.state.FilterData.length ?
                        <>
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-6">
                                <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                              </div>
                            </div>

                            .<div className="row">
                              <div className="col-sm-6">
                                <MultiSelect
                                  id="locationFilter"
                                  name="location"
                                  placeholder="Filter Location"
                                  options={LocationOptions}
                                  handleChange={d => this.locationFilter(d)}
                                  selectedService={this.state.filteredLoc}
                                />
                              </div>
                              <div className="col-sm-6">
                                <MultiSelect
                                  id="makefilter"
                                  name="make"
                                  placeholder="Filter Make"
                                  options={MakeOptions}
                                  handleChange={d => this.makeFilter(d)}
                                  selectedService={this.state.filteredMake}
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              <div className="col-sm-5" />
                              <div className="col-sm-2">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                  this.clearFilter()
                                }}> Clear filter</button>
                              </div>
                              <div className="col-sm-5"></div>
                            </div>
                          </div>
                          <br></br>
                        </>
                        :
                        ""

                    }

                    {this.state.loading
                      ?
                      <div className="text-center">
                        <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}></i>
                      </div>

                      :
                      this.state.Datas && this.state.Datas.length
                        ?
                        <Datatable
                          data={this.state.Datas}
                          columnHeading={this.state.workproc ? this.state.column2 : this.state.column}
                          excelColumn={this.state.workproc ? this.state.excelColumn2 : this.state.excelColumn}
                        />
                        :
                        <>
                          <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                          <br></br>
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default TLRefurbishment;
