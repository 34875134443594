import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import LoginModal from "../../../../components/Modal/Modal";
import SFAPDF from "../../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import moment from "moment";
import DfCarsAddEnquiry from "../Screen/DfCarsAddEnquiry";
import Updatefollowup from "../Updatestatus";
import swal from 'sweetalert';
import SingleSelect from "../../../../components/Form/SingleSelect";
import { Link } from "react-router-dom";
//import CheckListJSON from "./Dfcars350checklist.json"
import TopComments from '../../../CMSUser/TopComments';
import { ic_directions_walk } from 'react-icons-kit/md/ic_directions_walk'
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'
import { x } from 'react-icons-kit/iconic/x'
import { alarmClock } from 'react-icons-kit/metrize/alarmClock'
import Icon from "react-icons-kit";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../../../config/index";

class DfCarsEnquiry extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      statusbtn: false,
      StatusupdatealertVisible: false,
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      editid: null,
      isEdit: false,
      EnquiryData: [],
      statusArray: [],
      ftdval: false,
      EditData: {},
      StatusEditData: {},
      status: {},
      status1: {},
      status2: {},
      status3: {},
      sellerstatus1options: [{ label: "Yes, Interested in Buying", value: 0 },
      { label: "Yes, but not interested, Mark closed", value: 1 },
      { label: "Couldn’t speak to customer", value: 2 }
      ],
      sellerstatus1: {},
      sellerstatus2: {},
      sellerstatus3: {},
      sellerstatus2options: {},
      sellerstatus3options: {},
      EnquiryDatas: [],
      MakeOptions: [],
      coloropt: [],
      LogosEval: [],

      variantopt: [],
      statuschg1: false,
      statuschg2: false,
      status1options: [
        { value: 0, label: "Scheduled Follow up" },
        { label: "Scheduled Evaluation", value: 1 },
        { value: 2, label: "Mark Closed" },
        // {value:2,label:"Evaluated"},
      ],
      status2options: {},
      status3options: {},
      modelopt: [],
      Index: null,
      loading: false,
      userid: JSON.parse(localStorage.getItem("userlog")),
      customerId: JSON.parse(localStorage.getItem("userlog")).customerId,
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      CloseEnquiry: false,
      status: [{ label: "Scheduled Follow up", value: 1 }, { label: "Closed", value: 2 }, { label: "Evaluated", value: 3 }],
      LocationDetail: [],
      suggestion: [],
      suggestion1: [],
      dataof: [],
      remainder: [],
      data1: [],
      FullData1: [],
      closeddata: [],
      livedata: [],
      dateVisible: false,
      ftdDashDate: moment(new Date()).format("YYYY-MM-DD"),
      ftd: null,
      dashboard: [{ "live": 0, "close": 0, "today": 0, "MTD": 0, "remainder": 0 }],
      FullData: [],
      followJson1: [{
        date: "",
        status1: "",
        status2: "",
        status3: "",
        remarks: "",
        follow: ""
      }],
      followupDateVisible: false,
      followup: "",
      column: [
        {
          Header: "Form",
          accessor: "edit",
          width: 100,
          Cell: d => this.UpdateStatus(d)
        },
        {
          Header: "View Details",
          accessor: "view",
          width: 100,
          Cell: d => this.fullview(d)
        },
        {
          Header: "View status",
          accessor: "edit",
          width: 100,
          Cell: d => this.StatusView(d)
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Reg no",
          accessor: "Reg_no"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        // {
        //   Header: "Type",
        //   accessor: "type"
        // },
        {
          Header: "Enquiry date",
          accessor: "createdAt"
        },
        {
          Header: "Source",
          accessor: "sources",
        },

        // {
        //   Header: "Make",
        //   accessor: "make",
        //   Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        // },
        // {
        //   Header: "Model",
        //   accessor: "model",
        //   Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        // },
        // {
        //   Header: "Variant",
        //   accessor: "variant",
        //   Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        // },
        // {
        //   Header: "Color",
        //   accessor: "color",
        //   Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        // },
        // {
        //   Header: "Budget",
        //   accessor: "Budget"
        // },



        // {
        //   Header: "Status 1",
        //   accessor: "pendingStatus1",

        // },
        // {
        //   Header: "Status 2",
        //   accessor: "pendingStatus2",

        // },
        // {
        //   Header: "Status 3",
        //   accessor: "pendingStatus3",

        // },

        // {
        //   Header: "Next Action plan",
        //   accessor: "followup",
        //   Cell: d => this.followup(d)
        // },

        // {
        //   Header: "NAP status",
        //   accessor: "PendingStatus",
        //   Cell: d => this.NAPstatus(d)
        // },
        // {
        //   Header: "Status 2",
        //   accessor: "followstatus",
        //   //Cell: d => this.followstatus(d)
        // },
        // {
        //   Header: "View History",
        //   accessor: "edit",
        //   Cell: d => this.ViewLead(d)
        // },
        // {
        //   Header: "Convert to Prospect",
        //   accessor: "edit",
        //   Cell: d => this.ViewLead1(d)
        // },
        // {
        //   Header: "Close Enquiry",
        //   accessor: "type",
        //   Cell: d => this.CloseEnquiry(d)
        // }
      ],
      statuscolumn: [
        {
          Header: "Form",
          accessor: "edit",
          width: 100,
          Cell: d => this.UpdateStatus(d)
        },
        {
          Header: "View Details",
          accessor: "view",
          width: 100,
          Cell: d => this.fullview(d)
        },
        {
          Header: "View status",
          accessor: "edit",
          width: 100,
          Cell: d => this.StatusView(d)
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Reg no",
          accessor: "Reg_no"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        // {
        //   Header: "Type",
        //   accessor: "type"
        // },
        {
          Header: "Enquiry date",
          accessor: "createdAt"
        },
        {
          Header: "Status",
          accessor: "pendingStatus1"
        },
        {
          Header: "Source",
          accessor: "sources",
        }
      ],
      excelColumn: [

        {
          label: "Name",
          key: "name"
        },
        {
          label: "Reg no",
          key: "Reg_no"
        },
        {
          label: "Mobile",
          key: "mobile",
        },

        {
          label: "Enquiry date",
          key: "createdAt"
        },
        {
          label: "Source",
          key: "sources"
        },

      ],
      column1: [
        {
          Header: "Leads",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        // {
        //   Header: "Email_id",
        //   accessor: "email"
        // },
        {
          Header: "Type",
          accessor: "type"
        },
        {
          Header: "Enquriry date",
          accessor: "createdAt"
        },

        {
          Header: "Budget",
          accessor: "budget"
        },
        {
          Header: "View status",
          accessor: "edit",
          Cell: d => this.StatusView(d)
        },
        // {
        //   Header: "CustomerId",
        //   accessor: "customerid"
        // },
        {
          Header: "Print pdf",
          accessor: "print",
          Cell: d => this.viewpdf(d)
        },
        {
          Header: "Update status",
          accessor: "edit",
          Cell: d => this.UpdateStatus(d)
        }
        // {
        //   Header: "View History",
        //   accessor: "edit",
        //   Cell: d => this.ViewLead(d)
        // },
        // {
        //   Header: "Convert to Prospect",
        //   accessor: "edit",
        //   Cell: d => this.Viewbuylead(d)
        // }
        //   {
        //     Header: "Close Enquiry",
        //     accessor: "type",
        //     Cell: d => this.CloseEnquiry(d)
        //   }
      ],
      evalcolumn: [
        {
          Header: "Form",
          accessor: "id",
          width: 220,
          Cell: d => this.fFrom(d.original)
        },
        {
          Header: "Name",
          accessor: "name",
          className: "text-center"
          // clickable: d => this.name(d)

        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile),
          className: "text-center"
        }

      ],
      excelEvalColumn: [

        {
          label: "Name",
          key: "name",

        },
        {
          label: "Mobile",
          key: "mobile",
        }

      ],
      CustomerList: []
    };
  }
  fullview = (d) => {
    return (<center ><button type="button" className="btn btn-sm btn-success"
      data-toggle="modal" data-target={"#fullview"}
      onClick={() => this.ViewHistory1(d)}
    >Details</button></center>)
  }
  viewpdf = d => {
    console.log(d.original, 'view pdf');
    if (d.original.pendingStatus1 && d.original.pendingStatus2) {
      return (<button type="button" className="btn btn-warning btn-sm "
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const fproofid = await cmsContent.getFreedom("*", "tbl_dfcarz_enquiryform", `id=${d.original.id}`, 1, 1);
    console.log(fproofid)
    if (fproofid) {
      let fproofpdf = await cmsContent.autoterrace("tbl_dfcarz_enquiryform", fproofid.data[0].id);
      console.log(fproofpdf)
      if (fproofpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadauto/${fproofid.data[0].name}`, '_self')
        // window.open(`${ACCESS_POINT}/cmsContent/downloadbookconfirm/${fproofid.data[0].name}`, '_self')
      }
    }
  }
  fFrom = (d) => {
    if (d.F_formfilled == "no") {
      return <center><button className='btn btn-sm btn-primary' onClick={() => this.navigateF(d)}>Start Evaluation</button></center>
    } else {
      return true;
    }
  }
  navigateF = (d) => {
    window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.id}`, '_self')
  }
  followup = (d) => {
    let d1 = [];
    if (d.original.Next_Contact_date) {
      d1 = d.original.Next_Contact_date.split(" ")[0];
      ////console.log(d1)
      d1 = moment(d1).format("DD-MM-YYYY");
    }
    return (<span style={{ cursor: 'pointer', textDecoration: "underline" }} data-toggle="modal" data-target={"#followup"}
      onClick={() => this.Editdata(d)}
    >{d1}</span>)
  }

  Editdata = async (d) => {
    ////console.log(111)
    this.setState({ EditData: d.original, CustomerId: d.original.id, IsClose: false, isEdit: true, Index: d.index })
  }
  NAPstatus = (d) => {
    if (d.original.pendingStatus == 'Scheduled Follow up') {
      return (<button type="button" className="btn btn-success btn-sm"
        onClick={() => this.ChangeStatus1(d)}
      >Scheduled Follow up</button>)
    }
    else if (d.original.pendingStatus == "Evaluate") {
      return (<button type="button" className="btn btn-warning btn-sm"
        onClick={() => this.ChangeStatus1(d)}
      >Evaluated</button>)
    } else if (d.original.pendingStatus == "Closed") {
      return (<button type="button" className="btn btn-danger btn-sm"
        onClick={() => this.ChangeStatus1(d)}
      >Closed</button>)
    }
    ///Uncomment after excel status
    // if (d.original.pendingStatus == 'completed') {
    //   return (<button type="button" className="btn btn-success"
    //     onClick={() => this.ChangeStatus1(d)}
    //   >Completed</button>)
    // } else {
    //   return (<button type="button" className="btn btn-primary"
    //     onClick={() => this.ChangeStatus1(d)}
    //   >Pending</button>)
    // }
  }
  // resetStatus = () => {
  //   this.setState({ statuschg1: false })
  // }

  StatusView = (d) => {
    return (
      <center>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target={"#matchcarmodal"}
          onClick={() => this.Status(d)}
        >
          View
        </button>
      </center>
    );
  }
  Status = (d) => {
    let { statusArray, LogosEval } = this.state
    // console.log(d.original, 'd', LogosEval);
    let StatusEditData_Single = d.original
    // console.log(d.original.statusArray, "statusArray");
    // console.log(d.original.followup, "followup");
    if (d.original.statusArray) {
      statusArray = JSON.parse(d.original.statusArray)
    }
    if (d.original) {
      let geteval = LogosEval.filter(x => x.enquiry_id == d.original.id)
      if (geteval.length) {
        StatusEditData_Single.F_formfilled = geteval[0].F_formfilled
      }
    }
    this.setState({ StatusEditData: StatusEditData_Single, statusArray })
    this.form_filled(d)
  }
  form_filled = async (d) => {
    console.log(d.original, "d")
    let ans = d.original;
    let { usertypelog } = this.state
    let followJson1 = this.state.followJson1;
    if (usertypelog.orderBy == 8) {
      console.log(ans);
      if (ans.pendingStatus1) {

        let followJson1 = this.state.followJson1;
        let w1 = await this.state.sellerstatus1options.map(async (ival, i) => {
          console.log(ival);
          if (ival.label == ans.pendingStatus1) {
            console.log("ok");
            await this.handlestatusseller1(ival)
          }
        })
        await Promise.all(w1)
      }
      if (ans.pendingStatus2) {

        let w = await this.state.sellerstatus2options.map(async (ival, i) => {
          if (ival.label == ans.pendingStatus2) {
            await this.handlestatusseller2(ival)
          }
        })
        await Promise.all(w)
      }
    }
    else {
      if (ans.pendingStatus1) {
        let followJson1 = this.state.followJson1;
        let w1 = await this.state.status1options.map(async (ival, i) => {
          console.log(ival);
          if (ans.pendingStatus1 == "Schedule walkin") {
            ans.pendingStatus1 = "Scheduled Evaluation"
          }
          if (ival.label == ans.pendingStatus1) {
            console.log("ok");
            await this.handlestatus1(ival)
          }
        })
        await Promise.all(w1)
      }
      if (ans.pendingStatus2) {
        let w = await this.state.status2options.map(async (ival, i) => {
          if (ival.label == ans.pendingStatus2) {
            await this.handlestatus2(ival)
          }
        })
        await Promise.all(w)
      }
      if (ans.Next_Contact_date) {
        this.setState({
          followup: ans.Next_Contact_date
        })
      }
    }

  }
  status1 = () => {
    let { usertypelog } = this.state
    if (usertypelog.orderBy == 8) {
      return (
        <div>
          <SingleSelect
            placeholder="Select..."
            options={this.state.sellerstatus1options}
            handleChange={d => this.handlestatusseller1(d)}
            selectedService={this.state.sellerstatus1}
          />
        </div>)
    }
    else {
      return (
        <div>
          <SingleSelect
            placeholder="Select..."
            options={this.state.status1options}
            handleChange={d => this.handlestatus1(d)}
            selectedService={this.state.status1}
          />

        </div>)
    }
  }
  status2 = () => {
    if (this.state.usertypelog.orderBy == 8) {
      return (
        <div>
          <SingleSelect
            placeholder="Select..."
            options={this.state.sellerstatus2options}
            handleChange={d => this.handlestatusseller2(d)}
            selectedService={this.state.sellerstatus2}
          />
        </div>)
    }
    else {
      return (
        <div>
          <SingleSelect
            placeholder="Select..."
            options={this.state.status2options}
            handleChange={d => this.handlestatus2(d)}
            selectedService={this.state.status2}
          />
        </div >
      )
    }
  }
  status3 = () => {
    if (this.state.usertypelog.orderBy == 8) {
      return (
        <div>
          <SingleSelect
            placeholder="Select..."
            options={this.state.sellerstatus3options}
            handleChange={d => this.handlestatusseller3(d)}
            selectedService={this.state.sellerstatus3}
          />
        </div>)
    } else {
      return (
        <div>
          <SingleSelect
            placeholder="Select..."
            options={this.state.status3options}
            handleChange={d => this.handlestatus3(d)}
            selectedService={this.state.status3}
          />
        </div >
      )
    }
  }
  handlestatusseller1 = async e => {
    this.setState({ sellerstatus1: e, sellerstatus2: {}, sellerstatus3: {}, status3visible: false });
    if (e.label == "Yes, Interested in Buying") {
      this.setState({
        status2visible: true,
        sellerstatus2options: [
          { label: "Schedule walkin", value: 0 },
          { label: "Schedule follow up", value: 0 }
        ]
      })
    } else if (e.label == "Yes, but not interested, Mark closed") {
      this.setState({
        status2visible: true,
        sellerstatus2options: [{ label: "Fake Lead", value: 0 },
        { label: "Customer Bought another car", value: 1 },
        { label: "Customer not picking the call", value: 2 },
        { label: "Desired car not available", value: 3 },
        { label: "price issue", value: 4 },
        { label: "Other", value: 5 }],
        status3visible: false, sellerstatus3: {}
      })
    } else if (e.label == "Couldn’t speak to customer") {
      this.setState({
        status2visible: true,
        sellerstatus2options: [{ label: "Schedule followup", value: 0 },
        { label: "Mark closed", value: 1 }
        ], status3visible: false, sellerstatus3: {}, sellerstatus3options: {}
      })
    }
  };
  handlestatusseller2 = async e => {
    this.setState({ sellerstatus2: e, sellerstatus3: {} });
    if (e.label == "Mark closed") {
      this.setState({
        status3visible: true, sellerstatus3options: [{ label: "Fake Lead", value: 0 },
        { label: "Customer Bought another car", value: 1 },
        { label: "Customer not picking the call", value: 2 },
        { label: "Desired car not available", value: 3 },
        { label: "price issue", value: 4 },
        { label: "Other", value: 5 }
        ], sellerstatus3: {}
      })
    } else {
      this.setState({ status3visible: false })
    }
  };
  handlestatusseller3 = async e => {
    this.setState({ sellerstatus3: e });
  };

  handlestatus1 = async e => {
    await this.setState({ status1: e, status2: {} });
    if (e.label == "Scheduled Follow up") {
      await this.setState({
        followupDateVisible: true,
        status2visible: true,
        followup: "",
        status2options: [
          { label: "Under Follow up", value: 1 },
          { label: " Ringing no response", value: 2 },
          { label: "Researching", value: 3 },
          { label: "  Not attempted", value: 4 }
        ],
        status3options: [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }], status3visible: true
      })
    } else if (e.label == "Scheduled Evaluation") {
      await this.setState({
        status2visible: true, status2options: [{ label: "Appointment taken", value: 1 }],
        status3visible: false, followupDateVisible: true, followup: "",
      })
    } else if (e.label == "Mark Closed") {
      await this.setState({
        status2visible: true, status2options: [{ label: " Plan dropped", value: 0 },
        { label: "Non contactable", value: 1 },
        { label: "Lost", value: 2 },
        { label: "Wrong capture", value: 3 },
        { label: "No response", value: 4 },
        { label: "Huge Price Gap", value: 5 },
        ], status3visible: false, followupDateVisible: false, followup: "",
      })
    } else if (e.label == "Evaluated") {
      this.setState({
        status2visible: true, status2options: [{ label: " Trade in", value: 0 },
        { label: "Follow-up", value: 1 },
        { label: "Researching", value: 2 },
        { label: "Postponned", value: 3 },
        { label: "Dropped", value: 4 },
        { label: "Not attempted", value: 5 },
        { label: "Non contactable", value: 6 },
        { label: "Price not offered", value: 7 },
        { label: "Huge Price gap", value: 8 },
        { label: "Lost", value: 9 },
        ], status3visible: false
      })
      console.log(this.state.status2options);
    }
    // else{this.setState({status2visible:false,status3visible:false})}
  };

  handlestatus2 = async e => {
    this.setState({ status2: e });
    if (e.label == "Lost") {
      this.setState({
        status3visible: true, status3options: [{ label: " Co dealer", value: 0 },
        { label: "Competition", value: 1 },
        { label: "End user", value: 2 },
        { label: "Broker", value: 3 },
        { label: "Not known", value: 4 }
        ]
      })
    } else if (e.label == "Non contactable") {
      this.setState({
        status3visible: true, status3options: [{ label: "RNR-3", value: 0 },
        { label: "Wrong number", value: 1 },
        ]
      })
    } else {
      // this.setState({status3visible:false})
    }
  };
  handlestatus3 = async e => {
    this.setState({ status3: e });
  }
  ChangeStatus1 = async (d) => {
    try {
      ////console.log('d', d.original)
      // //console.log(d.original.id);

      let result = await CmsContent.getFreedom(
        `tbl_dfcars_comments.*,tbl_UserType.usertype,tbl_user_web.userName as Name,tbl_user_web.id as UserID`,
        `tbl_dfcars_comments,tbl_user_web,tbl_MaptoLocation,tbl_UserType`,
        `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
        1,
        `tbl_dfcars_comments.id DESC`,
      );
      if (result.data.length) {

        let CommentData = result.data[0];
        ////console.log(CommentData);

        let arr = {};
        if (d.original.pendingStatus1 == "Scheduled Follow up") {
          arr.status = "Evaluate"
        }
        else if (d.original.pendingStatus1 == "Evaluated") {
          arr.status = "Closed"
        }
        else if (d.original.pendingStatus1 == "Closed") {
          arr.status = "Closed"
        }
        // if (d.original.pendingStatus == 'pending') {
        //   arr.status = 'completed';
        // } else {
        //   arr.status = 'pending';
        // }

        const result1 = await CmsContent.updateMaster(
          "tbl_dfcars_comments",
          CommentData.id,
          arr
        );
        if (result1) {
          ////console.log(result1);
          let newData = [...this.state.EnquiryData];
          newData[d.index].pendingStatus = arr.status;
          this.setState({
            Data: newData
          })
        }

      }

    } catch (error) {
      //console.log(error);
    }
  }
  async componentDidMount() {
    try {

      this.mainFun()
      let decr = this.Decrypt(localStorage.getItem('getcar'))
      let allOpt = JSON.parse(decr)
      if (allOpt) {
        this.setState({
          MakeOptions: allOpt.makeopt,
          coloropt: allOpt.coloropt,
          variantopt: allOpt.variantopt,
          modelopt: allOpt.modelopt
        })
      }
      if (localStorage.getItem('filled')) {
        console.log('form filledddd')
        window.$('#status').modal('show')
      }

    } catch (error) {
      console.log(error);
    }

  }
  mainFun = async () => {
    try {
      const { usertypelog } = this.state;
      let user = JSON.parse(localStorage.getItem(`userlog`));
      this.setState({ loading: true, EnquiryDatas: [] })


      // let typeLabel = usertypelog.orderBy == 9 ? "seller" : "buyer";

      // let result = await CmsContent.getFreedom(
      //   `*`,
      //   `tbl_dfcarz_customers`,
      //   `type="${typeLabel}"`,
      //   "id",
      //   "customer"
      // );
      // let Prospect = await CmsContent.getFreedom(
      //   `*`,
      //   `tbl_dfcarz_prosperities`,
      //   `type="${typeLabel}"`,
      //   "id",
      //   "id"
      // );

      // let ProspectData = [];
      // let ProspectData1 = [];
      // let Wait = await Prospect.data.map(async (ival, i) => {

      //   let Customer = await CmsContent.getFreedom(
      //     `*`,
      //     `tbl_dfcarz_customers`,
      //     `type="${typeLabel}" and prospectid=${ival.id}`,
      //     "id",
      //     "customer"
      //   );
      //   if (!Customer.data.length) {
      //     ProspectData.push(ival.name);
      //     ProspectData1.push(ival.mobile);
      //   }

      // });

      // await Promise.all(Wait);

      // let suggestion = [];
      // let suggestion1 = [];
      // if (result) {
      //   result.data.map((ival, i) => {
      //     if (ival.customer !== null) {
      //       suggestion.push(ival.customer);
      //       suggestion1.push(ival.mobile)
      //     }
      //   });
      // }
      // let Wait2 = await ProspectData.map(async (ival, i) => {
      //   if (ival !== null) {
      //     suggestion.push(ival)
      //     suggestion1.push(ival)
      //   }
      // })
      // let Wait3 = await ProspectData1.map(async (ival, i) => {
      //   if (ival !== null) {
      //     suggestion1.push(ival)
      //   }
      // })
      // await Promise.all(Wait2);
      // await Promise.all(Wait3);

      // this.setState({ suggestion, suggestion1, CustomerList: result.data });

      // let Product = [];

      let logos = await CmsContent.getDfcarzEnquiry(user.id)
      console.log(logos, 'API');
      let data = []
      // console.log(data.length);

      if (logos.user[0].orderBy == 9) {
        const result = await data.filter(word => word.type == "seller");
        data = result
      } else if (logos.user[0].orderBy == 8) {
        const result = await data.filter(word => word.type == "buyer");
        data = result


        let s1 = [{ label: "Yes, Interested in Buying", value: "Yes, Interested in Buying" },
        { label: "Yes, but not interested, Mark closed", value: "Yes, but not interested, Mark closed" },
        { label: "Couldn’t speak to customer", value: "Couldn’t speak to customer" },
        ]

        this.setState({ options: s1 })
      }
      let dashboard = logos.status

      let fulldata = []
      if (logos.statusdata[0].fulldata && logos.statusdata[0].fulldata.length > 0) {
        fulldata = logos.statusdata[0].fulldata
      }
      // console.log(logos, "API")
      this.setState({
        EnquiryData: fulldata.length ? fulldata.filter(e => e.filterfollowup == true) : [],
        livedata: fulldata.length ? fulldata.filter(e => e.filterfollowup == true) : [],
        closeddata: fulldata.length ? fulldata.filter(e => e.filterclosed == true) : [],
        data1: fulldata.length ? fulldata.filter(e => e.filterToday == true) : [],
        // dataof: fulldata.length ? fulldata.filter(e => e.filtermtd == true) : [],
        dataof: fulldata.length ? fulldata.filter(e => (e.filtermtd || e.filterfollowup || e.filterclosed) == true) : [],
        evaldata: logos.eval.length ? logos.eval.filter(e => e.filterEvalution == true) : [],
        ftddatas: logos.statusdata[0].ftd,
        LogosEval: logos.eval.length ? logos.eval : [],

        dashboard,
        UserId: logos.user[0].id,
        remainder: logos.statusdata[0].remainder,
        orberby: logos.user[0].orderBy,
        // Prosdata: logos.Prosdata,
        loading: false
      });

    } catch (error) {
      await this.setState({ loading: false })
      console.log(error);
    }


  }
  // ftd = async (d) => {
  //   console.log(d);
  //   const { data, FullDate, FullDate1 } = this.state;
  //   let v4 = 0;
  //   let data1 = []
  //   let datas = await data.map((ival, i) => {
  //     if (ival.Next_Contact_date) {
  //       let date = ival.Next_Contact_date.split(" ");
  //       console.log(date[0], d);
  //       if (date[0] == d) {
  //         v4 = ++v4;
  //         data1.push(ival)
  //       }
  //     }
  //   })
  //   if (v4 == 0) {

  //   }
  //   await this.setState({ ftd: v4, ftdEdata: data1, ftddate: d })
  //   setTimeout(() => {
  //     this.scroll.scrollTo({ y: 500 })
  //   }, 1000);

  // }
  ViewLead = d => {
    return (
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => this.ViewHistory(d)}
      >
        View
      </button>
    );
  };

  CloseEnquiry = d => {
    return (
      <button
        type="button"
        className="btn btn-danger btn-sm"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.ClosethisEnquiry(d)}
      >
        Close
      </button>
    );
  };
  Viewbuylead = d => {
    return (
      <button
        type="button"
        className="btn btn-success btn-sm"
        onClick={() => this.ViewHistory2(d)}
      >
        Add
      </button>
    );
  };
  ViewLead1 = d => {
    //console.log(d);
    if (d.original.pendingStatus1 == "Scheduled Follow up") {
      return (
        <button
          type="button"
          className="btn btn-success btn-sm"
          onClick={() => this.ViewHistory2(d)}
        >
          Add
        </button>
      );
    }
    else
      if (d.original.pendingStatus1 == "Scheduled Follow up") {
        return (
          <span className="label" style={{ backgroundColor: "white" }}></span>)
      }
      else {
        return (
          <span className="label" style={{ backgroundColor: "white" }}></span>)
      }
  };

  ClosethisEnquiry = async d => {
    this.setState({ CloseEnquiry: true });
    this.setState({ Index: d.index, EditData: d.original });
  };

  UpdateStatus = d => {
    if (this.state.usertypelog.orderBy == 8) {
      return (
        <center>
          <button
            type="button"
            className="btn btn-warning btn-sm"
            data-toggle="modal"
            data-target={"#status"}
            onClick={() => this.F_proofForm(d)}
          >
            Edit Form
          </button>
        </center>
      );
    }
    else {
      return (
        <center>
          <button
            type="button"
            className="btn btn-warning btn-sm"
            data-toggle="modal"
            data-target={"#status"}
            onClick={() => this.ViewHistory1(d)}
          >
            Edit
          </button>
        </center>

      );
    }
  };

  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  };

  ChangeStatus = async (data, id) => {
    try {
      let subgroupArray = {};
      //subgroupArray.enquiry_status = "inactive";
      subgroupArray.prosid = id
      const result = await CmsContent.updateMaster(
        "tbl_dfcarz_enquiryform",
        data.id,
        subgroupArray
      );

      // //console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  ViewHistory2 = async d => {
    let data = d
    // console.log(d);
    let groupArray2 = {};
    groupArray2.name = data.name;
    groupArray2.mobile = data.mobile;
    groupArray2.email = data.email;
    groupArray2.status = "active";
    groupArray2.companyid = data.companyid;
    groupArray2.enquiry_id = data.id;
    groupArray2.userid = data.evaluatorid;
    groupArray2.type = data.type;
    groupArray2.model = data.Model;
    groupArray2.make = data.Make;
    groupArray2.variant = data.variant;
    //groupArray.budget = data.Budget;
    groupArray2.color = data.color;
    groupArray2.prospective_status = "active";
    groupArray2.location = data.location;
    groupArray2.salesid = data.salesid;
    groupArray2.variant = data.variant;
    groupArray2.evaluatorid = data.evaluatorid;
    groupArray2.reg_no = data.Reg_no;
    groupArray2.year = data.yom;
    // let DaTE = new Date();
    // groupArray2.followup = moment(DaTE).format("YYYY-MM-DD");
    console.log(groupArray2);
    try {
      const history = await CmsContent.addMaster(
        "tbl_dfcarz_prosperities",
        groupArray2
      );
      // console.log(history);
      if (history) {
        console.log(history, 'history');
        const prosid = history.data.insertId;
        this.ChangeStatus(data, prosid);
      }
      // if (history) {
      //   swal(`${data.name} has been converted to a prospect successfully`);
      //   // //console.log(history);

      //   // const result = await CmsContent.updateMaster(
      //   //   "tbl_dfcarz_enquiryform",
      //   //   data.id,
      //   //   subgroupArray
      //   // );
      //   // //console.log(result);
      //   let newData = [];
      //   this.state.EnquiryData.map((ival, i) => {
      //     if (ival.id != data.id) {
      //       newData.push(ival);
      //     }
      //   });
      //   // //console.log(d.index)
      //   //newData[d.index].intabel=1;
      //   this.setState({
      //     EnquiryData: newData
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };
  F_proofForm = async (d) => {

    // console.log("id:", d.original.id, "customer id :", d.original.customerid);
    window.open(`/DFCars/Autoterrace_enquiry_form/${d.original.id}`, '_self')
  }
  Autoterrace = () => {
    window.open(`/DFCars/Autoterrace_enquiry_form/`, '_self')

  }
  ViewHistory1 = async d => {
    this.setState({ Index: d.index });
    // console.log(d.original, 'dd');
    this.setState({ isEdit: true, EditData: d.original });
  };

  ViewHistory = async d => {
    window.open(`/DFCars/DfCarsViewhistory/${d.original.id}`);
  };
  reset = async () => {
    console.log("reset");
    await localStorage.removeItem('filled')
    await localStorage.setItem('clearForm', 'yes')
    await this.setState({
      isEdit: false,
      Editdata: null,
      CustomerId: null,
      EditData: {},
      FullData: null,
      Index: null,
      IsClose: null
    }); 
  };

  edash = () => {
    return (

      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className='container-fluid'>
            <div className="row col-12">
              <div className="col-sm" ><center>
                {this.state.usertypelog.orderBy !== 8
                  ?
                  <div className="col-sm-4">
                    <LoginModal
                      buttonTitle="Add Enquiry"
                      title="Enquiry"
                      id="status"
                      extraStyle={{ width: '100%' }}
                      //extraClass="btn btn-primary"
                      reset={this.reset}
                      bodyText={
                        <DfCarsAddEnquiry
                          EnquiryData={this.state.EnquiryData}
                          these={this.changeModelstate}
                          isEdit={this.state.isEdit}
                          EditData={this.state.EditData}
                          Index={this.state.Index}
                          CloseEnquiry={this.state.CloseEnquiry}
                          LocationDetail={
                            this.state.LocationDetail.length
                              ? this.state.LocationDetail[0].id
                              : null
                          }
                          // Prosdata={this.state.Prosdata}
                          // suggestion={this.state.suggestion}
                          // suggestion1={this.state.suggestion1}
                          CustomerList={this.state.CustomerList}
                          mainFun={this.mainFun}
                        />
                      }
                    />
                  </div>
                  :
                  <div className="col-sm-3"> <button className="btn btn-primary btn-sm" onClick={this.Autoterrace}  > Add Enquiry</button>
                  </div>}

                {/* <Link to={'/DFCars/Autoterrace_enquiry_form/', '_self'} className="btn btn-primary">Add Enquiry</Link> */}
              </center>
              </div>
            </div><br />
            <div className="row mt-2 text-center">
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                  <Icon icon={calendar} style={{ color: '#2A9D8F' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].MTD || this.state.dashboard[0].live || this.state.dashboard[0].Closed ? this.state.dashboard[0].MTD + this.state.dashboard[0].live + this.state.dashboard[0].Closed : 0}</b></h1>
                  </div>
                </div>
              </div>
              {/* <div className="col" >
                <div className="dashboardCard1"
                  onClick={() => this.onClk(10)}
                >
                  <Icon icon={calendar} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >FTD Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.ftd && this.state.ftd != null && this.state.ftdval == true ? this.state.ftd : this.state.ftdval == false && this.state.dashboard[0].ftd}</b></h1>
                  </div>
                  <div>
                    <h1 className="bold mt-4 mb-3"><b><input type="date" defaultValue={this.state.ftdDashDate} className="form-control" onChange={this.cng} name="dateval"></input></b></h1>
                  </div>
                </div>
              </div> */}
              {/* {this.state.usertypelog.orderBy == 9 && this.state.evaldata && this.state.evaldata.length ?
                <div className="col" >
                  <div className="dashboardCard1" onClick={() => this.onClk(7)}>
                    <Icon icon={calendar} style={{ color: 'red' }} size={35} /><br />
                    <h4 className="mt-2 black" >Scheduled Evaluation</h4>
                    <div >
                      <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].Evaluation ? this.state.dashboard[0].Evaluation : 0}</b></h1>
                    </div>
                  </div>
                </div>
                : null} */}
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(1)}>
                  <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} /><br />
                  <h4 className="mt-2 black" > Scheduled Followup</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].live ? this.state.dashboard[0].live : 0}</b></h1>
                  </div>
                </div>
              </div>


              {/* <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                  <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >Today's Followup</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].today ? this.state.dashboard[0].today : 0}</b></h1>
                  </div>
                </div>
              </div> */}
              {/* <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(5)}>
                  <Icon icon={alarmClock} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black">Reminders</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].remainder ? this.state.dashboard[0].remainder : 0}</b></h1>
                  </div>
                </div>
              </div> */}
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(6)}>
                  <Icon icon={x} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >Closed Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].Closed ? this.state.dashboard[0].Closed : 0}</b></h1>
                  </div>
                </div>
              </div>
              {this.state.usertypelog.orderBy == 9 ?
                <div className="col" >
                  <div className="dashboardCard1" onClick={() => this.onClk(7)}>
                    <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} /><br />
                    <h4 className="mt-2 black" >Scheduled Evaluation</h4>
                    <div >
                      <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].Evaluation ? this.state.dashboard[0].Evaluation : 0}</b></h1>
                    </div>
                  </div>
                </div>
                : null}
            </div>
            {/* <div className="row col-12 mt-2 text-center">
              {this.state.usertypelog.orderBy == 9 ?
                <div className="col-sm-12" >
                  <div className="dashboardCard1" onClick={() => this.onClk(7)}>

                    <h4 className="mt-2 black" >SCHEDULED EVALUATION</h4>
                    <div >
                      <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].Evaluation ? this.state.dashboard[0].Evaluation : 0}</b></h1>
                    </div>
                  </div>
                </div> : null}
            </div> */}
          </div >
        </div >
      </div >)
  }
  cng = async (e) => {
    this.setState({ ftdval: true })
    console.log(e.target.value);
    let { usertypelog } = this.state
    // console.log(usertypelog.orderBy);
    let d = e.target.value
    let d1 = {}
    d1.date = e.target.value
    d1.orderBy = usertypelog.orderBy
    // d1.id = this.state.UserId
    d1.locationId = this.state.LocationID
    // console.log(d1, 'd1');
    let result = await cmsContent.GetFTD(d, d1)
    if (result.data.length) {
      // console.log(result);
      let v3 = 0;
      let data2 = []
      result.data.map((ival, i) => {
        if (ival.createdAt) {
          let date11 = new Date(ival.createdAt)
          let d1 = date11.getDate()
          let m1 = date11.getMonth() + 1
          let y1 = date11.getFullYear()
          if (m1 < 10) {
            m1 = "0" + m1
          }
          let date2 = y1 + "-" + m1 + "-" + d1
          if (d && date2) {
            // console.log("enterr");
            v3 = ++v3;
          }
        }
      })
      console.log(v3 && v3);
      await this.setState({ ftd: v3, EnquiryDatas: result.data, ftdDashDate: d })
    }
    else {
      // console.log("olkkkkkkk");
      let data = []
      await this.setState({ ftd: "0", EnquiryDatas: data })
      console.log(this.state.ftd, "ftddd");
    }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });

  }
  onClk = (val) => {
    if (val === 1) { this.setState({ EnquiryDatas: this.state.livedata, dateVisible: false, Eval: false, statusbtn: false }) }
    // if (val === 2) {this.setState({ dateVisible: true, EnquiryData: [] })}
    if (val === 3) { this.setState({ EnquiryDatas: this.state.dataof, dateVisible: false, Eval: false, statusbtn: true }) }
    if (val === 4) { this.setState({ EnquiryDatas: this.state.data1, dateVisible: false, Eval: false, statusbtn: false }) }
    if (val === 5) { this.setState({ EnquiryDatas: this.state.remainder, dateVisible: false, Eval: false, statusbtn: false }) }
    if (val === 6) { this.setState({ EnquiryDatas: this.state.closeddata, dateVisible: false, Eval: false, statusbtn: false }) }
    if (val === 7) { this.setState({ EnquiryDatas: this.state.evaldata, dateVisible: false, Eval: true, statusbtn: false }) }
    if (val === 10) { this.setState({ EnquiryDatas: this.state.ftddatas, dateVisible: false, Eval: false, statusbtn: false }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  StatusReset = () => {
    this.setState({
      statuschg1: false, status2visible: false, status2: "",
      status1: null, StatusEditData: {}, status3visible: false,
      sellerstatus1: {}, sellerstatus2: {}, sellerstatus3: {}
    })
  }
  StatusUpdate = async () => {
    // console.log("StatusUpdateUser", this.state.StatusEditData);
    if (this.state.usertypelog.orderBy == 8) {

      const { sellerstatus1, sellerstatus2, sellerstatus3, followup, statusArray } = this.state
      let groupArray = {}
      let Userlog = JSON.parse(localStorage.getItem("userlog"))
      if (statusArray && statusArray.length) {
        let obj = {}
        obj.status1 = sellerstatus1 && sellerstatus1.label == "Scheduled Evaluation" ? 'Schedule walkin' : sellerstatus1.label;
        obj.status2 = sellerstatus2 ? sellerstatus2.label : null;
        obj.status3 = sellerstatus3 ? sellerstatus3.label : null;
        if (followup) {
          obj.Next_Contact_date = followup && followup.length && moment(followup).format('YYYY-MM-DD')
        }
        else {
          obj.Next_Contact_date = "-"
        }
        obj.sino = statusArray.length + 1
        obj.userName = Userlog.userName
        if (obj) {
          statusArray.unshift(obj)
        }
      }
      console.log(statusArray, "statusArray ");

      let id = this.state.StatusEditData.id
      if (sellerstatus1) {
        groupArray.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
      }
      if (sellerstatus2) {
        groupArray.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
      }
      if (sellerstatus3) {
        groupArray.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
      }
      if (followup) {
        groupArray.Next_Contact_date = followup && followup.length ? followup : null
      }
      this.setState({ statusbtnDisable: true })
      console.log(groupArray, id)
      try {
        let result = await CmsContent.UpdateSaleEnquiryadd(
          "tbl_dfcarz_enquiryform",
          id,
          groupArray
        );
        if (result) {
          console.log(result)
        }
        await this.setState({
          statusbtnDisable: false,
          answerJson: "",
          json: "",
          StatusupdatealertVisible: true,
          Statusupdataetextalert: "Your Status have been Updated successfully",
          Statusupdatecolor: "success"
        });
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        setTimeout(() => { this.setState({ StatusupdatealertVisible: false }) }, 3000);

      } catch (error) {
        console.log(error);
      }
    }
    else {
      const { status1, status2, status3, followup, statusArray } = this.state
      let groupArray = {}
      let Userlog = JSON.parse(localStorage.getItem("userlog"))
      if (statusArray && statusArray.length) {
        let obj = {}
        obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
        obj.status2 = status2 ? status2.label : null;
        obj.status3 = status3 ? status3.label : null;
        if (followup) {
          obj.Next_Contact_date = followup && followup.length && moment(followup).format('YYYY-MM-DD')
        }
        else {
          obj.Next_Contact_date = "-"
        }
        obj.sino = statusArray.length + 1
        obj.userName = Userlog.userName
        if (obj) {
          statusArray.unshift(obj)
        }
      } else if (statusArray && statusArray.length == 0) {
        let obj = {}
        obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
        obj.status2 = status2 ? status2.label : null;
        obj.status3 = status3 ? status3.label : null;
        if (followup) {
          obj.Next_Contact_date = followup && followup.length && moment(followup).format('YYYY-MM-DD')
        }
        else {
          obj.Next_Contact_date = "-"
        }
        obj.sino = statusArray.length + 1
        obj.userName = Userlog.userName
        if (obj) {
          statusArray.unshift(obj)
        }
      }
      else {
        statusArray = []
        let obj = {}
        obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
        obj.status2 = status2 ? status2.label : null;
        obj.status3 = status3 ? status3.label : null;
        if (followup) {
          obj.Next_Contact_date = followup && followup.length && moment(followup).format('YYYY-MM-DD')
        }
        else {
          obj.Next_Contact_date = "-"
        }
        obj.sino = 1
        obj.userName = Userlog.userName
        if (obj) {
          statusArray.unshift(obj)
        }
      }

      // console.log(statusArray, "statusArray else ");
      groupArray.statusArray = JSON.stringify(statusArray);
      groupArray.pendingStatus1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
      // groupArray.pendingStatus1 = status1 && status1.label;
      groupArray.pendingStatus2 = status2 ? status2.label : null;
      groupArray.pendingStatus3 = status3 ? status3.label : null;
      groupArray.Next_Contact_date = followup && followup.length && moment(followup).format('YYYY-MM-DD')
      console.log("groupArray", groupArray);
      let id = this.state.StatusEditData.id
      this.setState({ statusbtnDisable: true })
      try {
        const result = await cmsContent.UpdateEnquiryadd(
          id,
          groupArray
        );
        this.setState({
          // data: newData,
          statusbtnDisable: false,
          StatusupdatealertVisible: true,
          Statusupdataetextalert: "Your Status have been Updated successfully",
          Statusupdatecolor: "success",
          errorColourName: "",
          status1: "",
          status2: "",
          status3: "",
          modelPresent: false
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

        setTimeout(() => this.setState({ StatusupdatealertVisible: false }), 3000);
      } catch (error) {
        console.log(error);
      }
    }
    this.mainFun()
  }

  changeHandler = (e, name) => {
    console.log(e.target.value, "e")
    this.setState({ [name]: e.target.value })
  }
  render() {
    // console.log(moment(new Date()).format("DD-MM-YYYY"));
    let { loading, StatusEditData, EditData, statusArray, statusbtn } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Enquiry Report</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {/* <div className="row form-group"> */}
                    {/* {this.state.usertypelog.orderBy !== 8 ? <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Enquiry"
                          id="status"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          bodyText={
                            <DfCarsAddEnquiry
                              EnquiryData={this.state.EnquiryData}
                              these={this.changeModelstate}
                              isEdit={this.state.isEdit}
                              EditData={this.state.EditData}
                              Index={this.state.Index}
                              CloseEnquiry={this.state.CloseEnquiry}
                              LocationDetail={
                                this.state.LocationDetail.length
                                  ? this.state.LocationDetail[0].id
                                  : null
                              }
                              suggestion={this.state.suggestion}
                              suggestion1={this.state.suggestion1}
                              CustomerList={this.state.CustomerList}
                            />
                          }
                        />
                      </div> : <div className="col-sm-3" > <Link to={'/DFCars/Autoterrace_enquiry_form/'} className="btn btn-primary">Add Enquiry</Link> </div>} */}
                    {/* </div> */}

                    {this.state.EditData != null && <div>
                      <LoginModal
                        title={EditData.name}
                        id="fullview"

                        extraStyle={{ width: '100%' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <div className="table-responsive">
                            <table class="table  table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">NAME</th>
                                  <td>{EditData && EditData.name}</td>
                                </tr>
                                <tr>
                                  <th scope="row">MOBILE</th>
                                  <td>{EditData && EditData.mobile}</td>
                                </tr>

                                <tr>
                                  <th scope="row">MAKE</th>
                                  <td>{this.getValueFromArray(EditData && EditData.Make, this.state.MakeOptions)}</td>
                                </tr>
                                <tr>
                                  <th scope="row">MODEL</th>
                                  <td>{this.getValueFromArray(EditData && EditData.Model, this.state.modelopt)}</td>
                                </tr>
                                <tr>
                                  <th scope="row">VARIANT</th>
                                  <td>{this.getValueFromArray(EditData && EditData.Variant, this.state.variantopt)}</td>
                                </tr>

                                <tr>
                                  <th scope="row">OWNER</th>
                                  <td>{EditData && EditData.owner_type}</td>
                                </tr>
                                <tr>
                                  <th scope="row">KMS</th>
                                  <td>{EditData && EditData.Km}</td>
                                </tr>

                                <tr>
                                  <th scope="row">EMAIL</th>
                                  <td>{EditData && EditData.email}</td>
                                </tr>
                                <tr>
                                  <th scope="row">REG NO</th>
                                  <td>{EditData && EditData.Reg_no}</td>
                                </tr>
                                <tr>
                                  <th scope="row">STATUS 1</th>
                                  <td>{EditData && EditData.pendingStatus1}</td>
                                </tr>
                                <tr>
                                  <th scope="row">STATUS 2</th>
                                  <td>{EditData && EditData.pendingStatus2}</td>
                                </tr>
                              </tbody>

                            </table>
                          </div>
                        }
                      /></div>
                    }
                    {this.state.StatusEditData != null && <div>
                      <LoginModal
                        title={StatusEditData.name}
                        id="matchcarmodal"
                        reset={this.StatusReset}
                        extraStyle={{ width: '100%' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <React.Fragment>
                            <main>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="card">
                                      <div className="card-header">
                                        <h1> Edit Status </h1>
                                      </div>
                                      <div className="card-body">
                                        <div className="row form-group">
                                          <div className="col-sm-2" />
                                          <div className="col-sm-7">
                                            <Alert
                                              className="badge-content"
                                              color={this.state.Statusupdatecolor}
                                              isOpen={this.state.StatusupdatealertVisible}
                                              toggle={this.onDismiss}
                                            >
                                              {this.state.Statusupdataetextalert}
                                            </Alert>
                                          </div>
                                          <div className="col-sm-3" />
                                        </div>
                                        <div>
                                          {statusArray && statusArray.length ?
                                            <div className="row " style={{ overflow: "auto" }}>
                                              <table class="table table-bordered">
                                                <thead style={{ background: '#151E80', color: 'white' }}>
                                                  <tr>
                                                    <th scope="col">SiNo</th>
                                                    <th scope="col">Status1</th>
                                                    <th scope="col">Status2</th>
                                                    <th scope="col">Followup Date</th>
                                                    <th scope="col">Update By</th>

                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {statusArray && statusArray.map((jval, i) => {
                                                    return (
                                                      < tr >
                                                        <th scope="row">{jval.sino}</th>
                                                        <td>{jval.status1}</td>
                                                        <td>{jval.status2}</td>
                                                        <td>{jval.Next_Contact_date}</td>
                                                        <td>{jval.userName}</td>
                                                      </tr>
                                                    )
                                                  })
                                                  }
                                                </tbody>
                                              </table>
                                            </div> : null}
                                        </div>
                                        <div className="row" style={{ justifyContent: 'center' }}>
                                          <div className="col-sm-7"
                                          // style={{ left: "25%" }}
                                          >
                                            {StatusEditData && StatusEditData.F_formfilled && StatusEditData.F_formfilled == "yes" ?
                                              <div className="d-flex justify-content-center">
                                                <h2 style={{ color: "#07278f" }}>
                                                  Evalution Form Already Filled
                                                </h2>
                                              </div>
                                              :
                                              <div  >
                                                &nbsp;
                                                <h4>Status 1 : </h4>
                                                &nbsp;
                                                {this.status1()}
                                                &nbsp;
                                                <h4>Status 2 : </h4>

                                                &nbsp;
                                                {this.state.status2visible == true && this.status2()}
                                                <br />
                                                {/* {this.state.status3visible == true && <h4>Status 3 :  </h4>
                                              }
                                              &nbsp; */}
                                                {/* {this.state.status3visible == true && this.status3()} */}
                                                {
                                                  this.state.followupDateVisible == true &&
                                                  <>
                                                    <label style={{ fontWeight: 500 }}> Next-follow-up : </label>
                                                    <br></br>
                                                    <br></br>
                                                    <input
                                                      type="date"
                                                      placeholder="Follow-up date"
                                                      min={moment().format('YYYY-MM-DD')}
                                                      value={this.state.followup}
                                                      className="form-control"
                                                      onChange={(e) => { this.changeHandler(e, "followup") }}
                                                    />
                                                    <br></br>
                                                  </>

                                                }

                                                {/* <br></br>
                                              <br /> */}
                                                {StatusEditData && StatusEditData.view_status && StatusEditData.view_status == true ? this.state.status2visible == true && <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => { this.StatusUpdate() }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                                  :
                                                  <h3 className="text-center" style={{ color: "#07278f" }}> Evalution Form Already Filled</h3>
                                                }
                                                &nbsp;
                                              </div>

                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </main>
                          </React.Fragment>
                        }
                      /></div>
                    }
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Status"
                        id="followup"
                        extraClass="btn btn-primary btn-sm"
                        extraStyle={{ width: '100%' }}
                        onClick={this.reset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <Updatefollowup
                            EditData={this.state.EditData}
                            these={this.changeModelstate}
                            isEdit={this.state.isEdit}
                            FullData={this.state.Data}
                            Index={this.state.Index}
                            IsClose={this.state.IsClose}
                            CustomerId={this.state.CustomerId}
                          />}
                      />
                    </div>
                    {/* </div> */}
                    {/* {loading ? <div class="loader" /> : this.state.EnquiryData.length ? (
                      <Datatable
                        data={this.state.EnquiryData}
                        columnHeading={this.state.column}
                      />
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)} */}

                    {loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.usertypelog.orderBy == 8 ? <div>{this.state.EnquiryData.length !== 0 ? (
                      this.state.EnquiryData.length ? <Datatable
                        data={this.state.EnquiryDatas}
                        columnHeading={this.state.column1}
                      /> : null
                    ) : (null)} </div> : null}
                    {this.state.usertypelog.orderBy == 9 ? <div>{this.state.EnquiryDatas && this.state.EnquiryDatas.length !== 0 ? (
                      this.state.EnquiryDatas.length ? <Datatable
                        data={this.state.EnquiryDatas}
                        columnHeading={this.state.Eval ? this.state.evalcolumn : statusbtn == true ? this.state.statuscolumn : this.state.column}
                        // columnHeading={this.state.Eval ? this.state.evalcolumn : this.state.column}
                        excelColumn={this.state.Eval ? this.state.excelEvalColumn : this.state.excelColumn}
                      /> : null
                    ) : (null)} </div> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}
export default DfCarsEnquiry;
